const keys = Object.freeze({
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
});

const getTitle = (value) => {
  switch (value) {
    case keys.LOW:
      return "Baixa";
    case keys.MEDIUM:
      return "Média";
    case keys.HIGH:
      return "Alta";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
