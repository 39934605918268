<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <form class="needs-validation" autocomplete="off">
            <div>
              <input type="text" style="display:none">
              <input type="password" style="display:none">
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.company_plant_uuid" size="mini" filterable placeholder="Selecione"
                                 :selected="false" @change="getChargePoint(adjustment.company_plant_uuid)"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option class="text-normal" v-for="plant in plants" :key="'B'+plant.id" :label="plant.name"
                                   :value="plant.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ponto de carga -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Ponto de carga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.charge_point_uuid" size="mini" filterable placeholder="Selecione"
                                 :disabled="!charge_point_select || !adjustment.company_plant_uuid"
                                 @change="getCategory()"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-for="charge_point in chargePointItems" :key="'A'+charge_point.id"
                                   :label="charge_point.name" :value="charge_point.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.cmc_category_uuid"
                                 :disabled="!category_select || !adjustment.charge_point_uuid" size="mini" filterable
                                 placeholder="Selecione" @change="getGroups()"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-for="(category,index) in categories" :key="index" :label="category.name"
                                   :value="category.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Grupo -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Grupo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select @change="getCmc" v-model="adjustment.cmc_group_uuid" size="mini" filterable
                                 placeholder="Selecione" :disabled="!group_select || !adjustment.cmc_category_uuid"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-for="(group,index) in selectedGroups" :key="index" :label="group.group_name"
                                   :value="group.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- MCC Produto - Fornecedor(Procedência)  -->
              <div class="form-group row m-0 p-0 pt-3">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  MCC
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.cmc_uuid" size="mini" filterable placeholder="Selecione"
                                 :disabled="!cmc_select || !adjustment.cmc_group_uuid" @change="getDosageLocations()"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-for="(cmc,index) in verifyCmcs" :key="index"
                                   :label="cmc.product_name + ' - ' + cmc.supplier_name+'('+ cmc.origin_name+')'"
                                   :value="cmc.cmc_uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Locais de dosagem  -->
              <div class="form-group row m-0 p-0 pt-3">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Locais de dosagem
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.dosage_locations" multiple size="mini" filterable
                                 placeholder="Selecione" :disabled="!adjustment.cmc_uuid || selectLocation">

                        <el-option v-for="(dosage,index) in dosageLocationItems" :key="index"
                                   :label="dosage.name +' ('+ dosage.dosage_location.name+' '+dosage.code+')'"
                                   :value="dosage.uuid">
                        </el-option>
                        <el-option :value="5" label="Dosagem Manual (Central)"></el-option>
                        <el-option :value="6" label="Dosagem Manual (Obra)"></el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Unidade de dosagem  -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Unidade de dosagem
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.measured_unit_uuid" size="mini" placeholder="Selecione"
                                 :disabled="!adjustment.cmc_uuid">
                        <el-option v-if="validOptions(measured_unit_dosage)"
                                   v-for="measured_unit_dosage in measuredUnitDosages" :key="measured_unit_dosage.id"
                                   :label="measured_unit_dosage.letter" :value="measured_unit_dosage.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 mt-n2 p-0">
                <label class="col-md-5 pb-0 mb-1 mt-2 col-form-label form-control-label">
                  DEFINIR MCC COMO REF. COMERCIAL?
                </label>
                <div class="col-md-6 mb-1 mt-md-3 ml-n2 pr-4">
                  <base-input class="ml-2">
                    <base-switch v-model="adjustment.default_comercial_reference" type="primary"/>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 mt-2 col-form-label form-control-label">
                  REMOVER LOCAIS DE DOSAGEM IGUAIS EM OUTRO MCC?
                </label>
                <div class="col-md-6 mb-1 mt-md-3 ml-n2 pr-4">
                  <base-input class="ml-2">
                    <base-switch v-model="adjustment.remove_same_dosage_location" type="primary"/>
                  </base-input>
                </div>
              </div>
              <div class="row" v-if="windowWidth < 1024">
                <div class="col-12">
                  <div class="text-right mt-4 mr-4">
                    <base-button type="secondary" @click="closeModal('create')">
                      Cancelar
                    </base-button>
                    <base-button @click.native="storeAndCloseModal()" type="success" native-type="submit"
                                 v-bind:disabled="invalid" :loading="loadingStore">
                      Salvar
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-11">
                  <div class="text-right mt-4 mr-4">
                    <base-button type="secondary" @click="closeModal('create')">
                      Cancelar
                    </base-button>
                    <base-button @click.native="storeAndContinue()" type="primary" native-type="submit"
                                 v-bind:disabled="invalid || loadingStore" :loading="loadingSaveAndContinue">
                      Salvar e Continuar
                    </base-button>
                    <base-button @click.native="storeAndCloseModal" type="success" native-type="submit"
                                 v-bind:disabled="invalid || loadingSaveAndContinue"
                                 :loading="loadingStore">
                      Salvar e Fechar
                    </base-button>
                  </div>
                </div>
                <div class="col-1"/>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import {
  formatErrorValidation
} from '@/plugins'

export default {
  name: "ModalCreateAdjustment",
  data() {
    return {
      modal: {
        title: 'Incluir novo MCC',
        create: false,
      },
      loadingSaveAndContinue: false,
      windowWidth: null,
      charge_point_select: false,
      group_select: false,
      category_select: false,
      cmc_select: false,
      adjustment: {
        default_comercial_reference: null,
        company_plant_uuid: null,
        cmc_group_uuid: null,
        charge_point_uuid: null,
        cmc_category_uuid: null,
        cmc_uuid: null,
        remove_same_dosage_location: true,
        measured_unit_uuid: null,
        dosage_locations: [],
      },
      loadingStore: false,
      selectedGroups: [],
      cmcItems: [],
      verifyCmcs: [],
      old_dosage_locations: [],
      selectLocation: false,
      chargePointItems: [],
      dosageLocationItems: [],
    }
  },
  computed: {
    ...mapGetters({
      $_general_settings: "generalSetting/show",
      'plants': 'plant/fetch',
      'chargePoints': 'plantChargePoint/fetch',
      'categories': 'category/fetch',
      'groups': 'cmcGroup/showGroupByCategory',
      'cmcs': 'cmc/getCmcPrice',
      'cmcAdjustments': 'adjustmentCmc/fetch',
      'measuredUnitDosages': 'measuredunit/measured_unit_dosage',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false
      this.$emit("createdCmcAdjustments");
    },
    async handleCreateModal(default_params = null) {
      await this.$store.dispatch('generalSetting/show')
      this.windowWidth = window.innerWidth
      this.old_dosage_locations = [];
      this.adjustment = {
        company_plant_uuid: null,
        charge_point_uuid: null,
        cmc_category_uuid: null,
        cmc_group_uuid: null,
        cmc_uuid: null,
        measured_unit_uuid: null,
        dosage_locations: [],
        remove_same_dosage_location: true,
      }
      this.chargePointItems = [],
        this.dosageLocationItems = [],
        this.selectedGroups = [],
        this.charge_point_select = false

      if (this.$_general_settings.is_mcc_on_map_for_commercial_pref_change) {
        this.adjustment.default_comercial_reference = true
      } else {
        this.adjustment.default_comercial_reference = false
      }
      /**
       * Valida se possui parametros por padrao (Central, Categoria e grupo)
       */
      if (default_params) {
        this.adjustment.company_plant_uuid = default_params.company_plant_uuid
        await this.getChargePoint(this.adjustment.company_plant_uuid, true)
        this.adjustment.cmc_category_uuid = default_params.cmc_category_id
        await this.getGroups(default_params.cmc_group_uuid)
      }
      this.modal.create = true

    },
    validOptions(measuredUnit) {
      if (measuredUnit.id === 8) {
        return false
      }
      if (this.adjustment.cmc_category_uuid) {
        if ([2, 3].includes(this.adjustment.cmc_category_uuid)) {
          if (measuredUnit.id == 26) {
            return false
          }
        }
      }
      return true
    },
    getChargePoint(uuid, default_charge_point = false) {
      if (this.adjustment.company_plant_uuid) {
        this.charge_point_select = false
        this.old_dosage_locations = [];
        this.adjustment.charge_point_uuid = null
        this.adjustment.cmc_category_uuid = null
        this.adjustment.measured_unit_uuid = null
        this.category_select = false
        this.cmcItems = []
        this.verifyCmcs = []
        this.adjustment.cmc_uuid = null
        this.adjustment.cmc_group_uuid = null
        this.group_select = false
        this.cmc_select = false
        this.$store.dispatch('plantChargePoint/getByPlant', uuid).then((response) => {
          this.charge_point_select = true
          this.chargePointItems = response.data
          if (default_charge_point) {
            this.adjustment.charge_point_uuid = this.chargePointItems[0].uuid
            this.getCategory()
          }
        })
      } else {
        this.charge_point_select = false
        this.adjustment.charge_point_uuid = null
        this.old_dosage_locations = [];
        this.adjustment.cmc_category_uuid = null
        this.category_select = false
        this.adjustment.cmc_group_uuid = null
        this.adjustment.cmc_uuid = null
        this.group_select = false
        this.cmc_select = false
        this.cmcItems = []
        this.verifyCmcs = []
      }
    },
    getCategory() {
      this.category_select = true
      this.old_dosage_locations = [];
    },
    getGroups(default_group = null) {
      this.adjustment.cmc_uuid = null
      this.adjustment.dosage_locations = []
      this.old_dosage_locations = [];
      this.category_select = false;
      if (this.adjustment.cmc_category_uuid) {
        this.group_select = false
        let category_id = this.adjustment.cmc_category_uuid
        this.adjustment.cmc_group_uuid = null
        this.selectedGroups = []
        this.verifyCmcs = []
        let plant_id = this.plants.filter(item => item.uuid == this.adjustment.company_plant_uuid)[0].id
        this.$store.dispatch('cmcGroup/fetchGroupByCategory', category_id).then(() => {
          this.category_select = true
          const self = this;
          this.groups.forEach(function (obj, index) {
            let params = {
              groupId: obj.id,
              plantId: plant_id
            }
            self.checkGroup(params, index)
          });
          if (this.adjustment.cmc_category_uuid === 99 && this.groups.length === 0) {
            this.category_select = true;
            this.group_select = true
          }
          if (default_group) {
            this.adjustment.cmc_group_uuid = default_group
            this.getCmc()
          }
        })
      } else {
        this.selectedGroups = []
        this.adjustment.cmc_group_uuid = null
        this.category_select = true;
      }
    },
    getDosageLocations() {
      if (this.adjustment.cmc_uuid) {
        this.dosageLocationItems = []
        this.old_dosage_locations = [];
        let charge_point_id = this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point_uuid)[0].id
        let params = {
          chargePointId: charge_point_id,
          categoryId: this.adjustment.cmc_category_uuid
        }
        if (params.categoryId === 99) {
          params.categoryId = 5
        }
        this.$store.dispatch('plantChargePointLocation/fetchByChargePointAndCategory', params).then((response) => {
          this.dosageLocationItems = response.data
        })
        this.getMeasuredUnit()
      } else {
        this.dosageLocationItems = []
      }
    },
    getMeasuredUnit() {
      this.adjustment.measured_unit_uuid = this.cmcItems.filter(item => item.cmc_uuid == this.adjustment.cmc_uuid)[0].unit_buy_uuid
    },
    checkGroup(params, index) {
      this.$store.dispatch('cmc/fetchPrice', params).then((response) => {
        if (response.data.length > 0) {
          this.selectedGroups.push(this.groups[index])
        }
        if (this.groups.length - 1 === index) {
          this.category_select = true;
          this.group_select = true
        }
      })
    },
    getCmc() {
      if (!this.adjustment.cmc_group_uuid) {
        return;
      }
      this.adjustment.cmc_uuid = null;
      this.verifyCmcs = []
      this.cmcItems = []
      this.cmc_select = false
      this.group_select = false
      this.old_dosage_locations = [];
      const cmc_group_id = this.groups.find(item => item.uuid == this.adjustment.cmc_group_uuid).id
      const plant_id = this.plants.find(item => item.uuid == this.adjustment.company_plant_uuid).id
      this.$store.dispatch('cmc/fetchPrice', {
        plantId: plant_id,
        groupId: cmc_group_id,
        filter: { with_active_cmc_and_price : true },
      }).then((response) => {
        this.verifyCmcs = response.data;
        this.cmc_select = true
        this.group_select = true
        this.cmcItems = this.verifyCmcs;
      });
    },
    storeAndContinue() {
      this.$Progress.start()
      this.loadingSaveAndContinue = true
      const category = this.categories.find(item => item.id == this.adjustment.cmc_category_uuid)
      let adjustment = {...this.adjustment}
      if (category.id === 99) {
        adjustment.puzl = 1
        delete adjustment.cmc_category_uuid
      } else {
        adjustment.cmc_category_uuid = category.uuid
      }
      if (adjustment.dosage_locations.includes(5) || adjustment.dosage_locations.includes(6)) {
        this.old_dosage_locations = JSON.parse(JSON.stringify(adjustment.dosage_locations))
        this.selectLocation = true;
        adjustment.manual_dosages = this.checkManualDosage(adjustment.dosage_locations, adjustment.cmc_category_uuid)
      }
      this.$store.dispatch('adjustmentCmc/add', adjustment)
        .then((response) => {
          this.$emit("createdCmcAdjustments");
          this.adjustment.cmc_group_uuid = null
          this.adjustment.cmc_category_uuid = null
          this.adjustment.cmc_uuid = null
          this.adjustment.measured_unit_uuid = null
          this.adjustment.dosage_locations = null
          this.old_dosage_locations = [];
          this.loadingSaveAndContinue = false
          this.selectLocation = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            //tratar essa linha removendo-a após a apresentação
            this.$Progress.finish();
            this.selectLocation = false;
            this.adjustment.dosage_locations = this.old_dosage_locations
            this.loadingSaveAndContinue = false
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
            this.$Progress.finish();
            this.loadingSaveAndContinue = false
            this.adjustment.dosage_locations = this.old_dosage_locations
            this.selectLocation = false;
          }
        })
    },
    storeAndCloseModal() {
      this.$Progress.start()
      this.loadingStore = true
      const category = this.categories.find(item => item.id == this.adjustment.cmc_category_uuid)
      let adjustment = {...this.adjustment}
      if (category.id === 99) {
        adjustment.puzl = 1
        delete adjustment.cmc_category_uuid
      } else {
        adjustment.cmc_category_uuid = category.uuid
      }
      if (adjustment.dosage_locations.includes(5) || adjustment.dosage_locations.includes(6)) {
        this.old_dosage_locations = JSON.parse(JSON.stringify(adjustment.dosage_locations))
        this.selectLocation = true;
        adjustment.manual_dosages = this.checkManualDosage(adjustment.dosage_locations, adjustment.cmc_category_uuid)
      }
      this.$store.dispatch('adjustmentCmc/add', adjustment)
        .then((response) => {
          this.closeModal('create');
          this.$emit("createdCmcAdjustments");
          this.old_dosage_locations = [];
          this.selectLocation = false;
          this.loadingStore = false
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            //tratar essa linha removendo-a após a apresentação
            this.$Progress.finish();
            this.selectLocation = false;
            this.adjustment.dosage_locations = this.old_dosage_locations
            this.loadingStore = false

          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
            this.$Progress.finish();
            this.loadingStore = false
            this.selectLocation = false;
            this.adjustment.dosage_locations = this.old_dosage_locations
          }
        })
    },
    checkManualDosage(dosages, category_uuid) {
      let manual_dosages = [];
      if (dosages.includes(5)) {
        let dosage = {
          dosage_location_id: 5,
          company_plant_charge_point_id: this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point_uuid)[0].id,
          company_plant_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant_uuid)[0].id,
          company_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant_uuid)[0].company_id,
          cmc_category_id: (this.categories.filter(item => item.uuid == this.adjustment.cmc_category_uuid)[0]?.id) ?? 99
        };
        manual_dosages.push(dosage)
      }
      if (dosages.includes(6)) {
        let dosage = {
          dosage_location_id: 6,
          company_plant_charge_point_id: this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point_uuid)[0].id,
          company_plant_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant_uuid)[0].id,
          company_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant_uuid)[0].company_id,
          cmc_category_id: (this.categories.filter(item => item.uuid == this.adjustment.cmc_category_uuid)[0]?.id) ?? 99
        };
        manual_dosages.push(dosage)
      }
      return manual_dosages
    }
  },
  created() {
    this.$store.dispatch('plant/fetchItems')
    this.$store.dispatch('category/fetchItems').then(response => {
      const category = {
        calculable: 1,
        decimals: 1,
        id: 99,
        letter: "",
        name: "Puzl Admix",
        uuid: "0" // solução rápida para resolver problema ao filtrar por Puzl Admix
      }
      this.$store.state.category.items.push(category)
    })
    this.$store.dispatch('measuredunit/fetchItems')
  }
}
</script>

<style scoped>

</style>
