<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-3"/>
            Adicionar formulação
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3">
              <router-link :to="{ path:'/technology/mix/grid'}">
                <base-button
                  block
                  size="md"
                  type="light"
                  class="pb-2 pt-2 text-uppercase"
                >
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4">
    </base-header>
    <!-- Header Menus -->

    <div class="container-fluid">
      <card>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/test-tube.png" class="mr-3"/>
                        Formulação
                      </h5>
                    </div>
                    <div class="col-2">
                      <base-dropdown class="float-right">
                        <button
                          style="margin: 0;width: 115px; justify-content: center; height: 25px;display: flex; align-items: center;"
                          slot="title-container"
                          class="btn dropdown-toggle text-uppercase colorize-btn-img"
                          type="button"
                          :class="mix_design.status === 1 ? 'btn-outline-success' : (mix_design.status === 0 ? 'btn-outline-danger' : 'btn-outline-gray')">
                          <img class="mr-1" v-if="mix_design.status === 1"
                               src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                          <img class="mr-1" v-else-if="mix_design.status === 0"
                               src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                          <img class="mr-1" v-else src="/img/icons/icons8/ios/edit-file_secondary.png" width="16px">
                          {{ mix_design.status === 1 ? 'Ativo' : (mix_design.status === 0 ? 'Inativo' : 'Rascunho') }}
                        </button>
                        <a v-if="mix_design.status !== 1" @click="handleChangeStatus(1)"
                           class="dropdown-item text-uppercase new-default-black-font" style="font-size: 12px;">
                          Ativo
                        </a>
                        <a v-if="mix_design.status !== 0" @click="handleChangeStatus(0)"
                           class="dropdown-item text-uppercase new-default-black-font" style="font-size: 12px;">
                          Inativo
                        </a>
                        <a v-if="mix_design.status !== 2" @click="handleChangeStatus(2)"
                           class="dropdown-item text-uppercase new-default-black-font" style="font-size: 12px;">
                          Rascunho
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Central
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            @change="getSlumpFinal"
                            v-model="mix_design.company_plant_id"
                            :items="plants"
                            :disabled="loadingCompanyPlant"
                            :loading="loadingCompanyPlant"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <hr class="mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            Tipo
                            <span class="text-danger">&nbsp;*</span>
                          </div>
                          <div class="col-6">
                            <span @click.prevent="handleCreateType()"
                                  style="width: 80px;display: flex; align-items: center;"
                                  class="btn btn-sm btn-outline-success p-1 text-center float-right">
                              <i class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="mix_design.type_id"
                            :items="types"
                            label="technical_description"
                            @change="changeType"
                            :loading="loadingType"
                            :disabled="loadingType"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 mt-n2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            Subtipo
                          </div>
                          <div class="col-6">
                            <span @click="handleCreateSubtype()" style="width: 80px;display: flex; align-items: center;"
                                  class="btn btn-sm btn-outline-success p-1 text-center float-right">
                              <i class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          v-model="mix_design.subtype_id"
                          :items="subtypes"
                          label="technical_description"
                          @change="changeSubtype"
                          :loading="loadingSubtype"
                          :disabled="disabledType"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 mt-n3 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            Classificação adicional
                          </div>
                          <div class="col-6">
                            <span @click.prevent="handleCreateAdditionalClassification()"
                                  style="width: 80px;display: flex; align-items: center;"
                                  class="btn btn-sm btn-outline-success p-1 text-center float-right">
                              <i class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          v-model="mix_design.classification_id"
                          :items="additional_classifications"
                          label="technical_description"
                          :loading="loadingClassification"
                          :disabled="disabledClassification"/>
                      </base-input>
                    </div>
                  </div>
                  <hr class="mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Slump/flow
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="mix_design.slump_flow_id"
                            :items="slump_flows"
                            @change="getSlumpFinal()"
                            label="nomenclature"
                            :loading="loadingSlumpFlow"
                            :disabled="loadingSlumpFlow || !mix_design.company_plant_id"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Dimensão máxima
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="mix_design.maximum_size_id"
                            :items="maximum_sizes"
                            label="description"
                            :loading="loadingMaximumSize"
                            :disabled="loadingMaximumSize"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2 mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Responsabilidades
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="mix_design.feature_ids"
                            :items="listFeature"
                            :multiple="true"
                            :loading="loadingFeature"
                            :disabled="loadingFeature"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <hr class="mt-4 mb-2">
                  <div class="row mt-2 mb-3 align-items-center">
                    <div class="col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Prazo de aplicação
                        <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                  Caso esse campo seja preenchido, será definido esse prazo de aplicação do concreto contado a partir do tempo de início do carregamento.
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="24px"
                               src="/img/icons/info.png"/>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <base-input input-classes="form-control-sm">
                        <InputTimePicker
                          minuteInterval="1"
                          v-model="mix_design.application_deadline"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-4 mt-n3 align-items-center">
                    <div class="col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                       Corte de água
                        <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                  Caso esse campo seja preenchido, será adotado esse valor padrão de água retida no carregamento independente da água real que tiver sido retida.
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="24px"
                               src="/img/icons/info.png"/>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <base-input input-classes="form-control-sm">
                          <input-limit
                            :options="{
                      max: 99,
                      min: 0
                    }"
                            max_length="4"
                            :start_value="mix_design.water_cut"
                            v-model="mix_design.water_cut"
                            input_group_classes="input-group-sm pb-0"
                            @value="mix_design.water_cut = $event === '-' ? null : $event"
                          >
                            <small class="input-group-sm p-0 m-0"> l/m<sup>3</sup> </small>
                          </input-limit>
                        </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/brand/logo.png" class="mr-3"/>
                    Puzl Adjust
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Nível de aplicação
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            multiple
                            v-model="mix_design.level_features"
                            :items="feature_levels"
                            customKey="value"
                            label="label"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Slump final
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          :disabled="!mix_design.slump_flow_id"
                          multiple
                          v-model="mix_design.slump_flow_ids"
                          :items="slump_finals"
                          label="nomenclature"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Bloquear ajustes
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select v-model="mix_design.adjustment_blocks" size="mini" filterable multiple
                                   placeholder="Selecione" @change="setAllBlocks">
                          <el-option label="Todos" value="all">
                          </el-option>
                          <el-option v-for="block in blocks" :disabled="BlockAllSelected" :key="block.value"
                                     :label="block.text" :value="block.value">
                          </el-option>
                        </el-select>

                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Permitir reuso de concreto
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-switch v-model="mix_design.allow_reuse" type="success" offText="não" onText="sim"
                                   class="success">
                      </base-switch>
                    </div>
                  </div>
                  <hr class="mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Restringir peças a concretar
                        <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                  Caso alguma peça seja marcada, as formulações dessa tabela só poderão ser dosadas para
                                  as peças selecionadas.
                                  Se nenhuma peça for marcada, não haverá restrição de peças, logo, as formulações dessa
                                  tabela poderão ser dosadas
                                  para qualquer tipo de peça cadastrada.
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="24px"
                               src="/img/icons/info.png"/>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="clearInputs('denied')"
                          v-model="mix_design.concreted_piece_ids"
                          size="mini"
                          filterable multiple
                          placeholder="Selecione"
                          :loading="loadingConcretePiece"
                          :disabled="loadingConcretePiece">
                          <el-option v-for="concrete_piece in concrete_pieces" :key="concrete_piece.id"
                                     :label="concrete_piece.description" :value="concrete_piece.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-n1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Bloquear peças a concretar
                        <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                  As peças selecionadas não serão poderão ser dosadas.
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="24px"
                               src="/img/icons/info.png"/>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="clearInputs('allowed')"
                          v-model="mix_design.block_concreted_piece_ids" size="mini" filterable multiple
                          placeholder="Selecione" :loading="loadingConcretePiece"
                          :disabled="loadingConcretePiece">
                          <el-option v-for="concrete_piece in concrete_pieces" :key="concrete_piece.id"
                                     :label="concrete_piece.description" :value="concrete_piece.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="card p-4 mt-n3">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/info.png" class="mr-3"/>
                    Informações técnicas para o.s de carregamento
                  </h5>
                  <hr class="new-default-black mt-2 mb-1">
                  <div class="row mt-1 mb-3 align-items-center">
                    <div class="col-12">
                      <base-input input-group-classes="input-group-sm">
                        <textarea placeholder=" ..." maxlength="405" type="text" v-model="mix_design.description"
                                  rows="4"
                                  class="form-control form-control-sm">
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mt-n4">
              <div class="col-12">
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/icons8/ios/impossible-shapes_primary.png" class="mr-3"/>
                    Padrão de moldagem
                  </h5>
                  <hr class="new-default-black mt-2 mb-3">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="card p-4">
                        <h5 class="h3 text-primary p-1 mb-0">
                          Novo ensaio
                        </h5>
                        <div class="row my-2 align-items-center">
                          <div class="col-12 col-md-6">
                            <div class="pb-0 col-form-label new-default-black-font form-control-label">
                              Ensaio
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <base-input input-classes="form-control-sm">
                              <puzl-select
                                filterable
                                v-model="test.hardenedState"
                                @change="getMold()"
                                :items="hardened_states"
                                :label="'short_description'"
                                :disabled="loadingHardenedState"
                                :loading="loadingHardenedState"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row my-2 align-items-center">
                          <div class="col-12 col-md-6">
                            <div class="pb-0 col-form-label new-default-black-font form-control-label">
                              Molde
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <base-input input-classes="form-control-sm">
                              <puzl-select
                                filterable
                                v-model="test.mold"
                                :items="hardened_state_molds"
                                :label="'description'"
                                :disabled="loadingMold || !hardened_state_molds.length || !test.hardenedState"
                                :loading="loadingMold"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row my-2 align-items-center">
                          <div class="col-12 col-md-6">
                            <div class="pb-0 col-form-label new-default-black-font form-control-label">
                              Idade
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <base-input input-group-classes="input-group-sm">
                                  <input type="text" v-model="test.period" inputmode="numeric" v-mask="['#','##','###']"
                                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                                </base-input>
                              </div>
                              <div class="col-md-6">
                                <base-input input-classes="form-control-sm">
                                  <puzl-select
                                    filterable
                                    v-model="test.period_type"
                                    :items="period_types"
                                    :label="'label'"
                                    :customKey="'value'"/>
                                </base-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 align-items-center">
                          <div class="col-12 col-form-label form-control-label">
                            <base-button class="float-right py-2 px-4"
                                         :disabled="test.mold == '' || test.hardenedState == '' || test.period == '' || test.period_type==''"
                                         style="display: flex; align-items: center;"
                                         size="sm" type="success" @click="addMold()">
                              <img class="mr-2" src="/img/icons/plus--v1.png" width="16">
                              INCLUIR
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="px-4 pb-4">
                        <h5 class="h3 text-primary p-1 mb-0">
                          Ensaios
                        </h5>
                        <div class="m-2 mt-3" v-for="(itemHardenedState, index)  in mix_design.testHardenedState"
                             :key="index">
                          <card class="my-0 border card--hover card-body-molde">
                            <div class="row align-items-center">
                              <div class="col-5">
                                <h6 class="p-0 m-0" style="display: flex;align-items: center;">
                                  <i class="mr-2" style="font-style: normal; font-size: 14px;">{{ index + 1 }}.</i>
                                  <i style="font-style: normal;font-size: 12px;">{{
                                      itemHardenedState.mold.description
                                    }}</i>
                                </h6>
                              </div>
                              <div class="col-5">
                                <div v-if="itemHardenedState.period_type==1">
                                  <h6 style="font-size: 12px;" class="p-0 m-0">{{ itemHardenedState.period }} Dias</h6>
                                </div>
                                <div v-if="itemHardenedState.period_type==2">
                                  <h6 style="font-size: 12px;" class="p-0 m-0">{{ itemHardenedState.period }} Horas</h6>
                                </div>
                              </div>
                              <div class="col-2">
                                <img class="float-right pointer"
                                     src="/img/icons/icons8/ios/cancel_danger.png"
                                     width="14" @click="removeMold(index)">
                              </div>
                            </div>
                          </card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <router-link :to="{ path:'/technology/mix/grid'}">
                <base-button type="danger" style="width: 120px; display: flex; align-items: center;">
                  <img class="float-right pointer mr-2" width="14" src="/img/icons/icons8/ios/cancel.png">
                  Cancelar
                </base-button>
              </router-link>
              <base-button type="success" style="width: 120px; display: flex; align-items: center;"
                           native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                <img class="float-right pointer mr-2" width="14" src="/img/icons/save.png">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
    <create-type ref="createType"></create-type>
    <create-subtype ref="createSubtype"></create-subtype>
    <create-additional-classification ref="createAdditionalClassification"></create-additional-classification>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CreateType from './Type/Shared/_Create'
import CreateSubtype from './Subtype/Shared/_Create'
import CreateAdditionalClassification from './AdditionalClassification/Shared/_Create'
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe"
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit.vue";
import InputTimePicker from '@/components/InputTimePicker'

export default {
  name: 'MixDesigns',
  components: {
    CreateType,
    CreateSubtype,
    CreateAdditionalClassification,
    PuzlSelect,
    InputLimit,
    InputTimePicker
  },
  data() {
    return {
      format_mixe: period_types,
      test: {
        mold: '',
        hardenedState: '',
        period: '',
        period_type: '',
      },
      company_plants: {},
      hardened_state_molds: [],
      mix_design: {
        status: 2,
        application_deadline: null,
        water_cut: null,
        block_concreted_piece_ids: [],
        level_features: [1, 2, 3],
        slump_flow_ids: [],
        testHardenedState: [],
      },
      flag: 0,
      loadingSave: false,
      loadingCompanyPlant: true,
      loadingType: true,
      disabledType: true,
      loadingSubtype: false,
      disabledClassification: true,
      loadingClassification: false,
      loadingSlumpFlow: true,
      loadingMaximumSize: true,
      loadingFeature: true,
      loadingConcretePiece: true,
      loadingHardenedState: true,
      loadingMold: false,
      BlockAllSelected: false,
      slump_finals: [],
      blocks: [
        {
          text: 'MCC',
          value: 1
        },
        {
          text: 'Teor do MCC',
          value: 2
        },
        {
          text: 'Peça a concretar',
          value: 3
        },
        {
          text: 'Ciclo de concreto',
          value: 4
        },
        {
          text: 'Volume carregado',
          value: 5
        },
        {
          text: 'Temperatura ambiente',
          value: 6
        },
        {
          text: 'Ponto de carga',
          value: 7
        },
        {
          text: 'Reuso',
          value: 8
        },
        {
          text: 'Contrato e horário',
          value: 9
        },
        {
          text: 'Tipo',
          value: 10
        },
        {
          text: 'Nível de aplicação',
          value: 11
        },
        {
          text: 'Slump final',
          value: 12
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      'plants': 'plant/activeItems',
      'types': 'mixType/activeItems',
      'subtypes': 'mixSubtype/fetchByType',
      'subtype': 'mixSubtype/fetch',
      'additional_classifications': 'mixAdditionalClassifications/fetchBySubtype',
      'additional_classification': 'mixAdditionalClassifications/fetch',
      'slump_flows': 'mixSlumpFlow/activeItems',
      'maximum_sizes': 'mixMaximumSize/activeItems',
      'features': 'mixFeature/activeItems',
      'period_types': 'mixFeature/period_types',
      'concrete_pieces': 'mixConcretePiece/activeItems',
      'hardened_states': 'testHardenedState/activeItems',
      'mix_designs_show': 'MixDesigns/show',
      feature_levels: 'mixConcretePiece/feature_levels',
    }),
    listFeature() {
      return this.features.map(feature => {
        feature.name = feature.period && feature.short_description + ' ' + this.format_mixe(feature.period, feature.period_type) + ' ' + this.letter_unit(feature.measured_unit.initials) || feature.short_description
        return feature
      }, this)
    }
  },
  watch: {
    'mix_design.subtype_id': function () {
      this.changeSubtype()
    },
    additional_classification() {
      this.changeSubtype()
    }
  },
  methods: {
    getSlumpFinal() {
      this.mix_design.slump_flow_ids = []
      if (!this.mix_design.company_plant_id || !this.mix_design.slump_flow_id) {
        return this.slump_finals = []
      }
      let loader = this.$loading.show()
      this.$store.dispatch('mixSlumpFlow/getSlumpFinal',
        {
          slump_flow_id: this.mix_design.slump_flow_id,
          company_plant_id: this.mix_design.company_plant_id,
        }
      ).then(({data}) => this.slump_finals = data).finally(() => loader.hide())
    },
    letter_unit(letter) {
      if (letter === 'nenhum') {
        return ''
      }
      return letter
    },
    clearInputs(ids) {
      if (ids == 'denied') {
        this.mix_design.block_concreted_piece_ids = [];
      } else {
        this.mix_design.concreted_piece_ids = [];
      }
    },
    handleCreateType() {
      this.$refs.createType.handleCreateModal()
    },
    setAllBlocks() {
      if (this.mix_design.adjustment_blocks.length >= 2) {
        this.flag = 0
      } else {
        this.flag += 3
      }
      if (this.mix_design.adjustment_blocks.includes('all')) {
        if (this.flag % 2 == 1) {
          this.mix_design.adjustment_blocks = []
          for (let block of this.blocks) {
            this.mix_design.adjustment_blocks.push(block.value)
          }
        }
        if (this.flag % 2 == 0) {
          this.mix_design.adjustment_blocks = []
          for (let plant of this.plants) {
            this.mix_design.adjustment_blocks.pop()
          }
        }
      }
    },
    handleCreateSubtype() {
      this.$refs.createSubtype.handleCreateModal()
    },
    handleCreateAdditionalClassification() {
      this.$refs.createAdditionalClassification.handleCreateModal()
    },
    changeType() {
      !this.mix_design.subtype_id
      if (this.mix_design.type_id) {
        this.loadingSubtype = true
        let type = this.types.filter(item => item.id === this.mix_design.type_id)

        this.$store.dispatch('mixSubtype/fetchItemsByUuidType', type[0].uuid)
          .then(() => {
            this.loadingSubtype = false
            this.disabledType = false;
          })
      }
    },
    changeSubtype() {
      this.mix_design.classification_id = ''
      if (this.mix_design.subtype_id) {
        this.loadingClassification = true
        let subtype = this.subtypes.filter(item => item.id === this.mix_design.subtype_id)

        this.$store.dispatch('mixAdditionalClassifications/fetchItemsByUuidSubtype', subtype[0].uuid)
          .then(() => {
            this.loadingClassification = false
            this.disabledClassification = false
          })
      }
    },
    handleChangeStatus(status) {
      this.mix_design.status = status;
    },
    removeMold(index) {
      this.mix_design.testHardenedState.splice(index, 1);
    },
    addMold() {
      this.testHardenedState = {};
      this.testHardenedState.hardened_state_id = this.test.hardenedState;
      let testMoldSelected = this.test.mold;

      let moldSelected = this.hardened_state_molds.filter(function (mold) {
        return mold.id === testMoldSelected;
      });

      this.testHardenedState.mold = moldSelected[0];
      this.testHardenedState.period = parseInt(this.test.period);
      this.testHardenedState.period_type = this.test.period_type;
      this.mix_design.testHardenedState.push(this.testHardenedState);
    },
    getMold() {
      this.test.mold = '';

      let mold_default = {};
      let hardned_state = this.test.hardenedState
      let selected_hardened = this.hardened_states.filter(function (item) {
        return item.id === hardned_state;
      });

      this.hardened_state_molds = selected_hardened[0].molds;

      if (this.hardened_state_molds.length >= 1) {

        mold_default = this.hardened_state_molds.filter(function (mold) {
          return mold.default === true;
        });

        if (mold_default[0] !== undefined) {
          this.test.mold = mold_default[0].id;
        }

      }

      this.loadingMold = false;
    },
    save() {
      this.$Progress.start()
      // this.loadingSave = true
      this.$store.dispatch('MixDesigns/add', this.mix_design)
        .then(response => {
          this.loadingSave = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$router.push({
            name: "Resumo",
            path: '/technology/mix/resume',
            params: {
              mix_design_uuid: this.mix_designs_show.uuid
            }
          });

        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({
              type: 'danger',
              message: errors
            })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  created() {
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingCompanyPlant = false
    })
    this.$store.dispatch('mixType/fetchItems').then(() => {
      this.loadingType = false
    })
    this.$store.dispatch('mixSlumpFlow/fetchItems').then(() => {
      this.loadingSlumpFlow = false
    })
    this.$store.dispatch('mixMaximumSize/fetchItems').then(() => {
      this.loadingMaximumSize = false
    })
    this.$store.dispatch('mixFeature/fetchItems').then(() => {
      this.loadingFeature = false
    })
    this.$store.dispatch('mixConcretePiece/fetchItems').then(() => {
      this.loadingConcretePiece = false
    })
    this.$store.dispatch('testHardenedState/fetchItems').then(() => {
      this.loadingHardenedState = false
    })
  }
};
</script>

<style scoped>
.btn-outline-gray {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}
</style>
