import store from "../../../../shared/libraries/store";
import { initBankAccountListPagerType, BankAccountListFilterType, BankAccountListPagerType, BankAccountType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../shared/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
    BASE: "/configuration/bank-accounts",
    BANKS_URL: '/financial/bank'
});

/** KEYS */
const keys = Object.freeze({
    ITEM: "BANK_ACCOUNT_ITEM",
    LISTED: "BANK_ACCOUNT_LISTED",
    IS_LISTING: "BANK_ACCOUNT_IS_LISTING",
    IS_FINDING: "BANK_ACCOUNT_IS_FINDING",
    IS_SAVING: "BANK_ACCOUNT_IS_SAVING",
    IS_REMOVING: "BANK_ACCOUNT_IS_REMOVING",
    PAGE_STATES: "BANK_ACCOUNT_PAGE_STATE",
    IS_LOADING_BANKS: "BANK_ACCOUNT_IS_LOADING_BANKS",
    BANK_ITEMS: 'BANK_ITEMS'
});

/** GETTERS */
const getters = {
    /** @returns {?BankAccountType} */
    getItem: () => store.getState(keys.ITEM),

    /** @returns {BankAccountListPagerType} */
    getListed: () => store.getState(keys.LISTED) ?? initBankAccountListPagerType(),

    /** @returns {boolean} */
    getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

    /** @returns {boolean} */
    getIsLoadingBanks: () => store.getState(keys.IS_LOADING_BANKS) ?? false,

    /** @returns {array}  */
    getBankItems: () => store.getState(keys.BANK_ITEMS) ?? [],

    /** @returns {boolean} */
    getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

    /** @returns {boolean} */
    getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

    /** @returns {boolean} */
    getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

    /** @returns {{  searchBarFilter: SearchBarFilterType, filter: BankAccountListFilterType }} */
    getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
    /**
     * @param {BankAccountType} item
     * @returns {BankAccountType}
     */
    setItem: (item) => store.commit(keys.ITEM, item),

    /**
     * @param {BankAccountListPagerType} listed
     * @returns {BankAccountListPagerType}
     */
    setListed: (listed) => store.commit(keys.LISTED, listed),

    /**
     * @param {boolean} isListing
     * @returns {boolean}
     */
    setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

    /**
     * @param {boolean} isLoadingBanks
     * @returns {boolean}
     */
    setIsLoadingBanks: (isLoadingBanks) => store.commit(keys.IS_LOADING_BANKS, isLoadingBanks),

    /**
     * @param {array} bankItems
     * @returns {array}
     */
    setBanks: (banks) => store.commit(keys.BANK_ITEMS, banks),

    /**
     * @param {boolean} isFinding
     * @returns {boolean}
     */
    setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

    /**
     * @param {boolean} isSaving
     * @returns {boolean}
     */
    setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

    /**
     * @param {boolean} isRemoving
     * @returns {boolean}
     */
    setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

    /**
     * @param {BankAccountType} created
     * @returns {BankAccountType}
     */
    setNewItemInList: (created) => store.commitNewItemToList(keys, created),

    /**
     * @param {BankAccountListPagerType} listed
     * @returns {BankAccountListPagerType}
     */
    setListMerge: (listed) => store.commitListMerge(keys, listed),

    /**
     * @param {number} id
     */
    setRemovedItem: (id) => store.commitRemovedItem(keys, id),

    /**
     * @param {BankAccountType} updated
     * @returns {BankAccountType}
     */
    setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

    /** @param {{ searchBarFilter: SearchBarFilterType, filter: BankAccountListFilterType }} filters */
    setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

    /** Resetar chaves de estado */
    resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
    /**
     * Incluir
     *
     * @param {BankAccountType} data
     * @returns {Promise<BankAccountType>}
     */
    create: async (data) => await createSource(data, mutations, urls.BASE),

    /**
     * Localizar por ID
     *
     * @param {number} id
     * @returns {Promise<?BankAccountType>}
     */
    find: async (id) => await findSource(id, mutations, urls.BASE),

    /**
     * Listar
     *
     * @param {BankAccountListFilterType} filter - Filtro de listagem
     * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
     * @returns {Promise<?BankAccountListPagerType>} - Retorno paginado
     */
    list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

    /**
     * Deletar por ID
     *
     * @param {number} id
     * @returns {Promise<void>}
     */
    remove: async (id) => await removeSource(id, mutations, urls.BASE),

    /**
     * Atualizar por ID
     *
     * @param {BankAccountType} data
     * @param {number} id
     * @returns {Promise<BankAccountType>}
     */
    update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),

    /**
     * Atualizar Status por ID
     *
     * @param {0|1} status
     * @param {number} id
     * @returns {Promise<BankAccountType>}
     */
    updateStatus: async (status, id) => {
        try {
            mutations.setIsSaving(true);

            const url = `${urls.BASE}/${id}/status/${status}`;
            const res = await httpClient.put(url);
            if (res.status !== HttpStatusEnum.HTTP_OK) {
                handleUnexpectedStatusCode(res);
                return;
            }

            const updated = res.data.data;
            return mutations.setUpdatedItem(updated);
        } finally {
            mutations.setIsSaving(false);
        }
    },


    /**
    * @typedef {Object} Bank
    * @property {number} id - id do banco
    * @property {string} name - nome do banco
    * @property {string} code - code do banco
    * 
    * @returns {Promise<Bank[]>} - lista de bancos
    */
    fetchBanks: async () => {
        try {
            mutations.setIsLoadingBanks(true);

            const res = await httpClient.get(urls.BANKS_URL);
            if (res.status !== HttpStatusEnum.HTTP_OK) {
                handleUnexpectedStatusCode(res);
                return;
            }

            const bankItems = res.data.data
            return mutations.setBanks(bankItems)
        } finally {
            mutations.setIsLoadingBanks(false)
        }
    }
}

export default {
    actions,
    getters,
    mutations,
};
