<template>
  <div>
    <modal :borderInfo="'2psx solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template #header>
        <div style="display: flex; align-items: center">
          <img src="/img/icons/interstate-truck-white.png" width="22" />
          <h5 class="modal-title p-0 m-0 ml-3 text-white">
            {{ title }}
          </h5>
        </div>
      </template>
      <DriverScaleForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
        @onfilterClick="filterItems"
      />
      <TableListing @onRemoveItemClick="removeStockLocation" />
      <AppViewTrigger v-if="!$_driver_scales_is_listing" @onIntersected="listItems(true)" />
    </modal>
  </div>
</template>

<script setup>
//#region === Imports ===
import { ref, reactive, getCurrentInstance, computed } from "vue";
import { initDriverScaleListFilterType, driverScaleListFilterType } from './types';
import DriverScaleForm from "./shared/DriverScaleForm";
import TableListing from "./shared/Table";
import driverScaleStore from './store/driverScaleStore';
import { AppViewTrigger } from '../../../../components/AppGlobal';
import { dialogs, progress } from "../../../../helpers";
import moment from 'moment';
//#endregion

//#region === Data ===
const { proxy } = getCurrentInstance();
const isOpen = ref(false);
const title = "Escala de motorista";

/** @type {driverScaleListFilterType} - Filtro principal */
const filter = reactive(initDriverScaleListFilterType());
//#endregion

//#region === Computeds ===
const $_driver_scales_is_listing = computed(() => driverScaleStore.getters.getIsListing());

const formData = ref({
  time: null,
  driver_id: null,
  company_plant_id: null,
});
//#endregion

//#region === Methods ===
function openModal() {
  Object.assign(filter, initDriverScaleListFilterType());
  formData.value.time = null;
  formData.value.driver_id = null;
  formData.value.company_plant_id = null;
  
  listItems(false);
  loadCompanyPlants();
  loadDrivers();

  isOpen.value = true;
}

function closeModal() {
  isOpen.value = false;
}

/**
 * Preparar filtro principal para listagem
 */
 function prepareFilter() {
  filter.page = "";
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
 function listItems(isAccumulateItems = false) {
  prepareFilter();
  driverScaleStore.actions.list(filter, isAccumulateItems);
};

/**
 * @typedef {Object} FilterParams
 * @property {string} company_plant_id
 * @property {string} date
 */
function filterItems(filterParams) {
  if (filterParams.date) {
    filter.date = moment(filterParams.date).format('YYYY-MM-DD');
    formData.value.date = moment(filterParams.date).format('YYYY-MM-DD');
  }
  
  if (filterParams.company_plant_id) {
    filter.company_plant_id = filterParams.company_plant_id;
    formData.value.company_plant_id = filterParams.company_plant_id;
  }

  listItems(false);
};

/** Cadastrar item */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  driverScaleStore.actions.create(formData.value)
    .then(() => {
      dialogs.notify();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
};

/**
 * Remover registro
 * @param {number} id
 */
 async function removeStockLocation(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await driverScaleStore.actions.remove(id);
    dialogs.notifyRemoved();
  }
};

function loadCompanyPlants() {
  const localPlants = localStorage.getItem("companyPlants");
  if (localPlants) {
    proxy.$store.commit("plant/SET_ACTIVE", JSON.parse(localPlants).data);
  } else {
    proxy.$store.dispatch("plant/getPlantsSimplified").then((response) => {
      proxy.$store.commit("plant/SET_ACTIVE", response.data);
    });
  }
}

function loadDrivers() {
  proxy.$store.dispatch("user/getDriversSimplified");
}
//#endregion

defineExpose({
  openModal,
});
</script>
