import store from "../../../../../../shared/libraries/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";
import { EntryLaunchConfigurationType, initEntryLaunchConfigurationType } from "../types/EntryLaunchConfigurationType";

/** URLS */
const urls = Object.freeze({
  BASE: "/configuration/entry-launch/configuration",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "ENTRY_LAUNCH_CONFIGURATION_ITEM",
});

const getters = {
  /** @returns {EntryLaunchConfigurationType} */
  getItem: function () {
    return store.getState(keys.ITEM) ?? initEntryLaunchConfigurationType();
  },
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {EntryLaunchConfigurationType} item
   * @returns {EntryLaunchConfigurationType}
   */
  setItem: function (item) {
    return store.commit(keys.ITEM, item);
  },
};

/** ACTIONS */
const actions = {
  /**
   * @param {EntryLaunchConfigurationType} data
   * @returns {Promise<?EntryLaunchConfigurationType>}
   */
  create: async function (data) {
    const res = await httpClient.post(`${urls.BASE}`, data);
    switch (res.status) {
      case HttpStatusEnum.HTTP_OK:
        return res.data.data;
      default:
        handleUnexpectedStatusCode(res);
    }
  },

  /**
   * @param {number} company_plant_issuer_id - Cnpj emissor
   * @returns {Promise<?EntryLaunchConfigurationType>}
   */
  find: async function (company_plant_issuer_id) {
    const res = await httpClient.get(`${urls.BASE}/${company_plant_issuer_id}`);
    switch (res.status) {
      case HttpStatusEnum.HTTP_OK:
        mutations.setItem(res.data.data);
        return res.data.data;
      default:
        handleUnexpectedStatusCode(res);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};
