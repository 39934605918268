<template>
  <div>
    <AppFixedPageTitle
      title="SLUMP FINAL"
      icon="/img/icons/physics-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
          :showCompanyPlants="true"
        />
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openAdjustmentFinalSlumpCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            @click="openLimitSettingCreateModal"
            text="Limite"
            type="primary"
            icon="/img/icons/icons8/ios/resize-vertical_white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    />
    <div class="container-fluid">
      <AdjustmentFinalSlumpListPageCards
        @onCancelDosageAdjutmentClick="restoreAdjustmentValues"
        @onUpdateDosageAdjustmentClick="updateDosageAdjustmentValues"
        @onRemoveItemClick="removeAdjustmentFinalSlump"
        @onCompanyPlantsByIdClick="getCompanyPlantsById"
        @onEditItemClick="openAdjustmentFinalSlumpEditModal"
      />
      <AppViewTrigger v-if="!$_adjustment_final_slumps_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_adjustment_final_slumps_is_listing && !$_adjustment_final_slumps_listed.items.length"/>
    </div>
    <AdjustmentFinalSlumpCreateModal ref="adjustmentFinalSlumpCreateModal" />
    <AdjustmentFinalSlumpEditModal ref="adjustmentFinalSlumpEditModal" />
    <LimitSettingCreateModal ref="limitSettingCreateModal"/>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted, Ref } from 'vue';
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import adjustmentFinalSlumpStore from "../../store/adjustmentFinalSlumpStore";
import {
  initAdjustmentFinalSlumpListFilterType,
  AdjustmentFinalSlumpListFilterType,
  AdjustmentFinalSlumpListType,
  AdjustmentFinalSlumpUpdateDosageType,
  AdjustmentFinalSlumpType
} from '../../types';
import { dialogs, progress } from '../../../../../helpers';
import AdjustmentFinalSlumpListPageCards from './AdjustmentFinalSlumpListPageCards.vue';
import { AdjustmentFinalSlumpFilterStatusEnum } from "../../enums";
import LimitSettingCreateModal from "../limit-setting/LimitSettingCreateModal.vue";
import AdjustmentFinalSlumpCreateModal from "../create/AdjustmentFinalSlumpCreateModal.vue";
import { AdjustmentFinalSlumpCreateModalExpose } from '../create/AdjustmentFinalSlumpCreateModal.vue';
import AdjustmentFinalSlumpEditModal from "../edit/AdjustmentFinalSlumpEditModal.vue";
import { AdjustmentFinalSlumpEditModalExpose } from '../edit/AdjustmentFinalSlumpEditModal.vue';
import { adjustmentFinalSlumpCreateHistoryService } from "../../services";
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import { useAdjustmentFinalSlumpHook } from "../../hooks/useAdjustmentFinalSlumpHook";
import PuzlEmptyData from "@/components/PuzlEmptyData";
//#endregion

//#region ComponentRefs
/** @type {Ref<AdjustmentFinalSlumpCreateModalExpose>} */
const adjustmentFinalSlumpCreateModal = ref(null);
/** @type {Ref<AdjustmentFinalSlumpEditModalExpose>} */
const adjustmentFinalSlumpEditModal = ref(null);
/** @type {Ref<LimitSettingCreateModal>} */
const limitSettingCreateModal = ref(null);
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {AdjustmentFinalSlumpListFilterType} - Filtro principal */
const filter = reactive(initAdjustmentFinalSlumpListFilterType());

const listType = ref('cards');

const { agentGroups } = useAdjustmentFinalSlumpHook();
//#endregion

//#region Computeds
/** Store Getters */
const $_adjustment_final_slumps_listed = computed(() => adjustmentFinalSlumpStore.getters.getListed());
const $_adjustment_final_slumps_is_listing = computed(() => adjustmentFinalSlumpStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: AdjustmentFinalSlumpFilterStatusEnum.keys.WITH_ADJUSTMENT,
      name: 'Com Ajuste',
      selected: filter.status === AdjustmentFinalSlumpFilterStatusEnum.keys.WITH_ADJUSTMENT
    },
    {
      id: AdjustmentFinalSlumpFilterStatusEnum.keys.WITHOUT_ADJUSTMENT,
      name: 'Sem Ajuste',
      selected: filter.status === AdjustmentFinalSlumpFilterStatusEnum.keys.WITHOUT_ADJUSTMENT
    },
  ];
});
//#endregion

//#region Methods
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? adjustmentFinalSlumpStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {AdjustmentFinalSlumpListFilterType}
 */
function defaultFilter() {
  return {
    ...initAdjustmentFinalSlumpListFilterType(),
    status: null
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {  
  prepareFilter();
  adjustmentFinalSlumpStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
};

/**
 * @param {TabSelectItemType} item
 */
 function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * @param {AdjustmentFinalSlumpListType} adjustment
 */
async function restoreAdjustmentValues(adjustment) {
  progress.start();

  try {
    const oldAdjustment = await adjustmentFinalSlumpStore.actions.find(adjustment.id);
    adjustment.binder = oldAdjustment.binder;
    adjustment.water = oldAdjustment.water;
    adjustment.content_mortar = oldAdjustment.content_mortar;
    adjustment.total_air = oldAdjustment.total_air;
    adjustment.agent = oldAdjustment.agent;
    adjustment.observation = oldAdjustment.observation;
    adjustment.has_adjustment = oldAdjustment.has_adjustment;
    adjustment.is_adjusting = false;
  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {AdjustmentFinalSlumpUpdateDosageType} dosages 
 */
async function updateDosageAdjustmentValues(dosages) {
  progress.start();

  try {
    await adjustmentFinalSlumpStore.actions.updateDosages(dosages, dosages.id);

    /** @type {AdjustmentFinalSlumpType} adjustmentFinalSlump */
    const adjustmentFinalSlump = await adjustmentFinalSlumpStore.actions.find(dosages.id);
    const has_agent = await hasAgent(adjustmentFinalSlump.agent);

    adjustmentFinalSlumpCreateHistoryService.execute(
      AdjustmentHistoryActionsEnum.keys.ADJUST,
      adjustmentFinalSlump,
      has_agent ? agentGroups : null
    );

    dialogs.notify();
  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {AdjustmentFinalSlumpListType} adjustment 
 */
async function getCompanyPlantsById(adjustment) {
  progress.start();

  try {
    await adjustmentFinalSlumpStore.actions.getCompanyPlantsById(adjustment);
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {number} id 
 */
async function removeAdjustmentFinalSlump(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {

    try {
      /** @type {AdjustmentFinalSlumpType} adjustmentFinalSlump */
      const adjustmentFinalSlump = await adjustmentFinalSlumpStore.actions.find(id);
      const has_agent = await hasAgent(adjustmentFinalSlump.agent);

      await adjustmentFinalSlumpStore.actions.remove(id);

      adjustmentFinalSlumpCreateHistoryService.execute(
        AdjustmentHistoryActionsEnum.keys.REMOVE,
        adjustmentFinalSlump,
        has_agent ? agentGroups : null
      );
    } catch (error) {
      dialogs.notify(
        "danger",
        "Não foi possível remover o ajuste"
      );
    } finally {
      dialogs.notifyRemoved();
    }
  }
}

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}

/** 
 * Abrir modal para criar registro
 */
function openAdjustmentFinalSlumpCreateModal() {
  adjustmentFinalSlumpCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
function openAdjustmentFinalSlumpEditModal(id) {
  adjustmentFinalSlumpEditModal.value.openModal(id);
}

/**
 * Abrir modal para ajuste de limite
 */
function openLimitSettingCreateModal() {
  limitSettingCreateModal.value.handleCreateModal();
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  adjustmentFinalSlumpStore.mutations.resetStates();
  adjustmentFinalSlumpStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion


</script>

<style scoped>
</style>