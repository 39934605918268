<template>
  <div class="row card-wrapper container-all-header">
    <div class="col-lg-2 col-md-4 desktop pr-0">
      <HeaderCard class="active">
        <div class="rotate d-flex justify-content-between align-items-center">
          <span>Equipamento</span>
          <img src="/img/icons/lessThan_grey.png" width="16px" height="16px" />
        </div>
      </HeaderCard>
    </div>
    <!-- <div class="col-lg-0 action-prev desktop" @click="navigateSteps('prev')">
      <img src="/img/icons/icons8/ios/left-button.png" width="16px" height="16px" />
    </div> -->
    <div class="card-wrapper col-lg-10 col-md-6 container desktop">
      <div class="row justify-content-center align-items-center">
        <div
          class="col-lg-0 action-prev desktop"
          @click="navigateSteps('prev')"
        >
          <img
            src="/img/icons/icons8/ios/left-button.png"
            width="16px"
            height="16px"
          />
        </div>
        <div
          class="col-lg-2 col-md-6 container-header-card"
          v-for="(column, index) in renderColumns"
          :key="index"
        >
          <HeaderCard>
            <img
              src="/img/icons/calendar-gray.png"
              width="16px"
              height="16px"
            /><span style="font-size: 14px">{{ column.stringDate }}</span>
          </HeaderCard>
        </div>
        <div
          class="col-lg-0 action-next desktop"
          @click="navigateSteps('next')"
        >
          <img src="/img/icons/right-button.png" width="16px" height="16px" />
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-0 action-next desktop" @click="navigateSteps('next')">
      <img src="/img/icons/right-button.png" width="16px" height="16px" />
    </div> -->
  </div>
</template>

<script>
import HeaderCard from "./_HeaderCard";

export default {
  name: "HeaderAgenda",
  inheritAttrs: false,
  components: {
    HeaderCard,
  },
  props: {
    stepCalendarDays: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      renderColumns: [],
    };
  },
  watch: {
    columns: {
      immediate: true,
      handler(newVal) {
        this.renderColumns = newVal;
      },
    },
  },
  methods: {
    /**
     * @returns {void}
     */
    init() {
      this.renderColumns = this.columns;
    },
    /**
     * @param {Date} dateString
     * @returns {string}
     */
    formatDateString(dateString) {
      const date = new Date(dateString);
      const daysOfWeek = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
      const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];

      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${dayOfWeek} - ${day} ${month} ${year}`;
    },
    /**
     * @param {string} direction
     * @returns {void}
     */
    navigateSteps(direction) {
      if (!this.renderColumns.length) {
        return;
      }
      const newList = [];
      const act = direction === "next" ? 1 : -1;
      let currentDate = new Date(this.renderColumns[0].date);
      currentDate.setDate(currentDate.getDate() + act);

      [...Array(this.stepCalendarDays).keys()].forEach((indx) => {
        let newDate = new Date(currentDate.getTime());
        newDate.setDate(currentDate.getDate() + indx);

        newList.push({
          date: newDate,
          stringDate: this.formatDateString(newDate),
        });
      });
      this.renderColumns = newList;
      this.$emit("navigate-steps", currentDate);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
div.container-all-header div.container-header-card {
  padding: 0 0.5%;
}

div.container-all-header div.header-card > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-transform: uppercase;
  color: #2b2d32;
}

div.container-all-header div.header-card > div.rotate {
  width: 100%;
}

div.container-all-header div.header-card > div.rotate span {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-transform: uppercase;
  color: #2b2d32;
}

div.container-all-header div.header-card > div.rotate > img {
  transform: rotate(-90deg);
}

div.container-all-header
  > div.row.card-wrapper.col-lg-9
  > div.col-lg-3:last-child {
  position: relative;
}

div.container-all-header div.action-prev,
div.action-next {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f2f4f9;
  background-color: #f2f4f9;
  border-radius: 8px;
}
</style>
