<template>
  <div>
    <modal :show.sync="isOpen" class="bank-account-create" size="lg">
      <template #header>
        <AppIcon icon="plus-math" color="success" width="21" />
        <h5 class="modal-title">Nova Conta</h5>
      </template>

      <BankAccountForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import bankAccountStore from "../../store/bankAccountStore";
import companyStore from "../../../company/store/companyStore";
import companyPlantIssuerStore from "../../../company-plant-issuer/store/companyPlantIssuerStore";
import { BankAccountType, initBankAccountType } from "../../types";
import { AppIcon } from "../../../../../components/AppGlobal";
import BankAccountForm from "../shared/BankAccountForm.vue";
import { dialogs, progress } from "../../../../../helpers";

//#region Data
const isOpen = ref(false);
/** @type {BankAccountType} */
const formData = reactive(initBankAccountType());
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
  bankAccountStore.actions.fetchBanks();
  companyStore.actions.getListOptions();
  companyPlantIssuerStore.actions.getListOptions();
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initBankAccountType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  bankAccountStore.actions
    .create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} BankAccountCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";
@import './assets/bankAccountCreateModal.scss';
</style>
