<template>
  <div>
    <div class="mx-1">
      <el-popover
        class="ml-0"
        placement="left"
        trigger="click"
      >
        <div style="min-width: 250px;">
          <span>
            <span class="d-flex justify-content-center" style="font-weight: 500;color: #2B2D32;font-size: 13px;">
              AJUSTES TÉCNICOS
            </span>
            <hr class="hr-popover-technical-adjustment">
            <div class="w-100 justify-content-around d-flex mt-3">
              <span class="popover-content-font">Aglomerante</span>
              <hr class="flex-grow-1 mx-3 hr-divider-popover">
              <span
                :class="
                  props.adjustment.binder < 0
                  ? 'danger-color'
                  : props.adjustment.binder > 0
                  ? 'success-color'
                  : 'font-dark'
                "
                class="popover-content-font font-weight-normal"
              >
                  {{ (props.adjustment.binder || 0) + ' kg/m³' }}
              </span>
            </div>
            <div class="w-100 justify-content-around d-flex mt-2">
              <span class="popover-content-font">Água</span>
              <hr class="flex-grow-1 mx-3 hr-divider-popover">
              <span
                :class="
                  props.adjustment.water < 0
                  ? 'danger-color'
                  : props.adjustment.water > 0
                  ? 'success-color'
                  : 'font-dark'
                "
                class="popover-content-font font-weight-normal"
              >
                  {{ (props.adjustment.water || 0) + ' kg/m³' }}
              </span>
            </div>
            <div class="w-100 justify-content-around d-flex mt-2">
              <span class="popover-content-font">Argamassa</span>
              <hr class="flex-grow-1 mx-3 hr-divider-popover">
              <span
                :class="
                  props.adjustment.content_mortar < 0
                  ? 'danger-color'
                  : props.adjustment.content_mortar > 0
                  ? 'success-color'
                  : 'font-dark'
                "
                class="popover-content-font font-weight-normal"
              >
                  {{ (props.adjustment.content_mortar || 0) + ' %' }}
              </span>
            </div>
            <div class="w-100 justify-content-around d-flex mt-2">
              <span class="popover-content-font">Ar Total</span>
              <hr class="flex-grow-1 mx-3 hr-divider-popover">
              <span
                :class="
                  props.adjustment.total_air < 0
                  ? 'danger-color'
                  : props.adjustment.total_air > 0
                  ? 'success-color'
                  : 'font-dark'
                "
                class="popover-content-font font-weight-normal"
              >
                  {{ (props.adjustment.total_air || 0) + ' %' }}
              </span>
            </div>
            <div v-if="hasAgent(props.adjustment.agent)">
              <div v-for="(agentItem, agentItemIndex) in props.adjustment.agent">
                <div v-if="agentItem.id">
                  <div class="w-100 justify-content-around d-flex mt-2">
                    <span class="popover-content-font">{{ agentItem.name }}</span>
                    <hr class="flex-grow-1 mx-3 hr-divider-popover">
                    <span
                      :class="
                        agentItem.value < 0
                        ? 'danger-color'
                        : agentItem.value > 0
                        ? 'success-color'
                        : 'font-dark'
                      "
                      class="popover-content-font font-weight-normal"
                    >
                        {{ ( agentItem.value || 0) + ' %' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="props.adjustment.observation_mcc !== null" class="hr-popover-technical-adjustment mt-4">
            <div v-if="props.adjustment.observation_mcc !== null">
              <span class="d-flex justify-content-center mb-2" style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                OBSERVAÇÃO
              </span>
              <span class="popover-content-font">
                {{ props.adjustment.observation_mcc }}
              </span>
            </div>
          </span>
        </div>
        <base-button
          slot="reference"
          class="p-0 m-0 shadow-none"
          outiline
          size="sm"
          type="secundary"
        >
          <img src="/img/brand/logo.png" alt="logo" width="22px">
        </base-button>
      </el-popover>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { AdjustmentHistoryListType, initAdjustmentHistoryListType } from "../../../types";

/**
* @type {AdjustmentHistoryListType} props.adjustment
*/
const props = defineProps({
  adjustment: {
    type: Object,
    default: initAdjustmentHistoryListType(),
    description: 'Popover para exibir informações de ajustes técnicos.',
  },
});

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}

</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
}
.hr-popover-technical-adjustment {
  margin: 7px 0;
  width: 109%;
  margin-left: -14px;
}
.hr-divider-popover {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 10%;
}
.popover-content-font {
  font-size: 12px;
  font-weight: 300;
  color: #2B2D32;
}
.danger-color {
  color: #DB4539;
}
.success-color {
  color: #149E57;
}
</style>