import { render, staticRenderFns } from "./AdjustmentLevelResponsibilityCreateModal.vue?vue&type=template&id=20f918a9&scoped=true"
import script from "./AdjustmentLevelResponsibilityCreateModal.vue?vue&type=script&setup=true&lang=js"
export * from "./AdjustmentLevelResponsibilityCreateModal.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f918a9",
  null
  
)

export default component.exports