<template>
  <div class="bank-accounts-list-page">
    <AppFixedPageTitle
      title="CONTAS BANCÁRIAS"
      icon="/img/icons/icons8/ios/users.svg"
      class="invert-color"
    />

    <AppPageHeader>
      <template #search-bar>
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="$_bank_account_is_listing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        />
      </template>
      <template #header-buttons>
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            type="success"
            @click.prevent="openBankAccountCreateModal"
            text="novo"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      isShowViewTypes
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div
      class="container-fluid mt-4"
      v-if="$_bank_account_listed.items.length > 0"
    >
      <BankAccountListPageCards
        @onUpdateItemPix="handlePix"
        @onUpdateItemBillet="handleBillet"
        @onUpdateItemSettings="handleSettings"
        @onRemoveItemClick="removeBankAccount"
        @onEditItemClick="openBankAccountEditModal"
        v-if="listType === 'cards'"
      />
      <BankAccountListPageTable
        v-else
        @onUpdateItemPix="handlePix"
        @onUpdateItemBillet="handleBillet"
        @onUpdateItemSettings="handleSettings"
        @onRemoveItemClick="removeBankAccount"
        @onEditItemClick="openBankAccountEditModal"
      />
      <AppViewTrigger
        v-if="!$_bank_account_is_listing"
        @onIntersected="listItems(true)"
      />
    </div>
    <div class="container-fluid mt-4">
      <div class="row card-wrapper" v-if="$_bank_account_is_listing">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData
        v-else-if="
          !$_bank_account_listed.items.length && !$_bank_account_is_listing
        "
      />
    </div>

    <BankAccountCreateModal ref="bankAccountCreateModal" />
    <BankAccountEditModal ref="bankAccountEditModal" />
    <ModalBilletParamCreateEdit ref="modalBilletParamCreateEdit" />
    <ModalPixParamCreateEdit ref="modalPixParamCreateEdit" />
    <ModalBankAccountSettings ref="modalBankAccountSettings" />
  </div>
</template>

<script setup>
//#region Imports
import {
  computed,
  reactive,
  ref,
  onMounted,
  onUnmounted,
  Ref,
  getCurrentInstance,
} from "vue";

import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppTabSelect,
  AppSelect,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  SearchBarFilterType,
  initSearchBarFilterType,
  TabSelectItemType,
  AppViewTrigger,
} from "../../../../../components/AppGlobal";

import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";

import {
  BankAccountListFilterType,
  initBankAccountListFilterType,
} from "../../types";

import bankAccountStore from "../../store/bankAccountStore";
import BankAccountListPageCards from "./BankAccountListPageCards.vue";
import BankAccountListPageTable from "./BankAccountListPageTable.vue";

import BankAccountCreateModal from "../create/bankAccountCreateModal.vue";
import BankAccountEditModal from "../edit/bankAccountEditModal.vue";
import { BankAccountCreateModalExpose } from "../create/bankAccountCreateModal.vue";
import { dialogs } from "../../../../../helpers";
import { BankAccountEditModalExpose } from "../edit/bankAccountEditModal.vue";

import ModalBankAccountSettings from "../../../../../views/Modules/Financial/CashFlow/BankAccount/Shared/_ModalBankAccountSettings.vue";
import ModalBilletParamCreateEdit from "../../../../../views/Modules/Financial/CashFlow/BankAccount/Shared/_BilletParamCreateEdit.vue";
import ModalPixParamCreateEdit from "../../../../../views/Modules/Financial/CashFlow/BankAccount/Shared/_PixParamCreateEdit.vue";

//#endregion

//#region ComponentRefs
/** @type {Ref<BankAccountCreateModalExpose>} */
const bankAccountCreateModal = ref(null);

/** @type {Ref<BankAccountEditModalExpose>} */
const bankAccountEditModal = ref(null);

/**
 * @typedef {Object} ModalWithOpen
 * @property {() => void} openModal - Opens the modal
 */

/** @type {Ref<ModalWithOpen> } */
const modalBankAccountSettings = ref(null);

/**
 * @typedef {Object} ModalWithHandleCreate
 * @property {() => void} handleCreateModal - Handles the creation logic for the modal
 */

/** @type {Ref<ModalWithHandleCreate>} */
const modalBilletParamCreateEdit = ref(null);

/** @type {Ref<ModalWithHandleCreate>} */
const modalPixParamCreateEdit = ref(null);
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {BankAccountListFilterType} - Filtro principal */
const filter = reactive(initBankAccountListFilterType());

const { proxy } = getCurrentInstance();

/**
 * @type {import('vue').Ref<'cards' | 'table'>}
 */
const listType = ref("cards");

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "A-Z",
      selected_name: "A-Z",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [
        { column: "bank_accounts.status", is_desc: true },
        {
          column: "bank_accounts.name",
          is_desc: false,
        },
      ],
    },
    {
      id: 1,
      name: "Z-A",
      selected_name: "Z-A",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [
        { column: "bank_accounts.status", is_desc: true },
        {
          column: "bank_accounts.name",
          is_desc: true,
        },
      ],
    },
    {
      id: 2,
      name: "REGISTRO MAIS NOVO PARA O MAIS VELHO ",
      selected_name: "REG. MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [
        { column: "bank_accounts.status", is_desc: true },
        {
          column: "bank_accounts.created_at",
          is_desc: true,
        },
      ],
    },
    {
      id: 3,
      name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "REG. MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [
        { column: "bank_accounts.status", is_desc: true },
        {
          column: "bank_accounts.created_at",
          is_desc: false,
        },
      ],
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_bank_account_listed = computed(() =>
  bankAccountStore.getters.getListed()
);
const $_bank_account_is_listing = computed(() =>
  bankAccountStore.getters.getIsListing()
);

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: "Todos",
      selected: filter.status === null,
    },
    {
      id: true,
      name: "Ativo",
      selected: filter.status === true,
    },
    {
      id: false,
      name: "Inativo",
      selected: filter.status === false,
    },
  ];
});

//#endregion

//#region Methods
/**
 * Abrir modal configurações
 *
 * @param {number} id - Id da entidade
 */
const handleSettings = (id) => {
  modalBankAccountSettings.value.openModal(id);
};
/**
 * Abrir modal boleto
 *
 * @param {number} id - Id da entidade
 */
const handleBillet = (id) => {
  modalBilletParamCreateEdit.value.handleCreateModal(id);
};
/**
 * Abrir modal pix
 *
 * @param {number} id - Id da entidade
 */
const handlePix = (id) => {
  modalPixParamCreateEdit.value.handleCreateModal(id);
};
/**
 * Selecionar tipo de listagem
 *
 * @param {'card' | 'table'} type - Atualiza a lista após limpar os filtros.
 */
function handleViewTypeChange(type) {
  listType.value = type;
}
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  filter.status = item.id;
  listItems();
}

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters
    ? bankAccountStore.getters.getPageState()
    : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
}

/**
 * Padrão do filtro principal
 * @returns {BankAccountListFilterType}
 */
function defaultFilter() {
  return {
    ...initBankAccountListFilterType(),
    status: null,
  };
}

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  bankAccountStore.actions.list(filter, isAccumulateItems);
}

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
}

/**
 * Abrir modal para criar registro
 */
function openBankAccountCreateModal() {
  bankAccountCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
function openBankAccountEditModal(id) {
  bankAccountEditModal.value.openModal(id);
}

/**
 * Remover registro
 * @param {number} id
 */
async function removeBankAccount(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await bankAccountStore.actions.remove(id);
    dialogs.notifyRemoved();
  }
}

//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  bankAccountStore.mutations.resetStates();
  bankAccountStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>
