import {
  BaseListFilterType,
  initBaseListFilterType,
} from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} MixListFilterExtType
 * @property {null|0|1|2} status - null: Todos, 0: Inativo, 1: ativo, 2: rascunho
 * @property {number|null} type_ids - Seleciona Tipo da Formulação
 * @property {number|null} maximum_size_ids - Seleciona Dimensão maxima
 * @property {number|null} slump_flow_ids - Seleciona o Slump/Flow
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & MixListFilterExtType)} MixListFilterType
 */
export const MixListFilterType = {};

/**
 * Inicializar FinanceListFilterType
 *
 * @returns {MixListFilterType}
 */
export const initMixListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "id", is_desc: true }],
  custom_search: {
    columns: [],
    values: [],
  },
  status: 1, // Ativo
  type_ids: null,
  maximum_size_ids: null,
  slump_flow_ids: null,
});
