import BankAccountListPage from "../views/list/BankAccountListPage.vue";

export default [
  {
    path: '/configuration/bank-account',
    name: 'configuration.bank-account.view',
    component: BankAccountListPage,
    meta: {
      title: 'BankAccount',
      description: 'Contas',
      icon: 'fa fa-home',
      permission: 'configuration.cash-flow-bank-account.index.view',
      breadcrumbs: [
        { title: 'Dashboard', name: 'configuration.bank-account.dashboard.index' },
        { title: 'Contas', name: null },
      ]
    },
  },
];