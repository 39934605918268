<template>
  <div>
    <!-- Deixar automático até chegar nas Medium Screens -->
    <!-- Depois definir tamanho máximo como 520px -->
    <modal
      :show.sync="isOpen"
      :backgroundColor="'#DB4539'"
      bodyClasses="p-0"
      :showClose="false"
      size="md"
    >
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/error--v1.png"
            alt="Error"
            width="24"
          />
          <h5 class="modal-title new-default-black-font text-white m-0 ml-3"
            style="font-size: 16px;">
            {{ title }}
          </h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="modal_close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click.prevent="closeModal"
        />
      </template>
      <div class="container px-3 py-2">
        <div class="row">
          <div class="col-12">
            <h5 class="new-default-black-font font-weight-400 m-0"
              style="font-size: 13.5px;">
              ALTERAÇÃO DA REGRA CADASTRADA NO SISTEMA, DESEJA CONTINUAR?
            </h5>
          </div>
        </div>
      </div>
      <hr class="my-3">
      <div class="container px-4 mb-3">
        <div class="row">
          <!-- Botão para Acessar contrato (Sempre visível) -->
          <div class="col-12 text-right px-2" style="gap: 10px; display: flex; justify-content: flex-end">
            <AppButton
              label="Cancelar"
              type="danger"
              :isBlock="false"
              @click.prevent="closeModal"
            />
            <AppButton
              label="Continuar"
              type="primary"
              :isBlock="false"
              :hasOutline="true"
              @click.prevent="goOnWithChangeIssRetain"
            />
          </div>
          <!-- Caso haja boletos -->
        </div>
      </div>
    </modal>
  </div>
</template>
<script setup>
//#region Imports
import {defineEmits, onMounted, ref} from "vue";
import { AppButton } from "../../../../../../components/AppGlobal";
//#endregion

//#region ComponentRefs
//#endregion

//#region Data
const isOpen = ref(false);
const invoice = ref(null);
const title = "ALTERAR REGRA DE ISS?";

//#endregion

//#region Computeds
//#endregion

//#region Methods
function goOnWithChangeIssRetain() {
  emit("changeIssRetain", invoice.value);
  closeModal();
}
function openModal(item) {
  isOpen.value = true;
  invoice.value = item;
}
function closeModal() {
  isOpen.value = false;
}

/**
 * @typedef {Object} ModalSapBills
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
  closeModal,
});

const emit = defineEmits([
  "changeIssRetain",
]);
//#endregion

//#region Lifecycle
onMounted(() => {});
//#endregion
</script>
<style scoped>

</style>
