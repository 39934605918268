const keys = Object.freeze({
  ARQUIVEI: 0,
  EFIT: 1,
  PUZL: 2,
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.ARQUIVEI:
      return "Arquivei";
    case keys.EFIT:
      return "E-fit";
    case keys.PUZL:
      return "Puzl";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
