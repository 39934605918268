import {
  BaseListFilterType,
  initBaseListFilterType,
} from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} AdjustmentFinalSlumpListFilterExtType
 * @property {null|1|2|3} status - 1-Com Ajuste, 2-Sem Ajuste, null-Todos
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & AdjustmentFinalSlumpListFilterExtType)} AdjustmentFinalSlumpListFilterType
 */
export const AdjustmentFinalSlumpListFilterType = {};

/**
 * Inicializar AdjustmentFinalSlumpListFilterType
 *
 * @returns {AdjustmentFinalSlumpListFilterType}
 */
export const initAdjustmentFinalSlumpListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [{ column: "adjustment_final_slumps.id", is_desc: false }],
    custom_search: {
      columns: [],
      values: [],
    },
    status: null,
  };
};
