/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} EntryLaunchConfigurationType
 * @property {?number} id
 * @property {?string} url
 * @property {number} type [ 0=ARQUIVEI, 1=EFIT, 2=PUZL ]
 * @property {number} company_plant_issuer_id
 * @property {?string} arquivei_latest_date
 * @property {?string} efit_latest_cursor
 * @property {?string} puzl_latest_cursor
 * @property {?string} username
 * @property {?string} latest_token
 */
export const EntryLaunchConfigurationType = {};

/**
 * Inicializar EntryLaunchConfigurationType
 *
 * @returns {EntryLaunchConfigurationType}
 */
export const initEntryLaunchConfigurationType = () => {
  return {
    id: null,
    url: null,
    type: null,
    company_plant_issuer_id: null,
    arquivei_latest_date: null,
    efit_latest_cursor: null,
    puzl_latest_cursor: null,
    username: null,
    latest_token: null,
  };
};
