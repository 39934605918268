import store from "../../../../shared/libraries/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
    BASE: "/configuration/company/",
});

/** KEYS */
const keys = Object.freeze({
    ITEM: "BANK_ACCOUNT_ITEM",
    LISTED: "BANK_ACCOUNT_LISTED",
    IS_LISTING: "BANK_ACCOUNT_IS_LISTING",
    IS_FINDING: "BANK_ACCOUNT_IS_FINDING",
    IS_SAVING: "BANK_ACCOUNT_IS_SAVING",
    IS_REMOVING: "BANK_ACCOUNT_IS_REMOVING",
    PAGE_STATES: "BANK_ACCOUNT_PAGE_STATE",
    IS_LOADING_COMPANY_OPTIONS: "BANK_ACCOUNT_IS_LOADING_COMPANY_OPTIONS",
    COMPANY_ITEMS: 'COMPANY_ITEMS'
});

/** GETTERS */
const getters = {

    /** @returns {boolean} */
    getIsLoadingCompanyOptions: () => store.getState(keys.IS_LOADING_COMPANY_OPTIONS) ?? false,

    /** @returns {array}  */
    getCompanyItems: () => store.getState(keys.COMPANY_ITEMS) ?? [],
}

/** MUTATIONS */
const mutations = {

    /**
     * @param {boolean} isLoadingCompanyOptions
     * @returns {boolean}
     */
    setIsLoadingCompanyOptions: (isLoadingCompanyOptions) => store.commit(keys.IS_LOADING_COMPANY_OPTIONS, isLoadingCompanyOptions),

    /**
     * @param {array} CompanyOptionsItems
     * @returns {array}
     */
    setCompanyOptions: (CompanyOptions) => store.commit(keys.COMPANY_ITEMS, CompanyOptions),

    /** Resetar chaves de estado */
    resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {

    getListOptions: async () => {
        try {
            mutations.setIsLoadingCompanyOptions(true);

            const res = await httpClient.get(urls.BASE + 'list-options');
            if (res.status !== HttpStatusEnum.HTTP_OK) {
                handleUnexpectedStatusCode(res);
                return;
            }

            const bankItems = res.data.data
            return mutations.setCompanyOptions(bankItems)
        } finally {
            mutations.setIsLoadingCompanyOptions(false)
        }
    }
}

export default {
    actions,
    getters,
    mutations,
};
