import { AppSwitchVariants } from "../AppSwitchVariants";

/**
 * @typedef {Object} SwitchToogleLayoutType
 * @property {?string} label - O texto exibido para a opção.
 * @property {?string} icon - O ícone associado à opção. Utilize AppSwitchVariants.variants.icon
 * @property {?string} iconColor - A cor do ícone associado à opção. Utilize AppSwitchVariants.variants.iconColor
 * @property {?string} backgroundColor - A cor de fundo associada à opção.
 */

/**
 * @typedef {Object} SwitchToogleLayoutsType
 * @property {?SwitchToogleLayoutType} off - Configuração para o estado falso.
 * @property {?SwitchToogleLayoutType} on - Configuração para o estado verdadeiro.
 */
export const SwitchToogleLayoutsType = {};

/**
 * Inicializa um objeto do tipo SwitchToogleLayoutsType com valores padrão.
 * @returns {SwitchToogleLayoutsType}
 */
export const initSwitchToogleLayoutsType = () => {
  return {
    off: {
      label: "Inativo",
      icon: "shutdown", // AppSwitchVariants.variants.svg.shutdown, TODO: Verificar com o Francisco...
      iconColor: AppSwitchVariants.variants.iconColor.danger,
      backgroundColor: "#fcf3f3", // TODO: Criar variant para backgroundColor
    },
    on: {
      label: "Ativo",
      icon: "check", //AppSwitchVariants.variants.svg.check, TODO: Verificar com o Francisco...
      iconColor: AppSwitchVariants.variants.iconColor.success,
      backgroundColor: "#f2f7f3", // TODO: Criar variant para backgroundColor
    }
  };
};