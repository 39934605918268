import { PagerType, initPagerType } from "../../../../shared/types";
import { AgentType } from "../../cmc-adjustment/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} AdjustmentFinalSlumpListType
 * @property {number} id
 * @property {boolean} has_adjustment
 * @property {number} slump_flow_id
 * @property {number} ref_slump_flow_id
 * @property {string} nomenclature
 * @property {string} ref_nomenclature
 * @property {?number} binder
 * @property {?number} water
 * @property {?number} content_mortar
 * @property {?number} total_air
 * @property {?Array<AgentType>} agent
 * @property {?string} observation
 * @property {?Array<string>} company_plants
 * @property {string} created_by_user_name
 * @property {string} created_at
 */
export const AdjustmentFinalSlumpListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<AdjustmentFinalSlumpListType> } )} AdjustmentFinalSlumpListPagerType
 */
export const AdjustmentFinalSlumpListPagerType = {};

/**
 * Inicializar AdjustmentFinalSlumpListPagerType
 *
 * @returns {AdjustmentFinalSlumpListPagerType}
 */
export const initAdjustmentFinalSlumpListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  };
};
