/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} AdjustmentFinalSlumpType
 * @property {?number} id // [v/e]
 * @property {?boolean} has_adjustment // [v]
 * @property {?number} slump_flow_id
 * @property {?number} ref_slump_flow_id
 * @property {?Array<number>} company_plant_ids // [v]
 * @property {?number} binder // [v]
 * @property {?number} water // [v]
 * @property {?number} content_mortar // [v]
 * @property {?number} total_air // [v]
 * @property {?Array<AgentType>} agent // [v]
 * @property {?string} observation
 * @property {?string} created_at // [v]
 * @property {?string} created_by_user_name //[v]
 */
export const AdjustmentFinalSlumpType = {};

/**
 * Inicializar AdjustmentFinalSlumpType
 *
 * @returns {AdjustmentFinalSlumpType}
 */
export const initAdjustmentFinalSlumpType = () => ({
  id: null,
  slump_flow_id: null,
  ref_slump_flow_id: null,
  company_plant_ids: null,
  binder: null,
  water: null,
  content_mortar: null,
  total_air: null,
  agent: null,
  observation: null,
  created_at: null,
  created_by_user_name: null,
});
