<template>
  <card v-bind="$attrs">
    <div class="row align-items-center mb-1 mt-n3">
      <div class="col-9 justify-content-start p-0">
        <BodyCardStatus :status="hydrateService.status"></BodyCardStatus>
      </div>
      <div class="col-3 text-right pl-0">
        <base-dropdown menuOnRight>
          <div slot="title-container" class="dropdown-toggle rounded m-0 mr-n3">
            <img
              width="24"
              src="/img/icons/icons8/ios/settings--v1_primary.png"
            />
          </div>
          <a
            class="dropdown-item"
            style="display: flex; align-items: center"
            @click.prevent="
              handleScheduleService(service.schedules_uuid, service.uuid)
            "
          >
            <img
              width="18"
              src="https://img.icons8.com/ios/100/f2b532/create-new.png"
              alt="create-new"
            />
            <span class="text-right" style="font-size: 12px">Editar</span>
          </a>
          <a
            class="dropdown-item"
            style="display: flex; align-items: center"
            @click.prevent="
              handleModalInfo(service.schedules_uuid, service.uuid)
            "
          >
            <img
              width="18"
              src="/img/icons/info.png"
              class="pointer"
              title="Informações"
            />
            <span class="text-right" style="font-size: 12px">Informações</span>
          </a>
          <div class="dropdown-divider p-0 m-0"></div>
          <a
            class="dropdown-item"
            style="display: flex; align-items: center"
            @click.prevent="cancelService(service.uuid)"
            :disabled="service.schedule_service_status === statusList.CANCELED"
          >
            <img width="18" src="/img/icons/icons8/ios/delete_danger.png" />
            <span class="text-right" style="font-size: 12px">Cancelar</span>
          </a>
        </base-dropdown>
      </div>
    </div>
    <div class="flex-column row align-items-start mb-2 mt-2 d-content">
      <el-popover placement="right" trigger="click">
        <span slot="reference">{{
          hydrateService.customer_name > 15 && hydrateService.customer_name
            ? hydrateService.customer_name
            : hydrateService.customer_name.substring(0, 15)
        }}</span>
        <div class="d-content">
          <div
            style="
              padding: 1% 1% 1% 2%;
              background-color: #1a70b7;
              border: 1px solid #1a70b7;
              width: 55%;
              border-radius: 8px;
            "
          >
            <span style="font-size: 15px; color: #fff">{{
              hydrateService.proposal_code
            }}</span>
          </div>
          <span>{{ hydrateService.customer_name }}</span>
          <br />
          <span>{{ hydrateService.work_name }}</span>
        </div>
      </el-popover>
    </div>
    <div class="row justify-content-between d-content">
      <div class="d-content">
        <span
          class="d-flex align-items-center pointer"
          @click.prevent="
            handleCycleScheduleService(service.schedules_uuid, service.uuid)
          "
        >
          <img width="14" src="/img/icons/clock--v1.png" class="mr-1" />
          {{ hydrateService.start_date }} - {{ hydrateService.end_date }}
        </span>
        <span
          v-if="hydrateService.schedule_service_orders_code"
          class="d-flex align-items-center pointer mt-1"
          @click.prevent="
            handleServiceReport(hydrateService.schedule_service_orders_uuid)
          "
        >
          <img width="14" src="/img/icons/os.png" class="mr-1" /> nº
          {{ hydrateService.schedule_service_orders_code }}
        </span>
      </div>
      <span>{{ hydrateService.volume }} M³</span>
    </div>
  </card>
</template>

<script>
import moment from "moment";
import BodyCardStatus from "./_BodyCardStatus";
import { ScheduleServiceEnum } from "../Utils/ScheduleServiceEnum";

export default {
  name: "BodyCard",
  inheritAttrs: false,
  components: {
    BodyCardStatus,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hydrateService() {
      return {
        uuid: this.service.uuid,
        work_name: this.service.construction_name,
        volume:
          this.service.schedule_service_status === this.statusList.COMPLETED
            ? this.service.total_volume
            : this.service.expected_volume,
        status: this.service.schedule_service_status,
        proposal_code: this.service.contract_proposal_code,
        customer_name: this.service.customer_name,
        start_date: null,
        end_date: null,
        schedule_service_orders_code: this.service.schedule_service_orders_code,
        schedule_service_orders_uuid: this.service.schedule_service_orders_uuid,
        ...this.getTime({
          expected_start_date: this.service.expected_start_date,
          expected_end_date: this.service.expected_end_date,
          real_start_date: this.service.real_start_date,
          real_end_date: this.service.real_end_date,
        }),
      };
    },
  },
  data() {
    return {
      statusList: ScheduleServiceEnum,
      schedule: {},
    };
  },
  methods: {
    /**
     * @param {Object} params
     * @param {string} params.expected_start_date
     * @param {string} params.expected_end_date
     * @param {string} params.real_start_date
     * @param {string} params.real_end_date
     * @returns {Object}
     * @returns {string} return.start_date
     * @returns {string} return.end_date
     */
    getTime(params) {
      let start_date = params.expected_start_date;
      let end_date = params.expected_end_date;
      if (params.real_start_date) start_date = params.real_start_date;
      if (params.real_end_date) end_date = params.real_end_date;
      start_date = moment(start_date).format("HH:mm");
      end_date = moment(end_date).format("HH:mm");
      return { start_date, end_date };
    },
    /**
     * @param {string} uuid
     * @returns {void}
     */
    cancelService(uuid) {
      if (
        this.service.schedule_service_status === ScheduleServiceEnum.CANCELED
      ) {
        return this.$notify({
          type: "danger",
          message: "O serviço já está cancelado.",
        });
      }
      this.$emit("cancel-service-action", uuid);
    },
    /**
     * @param {string} uuidSchedule
     * @param {string} uuidService
     * @returns {void}
     */
    handleScheduleService(uuidSchedule, uuidService) {
      this.$emit("update-service-action", { uuidSchedule, uuidService });
    },
    /**
     * @param {string} uuidSchedule
     * @param {string} uuidService
     * @returns {void}
     */
    handleCycleScheduleService(uuidSchedule, uuidService) {
      this.$emit("update-cycle-action", { uuidSchedule, uuidService });
    },
    /**
     * @param {string} schedule_service_orders_uuid
     * @returns {void}
     */
    handleServiceReport(schedule_service_orders_uuid) {
      this.$emit("service-report-action", schedule_service_orders_uuid);
    },
    /**
     * @param {object} param
     * @returns {void}
     */
    handleChangeService(param) {
      this.$emit("updated-service", param);
    },
    /**
     * @param {string} uuidSchedule
     * @param {string} uuidService
     * @returns {void}
     */
    handleModalInfo(uuidSchedule, uuidService) {
      this.$emit("show-service-info", { uuidSchedule, uuidService });
    },
  },
};
</script>

<style scoped>
div.d-content > span {
  font-family: "Fredoka";
  color: #2b2d32;
  line-height: 16.94px;
  font-weight: 400;
  font-size: 13px;
}

a.dropdown-item[disabled] > img,
a.dropdown-item[disabled] > span.text-right {
  opacity: 0.4;
}
</style>
