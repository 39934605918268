import { computed, getCurrentInstance } from "vue";
import CmcAdjustmentLimitTypeEnum from "../../cmc-adjustment/enums/CmcAdjustmentLimitTypeEnum";
import { adjustmentLevelResponsibilityFormatEmptyAgentService } from "../services";
import { AdjustmentLevelResponsibilityListType } from "../types";

export function useAdjustmentLevelResponsabilityHook() {
  //#region Data
  const store = getCurrentInstance().proxy.$store;
  //#endregion

  //#region Computeds
  /**
   * Limites de Tipo
   */
  store.dispatch("adjustmentLimitSetting/getByType", 10);
  const allLimits = computed(
    () => store.getters["adjustmentLimitSetting/show"]
  );

  /**
   * Grupos de Aditivos
   */
  store.dispatch("cmcGroup/fetchGroupByCategory", 4);
  const agentGroups = computed(
    () => store.getters["cmcGroup/showGroupByCategory"]
  );

  /**
   * Centrais
   */
  store.dispatch("plant/getPlantsSimplified");
  const companyPlants = computed(() => store.getters["plant/activeItems"]);

  /**
   * Níveis de Aplicação
   */
  store.dispatch("mixType/fetchItems");
  const featureLevels = computed(
    () => store.getters["mixConcretePiece/feature_levels"]
  );

  //#endregion

  //#region Methods
  /**
   * Validação dos valores de ajuste
   * @param {number} limit_type
   * @param {number} value
   */
  function validateValueFromLimit(limit_type, value) {
    if (limit_type === CmcAdjustmentLimitTypeEnum.keys.BINDER) {
      if (value < parseInt(allLimits.value.binder.bottom)) {
        return parseInt(allLimits.value.binder.bottom);
      } else if (value > parseInt(allLimits.value.binder.top)) {
        return parseInt(allLimits.value.binder.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.WATER) {
      if (value < parseInt(allLimits.value.water.bottom)) {
        return parseInt(allLimits.value.water.bottom);
      } else if (value > parseInt(allLimits.value.water.top)) {
        return parseInt(allLimits.value.water.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR) {
      if (value < parseFloat(allLimits.value.content_mortar.bottom)) {
        return parseFloat(allLimits.value.content_mortar.bottom);
      } else if (value > parseFloat(allLimits.value.content_mortar.top)) {
        return parseFloat(allLimits.value.content_mortar.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR) {
      if (value < parseFloat(allLimits.value.total_air.bottom)) {
        return parseFloat(allLimits.value.total_air.bottom);
      } else if (value > parseFloat(allLimits.value.total_air.top)) {
        return parseFloat(allLimits.value.total_air.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.AGENT) {
      if (value < parseFloat(allLimits.value.agent.bottom)) {
        return parseFloat(allLimits.value.agent.bottom);
      } else if (value > parseFloat(allLimits.value.agent.top)) {
        return parseFloat(allLimits.value.agent.top);
      } else {
        return value;
      }
    }
  }

  /**
   *
   * @param {AdjustmentLevelResponsibilityListType} dosages
   */
  function resetAdjustmentValues(dosages) {
    dosages.binder = 0;
    dosages.water = 0;
    dosages.content_mortar = 0;
    dosages.total_air = 0;
    dosages.agent =
      adjustmentLevelResponsibilityFormatEmptyAgentService.execute();
    dosages.observation = null;
    dosages.is_adjusting = true;
  }

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toNegative(limit_type, value) {
    return validateValueFromLimit(limit_type, -Math.abs(value));
  }

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toPositive(limit_type, value) {
    return validateValueFromLimit(limit_type, Math.abs(value));
  }
  //#endregion

  return {
    allLimits,
    agentGroups,
    validateValueFromLimit,
    resetAdjustmentValues,
    toPositive,
    toNegative,
    companyPlants,
    featureLevels,
  };
}