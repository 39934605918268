import { initSwitchToogleLayoutsType } from ".";
import { AppIconVariants } from "../AppIcon";

export const AppSwitchVariants = Object.freeze({
  defaultVariants: Object.freeze({
    asyncClick: undefined,
    hasAsyncNotifications: true,
    toogleLayout: () => initSwitchToogleLayoutsType(),
    hasNotifications: false,
    isLoading: false,
    label: '',
    disabled: false,
  }),
  variants: Object.freeze({
    icon: AppIconVariants.variants.svg,
    iconColor: AppIconVariants.variants.color,
  }),
});