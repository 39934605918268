<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stock.company_plant_id"
                      :items="$_plants"
                      @input="getCmc(stock.company_plant_id, stock.cmc_category_id)"
                      :loading="loadingPlant"
                      :disabled="loadingPlant"/>
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Categoria MCC
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stock.cmc_category_id"
                      :items="$_cmcCategories"
                      @input="getCmc(stock.company_plant_id, stock.cmc_category_id)"
                      :loading="loadingCmcCategory"
                      :disabled="loadingCmcCategory"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  MCC
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stock.cmc_id"
                      :items="$_cmc"
                      @input="
                        getBulkDensity();
                        getMeasuredUnit();
                        getAverageHumidity();
                        getLastStockByPlantAndCmcCategoryAndCmcAndLocation();
                        getCmcPricePerKg()
                      "
                      :loading="loadingCmc"
                      :disabled="checkCmc"
                      :labelMask="true"
                      label="product_name$ | $supplier_name$ ($origin_name$)"/>
                  </base-input>
                </validation-provider>
              </div>

              <!--              <div class="col-md-6">-->
              <!--                <div class="pb-0 mb-1 col-form-label form-control-label">-->
              <!--                  Local de estoque-->
              <!--                  <span class="text-danger">&nbsp;*</span>-->
              <!--                </div>-->
              <!--                <validation-provider rules="required" v-slot="{errors}">-->
              <!--                  <base-input input-classes="form-control-sm">-->
              <!--                    <puzl-select-->
              <!--                      v-model="stock.company_plant_charge_point_location_id"-->
              <!--                      :items="stockLocations.filter((item) => item.company_plant_charge_point_location_name)"-->
              <!--                      @input="getLastStockByPlantAndCmcCategoryAndCmcAndLocation();getCmcPricePerKg()"-->
              <!--                      :loading="loadingStockLocation"-->
              <!--                      :disabled="checkCmc"-->
              <!--                      :labelMask="true"-->
              <!--                      label="dosage_location_name$ $code$ | $company_plant_charge_point_name$ | $company_plant_charge_point_location_name"/>-->
              <!--                  </base-input>-->
              <!--                </validation-provider>-->
              <!--              </div>-->
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Última medição
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm" v-if="stock.date_last_measurement">
                    {{ stock.date_last_measurement | parseDate('DD/MM/YYYY') }}
                    <input
                      v-model="stock.date_last_measurement"
                      @input="getCmcPricePerKg()"
                      type="hidden" />
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <div class="pb-0 col-form-label form-control-label">
                  Estoque inicial
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="stock.initial_stock" inputmode="numeric"
                           class="form-control form-control-sm" input-group-classes="input-group-sm" disabled
                           :class="errors[0] ? 'is-invalid' : 'is-valid'">
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        Kg
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Data de medição final
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <InputDateTimePicker
                      v-if="stock.date_last_measurement"
                      :disableMaxDate="new Date(moment().format('YYYY-MM-DD H:mm:ss'))"
                      v-model="stock.date_stock"
                      @input="modifyDateLastMeasurement();checkDate();getCmcPricePerKg()"
                      :mode="'date'" />
                    <InputDateTimePicker
                      disabled
                      v-else
                      v-model="stock.date_stock"
                      :mode="'date'" />
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <div class="pb-0 col-form-label form-control-label">
                  Unid. de Estoque
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stock.measured_unit_id"
                      :items="$_measuredUnitStock"
                      @input="getStockUnit"
                      @change="totalFinalStockKg(stock.measured_unit_id)"
                      label="letter"
                      :loading="loadingMeasuredUnit"
                      :disabled="loadingMeasuredUnit"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-4">
                <div class="pb-0 col-form-label form-control-label">
                  Massa unitária
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="stock.bulk_density" inputmode="numeric" v-mask="['#,##']"
                           class="form-control form-control-sm" input-group-classes="input-group-sm"
                           :class="errors[0] ? 'is-invalid' : 'is-valid'">
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        g/cm<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-4">
                <div class="pb-0 col-form-label form-control-label">
                  Umidade média
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="stock.average_humidity" v-mask="['#,#', '##,#']"
                      class="form-control form-control-sm"
                      input-group-classes="input-group-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    >
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        %
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-4">
                <div class="pb-0 col-form-label form-control-label">
                  Preço médio
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="stock.average_price" inputmode="numeric" disabled
                           class="form-control form-control-sm" input-group-classes="input-group-sm">
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        /Kg
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6">
                <div class="pb-0 col-form-label form-control-label">
                  Estoque final
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input v-show="labelStockUnit" input-group-classes="input-group-sm">
                    <input v-model="stock.final_stock" inputmode="numeric"
                           v-mask="['###.###,##', '##.###,##', '#####,##', '####,##', '###,##', '##,##', '#,##']"
                           class="form-control form-control-sm" input-group-classes="input-group-sm"
                           @input="totalFinalStockKg(stock.measured_unit_id)"
                           :class="errors[0] ? 'is-invalid' : 'is-valid'">
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        {{ labelStockUnit.description }}
                      </small>
                    </template>
                  </base-input>
                  <base-input v-show="!labelStockUnit" input-group-classes="input-group-sm">
                    <input disabled inputmode="numeric"
                           v-mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
                           class="form-control form-control-sm" input-group-classes="input-group-sm"
                    >
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <div class="pb-0 col-form-label form-control-label">
                  Estoque final
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="stock.final_stock_kg" inputmode="numeric"
                           class="form-control form-control-sm" input-group-classes="input-group-sm" disabled
                           :class="errors[0] ? 'is-invalid' : 'is-valid'">
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        Kg
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid || !stock.average_price" :loading="loadingStore">
                Salvar 
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins");
import {mapGetters} from 'vuex';
import {VMoney} from "v-money";
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {strToNum} from "@/helpers";

export default {
  name: "ModalCreate",
  components: {PuzlSelect, InputDateTimePicker},
  data() {
    return {
      modal: {
        title: 'Lançamento de estoque',
        create: false,
      },
      stock: {
        company_plant_id: null,
        cmc_category_id: null,
        cmc_id: null,
        // company_plant_charge_point_location_id: null,
        date_last_measurement: '',
        initial_stock: '',
        date_stock: new Date(),
        measured_unit_id: null,
        final_stock: '',
        bulk_density: '',
        average_humidity: '',
        outlet_humidity: '0',
        final_stock_kg: '',
        average_price: '',
        last_stock_location: 1,
      },
      moment: moment,
      loadingStore: false,
      loadingPlant: true,
      loadingCmcCategory: true,
      loadingCmc: false,
      loadingStockLocation: false,
      loadingMeasuredUnit: true,
      checkCmc: true,
      firstStockRecordWithFilter: true,
      stockLocations: [],
      labelStockUnit: '',
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    };
  },
  computed: {
    ...mapGetters({
      $_plants: 'plant/fetch',
      $_cmcCategories: 'category/fetch',
      $_cmc: 'cmc/fetch',
      $_user: 'auth/getUser',
      $_measuredUnitStock: 'measuredunit/measured_unit_stock',
    })
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  directives: {
    money: VMoney
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async handleCreateModal() {
      this.loadData();
      this.stock = {
        company_plant_id: null,
        cmc_category_id: null,
        cmc_id: null,
        // company_plant_charge_point_location_id: null,
        date_last_measurement: '',
        initial_stock: null,
        date_stock: new Date(),
        measured_unit_id: null,
        final_stock: '',
        bulk_density: '',
        average_humidity: '',
        outlet_humidity: '0',
        final_stock_kg: '',
        average_price: '',
        last_stock_location: 1,
      }
      this.modal.create = true;
      this.checkCmc = true;
      this.labelStockUnit = '';
    },
    /**
     * Busca o preço médio por kg
     */
    getCmcPricePerKg() {
      if (![this.stock.company_plant_id,
        this.stock.date_stock,
        this.stock.date_last_measurement,
        this.stock.cmc_id].includes(null)) {
        const cmc_uuid = this.$_cmc.find((item) => item.id === this.stock.cmc_id).uuid
        const company_plant_uuid = this.$_plants.find((item) => item.id === this.stock.company_plant_id).uuid
        this.$store.dispatch("stock/getCmcPricePerKg", {
          cmc_uuid: cmc_uuid,
          cmc_id: this.stock.cmc_id,
          company_plant_id: this.stock.company_plant_id,
          company_plant_uuid: company_plant_uuid,
          start_at: this.stock.date_last_measurement,
          end_at: this.stock.date_stock
        }).then((response) => {
          this.stock.average_price = response.data.toString().replace('.', ',')
        });
      }
    },
    /**
     * Busca o último estoque medido
     * @returns {Promise<void>}
     */
    async getLastStockByPlantAndCmcCategoryAndCmcAndLocation() {
      if (!this.stock.cmc_id) {
        return;
      }
      await this.$store.dispatch("stock/getLastStockByPlantAndCmcCategoryAndCmcAndLocation", {
        company_plant_id: this.stock.company_plant_id,
        category_id: this.stock.cmc_category_id,
        cmc_id: this.stock.cmc_id,
        company_plant_charge_point_location_id: null,
      })
        .then((response) => {
          if (response.data) {
            this.firstStockRecordWithFilter = false;
          } else {
            this.firstStockRecordWithFilter = true;
          }
          this.stock.initial_stock = response.data ? response.data.final_stock_kg : 0;
          this.stock.date_last_measurement = response.data ? response.data.date_stock : this.stock.date_stock;
        });
      if (this.stock.date_stock < this.stock.date_last_measurement) {
        this.stock.date_stock = this.stock.date_last_measurement;
      }
    },
    /**
     * Converte o valor do estoque de acordo com a unidade de medida
     *
     * @param {number} measured_unit_id
     */
    totalFinalStockKg(measured_unit_id) {
      const final_stock = strToNum(this.stock.final_stock);
      const bulk_density = strToNum(this.stock.bulk_density);
      switch (measured_unit_id) {
        case 1: //m3
          this.stock.final_stock_kg = (final_stock * bulk_density * 1000).toLocaleString("pt-br", {
          })
          break;
        case 4: //ton
          this.stock.final_stock_kg = (final_stock * 1000).toLocaleString("pt-br", {
          })
          break;
        case 6: //kg
          this.stock.final_stock_kg = final_stock.toLocaleString("pt-br", {
          })
          break;
        case 8: //g
          this.stock.final_stock_kg = (final_stock / 1000).toLocaleString("pt-br", {
          })
          break;
        case 26: //l
          this.stock.final_stock_kg = (final_stock * bulk_density).toLocaleString("pt-br", {
          })
          break;
      }
    },
    /**
     * Busca a descrição da unidade de medida
     */
    getStockUnit() {
      this.labelStockUnit = this.$_measuredUnitStock.find(item => item.id === this.stock.measured_unit_id);
    },
    /**
     * Busca Massa unitária
     */
    getBulkDensity() {
      this.$_cmc.find(item => {
        if (item.id === this.stock.cmc_id) {
          this.$nextTick(() => {
            this.stock.bulk_density = item.bulk_density;
          });
        }
      });
    },
    /**
     * Busca Unidade de médida
     */
    getMeasuredUnit() {
      this.$_cmc.find(item => {
        if (item.id === this.stock.cmc_id) {
          this.$nextTick(() => {
            this.stock.measured_unit_id = item.measured_unit_id_stock;
            this.getStockUnit();
          });
        }
      });
    },
    /**
     * Insere a média de humidade
     */
    getAverageHumidity() {
      this.$_cmc.find(item => {
        if (item.id === this.stock.cmc_id) {
          this.$nextTick(() => {
            this.stock.average_humidity = item.average_humidity;
          });
        }
      });
    },
    /**
     * Insere a data da ultima medição
     */
    modifyDateLastMeasurement() {
      if (this.firstStockRecordWithFilter && this.stock.date_stock) {
        this.stock.date_last_measurement = this.stock.date_stock;
      }
    },
    /**
     * Verifica se a data da medição atual é maior do que a ultima medição
     */
    checkDate() {
      if (this.stock.date_stock < this.stock.date_last_measurement) {
        this.$nextTick(() => {
          this.stock.date_stock = null;
        });
        this.$notify({
          type: 'warning',
          message: 'Data de medição não pode ser menor que a data da última medição!'
        });
      }
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true;
      let payload = {...this.stock};
      payload.average_price = strToNum(payload.average_price);
      payload.initial_stock = strToNum(payload.initial_stock);
      payload.final_stock = strToNum(payload.final_stock);
      payload.bulk_density = strToNum(payload.bulk_density);
      payload.final_stock_kg = strToNum(payload.final_stock_kg);
      payload.average_humidity = strToNum(payload.average_humidity);
      payload.cmc_uuid = this.$_cmc.find((item) => item.id === this.stock.cmc_id).uuid
      payload.date_stock = this.$helper.parseDate(payload.date_stock, 'YYYY-MM-DD HH:mm:ss')
      this.$store.dispatch('stock/add', payload)
        .then((response) => {
          // Envia o id do registro anterior da mesma localização.
          this.$emit("updatedPreviousStock", response.data.previous_stock_id);
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
            this.$Progress.finish();
            this.loadingStore = false;
          }
        })
    },
    /**
     * Busca os mccs por central e categoria
     * @param {number} companyPlantId
     * @param {number} cmcCategoryId
     */
    getCmc(companyPlantId, cmcCategoryId) {
      if (this.stock.company_plant_id && this.stock.cmc_category_id) {
        this.loadingCmc = true;
        this.$store.dispatch('cmc/getCmcByPlantAndCategory', {
          company_plant_id: companyPlantId,
          category_id: cmcCategoryId,
        })
          .then(() => {
            this.stock.cmc_id = null;
            this.checkCmc = false;
            this.loadingCmc = false;
          });
      }
    },
    loadData() {
      this.loadingPlant = true;
      this.$store.dispatch('plant/getCompanyPlantByUser', this.$_user.id).then(() => {
        this.loadingPlant = false;
      });
      this.loadingCmcCategory = true;
      this.$store.dispatch('category/getCmcCategories').then(() => {
        this.loadingCmcCategory = false;
      });
      this.loadingMeasuredUnit = true;
      this.$store.dispatch('measuredunit/fetchItems').then(() => {
        this.loadingMeasuredUnit = false;
      });
    },
    // /**
    //  * Busca os locais de estoque
    //  * @param {number} companyPlantId
    //  * @param {number} cmcCategoryId
    //  */
    // getStockLocation(companyPlantId, cmcCategoryId) {
    //   if (this.stock.company_plant_id && this.stock.cmc_category_id) {
    //     this.loadingStockLocation = true;
    //     this.$store.dispatch('cmc/getStockLocationByPlantAndCategory', {
    //       company_plant_id: companyPlantId,
    //       category_id: cmcCategoryId,
    //     })
    //       .then((response) => {
    //         this.stock.company_plant_charge_point_location_id = null;
    //         this.stockLocations = response.data;
    //         this.loadingStockLocation = false;
    //       });
    //   }
    // },
  },
};
</script>

<style scoped></style>
