import { BaseListFilterType, initBaseListFilterType } from "../../../../../shared/types";
import moment from "moment";

/**
 * Função para calcular o próximo dia útil
 * @returns {string} Data no formato YYYY-MM-DD
 */
function getNextBusinessDay() {
  let nextDate = moment().add(1, "day");

  // Ignora finais de semana
  while (nextDate.isoWeekday() > 5) {
    nextDate.add(1, "day");
  }

  return nextDate.format("YYYY-MM-DD");
}

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} DriverScaleListFilterExtType
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & DriverScaleListFilterExtType)} DriverScaleListFilterType
 */
export const DriverScaleListFilterType = {};

/**
 * Inicializar DriverScaleListFilterType
 *
 * @returns {DriverScaleListFilterType}
 */
export const initDriverScaleListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    date: getNextBusinessDay(),
    company_plant_id: null,
  };
}
