import { render, staticRenderFns } from "./_ModalAddItems.vue?vue&type=template&id=084d8be2&scoped=true"
import script from "./_ModalAddItems.vue?vue&type=script&lang=js"
export * from "./_ModalAddItems.vue?vue&type=script&lang=js"
import style0 from "./_ModalAddItems.vue?vue&type=style&index=0&id=084d8be2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084d8be2",
  null
  
)

export default component.exports