import AdjustmentHistoryActionsEnum from "../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import AdjustmentHistoryTypesEnum from "../../adjustment-history/enums/AdjustmentHistoryTypesEnum";
import AdjustmentEnum from "../../adjustment-history/enums/AdjustmentEnum";
import { AdjustmentLevelResponsibilityType } from "../types";
import { AgentType } from "../../cmc-adjustment/types";
import { AdjustmentHistoryCreateType } from "../../adjustment-history/types";
import adjustmentHistoryStore from "../../adjustment-history/store";
import { AdjustmentLevelResponsibilityLevelFeatureEnum } from "../enums";

/**
 * Serviço que faz a manipulação dos objetos
 * para criação de histórico (ajuste, edição e remoção)
 * de Tipo
 *
 * @param {number} action_id
 * @param {AdjustmentLevelResponsibilityType } adjustment
 * @param {?Array<object>} agentGroups - array de grupos de aditivo
 */
const execute = (action_id, adjustment, agentGroups = null) => {
  switch (action_id) {
    case AdjustmentHistoryActionsEnum.keys.ADJUST:
      const agent = formatAgent(adjustment.agent, agentGroups);

      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectAdjustmentLevelResponsibilityAdjust = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.LEVEL_RESPONSIBILITY,
        title: AdjustmentLevelResponsibilityLevelFeatureEnum.getTitle(
          adjustment.level_feature
        ),
        company_plant_ids: adjustment.company_plant_ids,
        binder: adjustment.binder,
        water: adjustment.water,
        content_mortar: adjustment.content_mortar,
        total_air: adjustment.total_air,
        agent: agent,
        observation_mcc: adjustment.observation,
      };

      adjustmentHistoryStore.create(
        historyObjectAdjustmentLevelResponsibilityAdjust
      );
      break;

    case AdjustmentHistoryActionsEnum.keys.EDIT:
      const agentEdited = formatAgent(adjustment.agent, agentGroups);

      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectAdjustmentLevelResponsibilityEdit = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.LEVEL_RESPONSIBILITY,
        title: AdjustmentLevelResponsibilityLevelFeatureEnum.getTitle(
          adjustment.level_feature
        ),
        company_plant_ids: adjustment.company_plant_ids,
        binder: adjustment.binder,
        water: adjustment.water,
        content_mortar: adjustment.content_mortar,
        total_air: adjustment.total_air,
        agent: agentEdited,
        observation_mcc: adjustment.observation,
      };

      adjustmentHistoryStore.create(
        historyObjectAdjustmentLevelResponsibilityEdit
      );
      break;

    case AdjustmentHistoryActionsEnum.keys.REMOVE:
      const agentRemoved = formatAgent(adjustment.agent, agentGroups);

      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectAdjustmentLevelResponsibilityRemove = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.LEVEL_RESPONSIBILITY,
        title: AdjustmentLevelResponsibilityLevelFeatureEnum.getTitle(
          adjustment.level_feature
        ),
        company_plant_ids: adjustment.company_plant_ids,
        binder: adjustment.binder,
        water: adjustment.water,
        content_mortar: adjustment.content_mortar,
        total_air: adjustment.total_air,
        agent: agentRemoved,
        observation_mcc: adjustment.observation,
      };

      adjustmentHistoryStore.create(
        historyObjectAdjustmentLevelResponsibilityRemove
      );
      break;

    default:
      break;
  }
};

/**
 * Se o ajuste conter aditivo, é necessário
 * salvar o nome do grupo para apresentar
 * no histórico. Serviço criado para
 * não precisar acessar o backend.
 *
 * @param {Array<AgentType>} agents
 * @param {Array<Object>} agentGroups
 * @returns {Array<AgentType>}
 */
const formatAgent = (agents, agentGroups) => {
  if (agentGroups !== null && agents !== null) {
    const nameMap = agentGroups.value.reduce((acc, item) => {
      acc[item.id] = item.group_name;
      return acc;
    }, {});

    return agents.map((item) => ({
      ...item,
      name: nameMap[item.id],
    }));
  } else {
    return agents;
  }
};

export default { execute };
