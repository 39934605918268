<template>
    <div>
      <div class="row">
        <ShadedTable
          :length="$_driver_scales_listed.items.length"
          :headers="headers"
          :loading="$_driver_scales_is_listing"
        >
          <template v-for="(item, index) in $_driver_scales_listed.items" :slot="index">
            <ShadedCol>
              <h5
                :title="item.date"
                class="new-default-black-font font-weight-normal m-0 mt-1 text-center"
              >
                {{ item.time }}
              </h5>
            </ShadedCol>
            <ShadedCol>
              <h5
                :title="item.first_travel_created_at |  parseDate('DD MMM YYYY')"
                class="new-default-black-font font-weight-normal m-0 mt-1 text-center"
              >
                <div v-if="item.first_travel_created_at">
                  {{ item.first_travel_created_at | parseDate("hh:mm") }}

                  <span :style="{ color: getDifferenceColor(item.time, item.first_travel_created_at) }">
                    ({{ getTimeDifference(item.time, item.first_travel_created_at) }})
                  </span>
                </div>
              </h5>
            </ShadedCol>
            <ShadedCol>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-center">
                {{ item.sequencial_number_plant_number }}
              </h5>
            </ShadedCol>
            <ShadedCol>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-center">
                {{ item.xml_travel_number }}
                <img style="opacity: 35%" class="ml-2" width="15px" src="/img/nfe.png">
              </h5>
            </ShadedCol>
            <ShadedCol>
              <h5
                :title="item.company_plant_name"
                class="new-default-black-font font-weight-normal m-0 mt-1"
              >
                {{ item.driver_name }}
              </h5>
            </ShadedCol>
            <ShadedCol>
              <div class="text-center">
                <base-dropdown menuOnRight>
                    <div
                      slot="title-container"
                      class="dropdown-toggle rounded m-0"
                    >
                      <AppIcon icon="settings" color="primary" width="25" />
                    </div>
                    <a class="dropdown-item" @click.prevent="onRemoveItemClick(item.id)">
                      <img width="18" src="/img/icons/delete.png" />
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
            </ShadedCol>
          </template>
        </ShadedTable>
      </div>
    </div>
  </template>
  
  <script setup>
  //#region === Imports ===
  import { ref, computed, defineEmits } from "vue";
  import ShadedTable from "../../../../../components/ShadedTable";
  import ShadedCol from "../../../../../components/ShadedCol";
  import {AppIcon} from "../../../../../components/AppGlobal";
  import driverScaleStore from '../store/driverScaleStore';
  import moment from 'moment';
  //#endregion

  //#region === Emits ===
const emit = defineEmits([
  "onRemoveItemClick"
]);
//#endregion

  //#region === Data ===
  const headers = ref([
    "Previsto",
    "Realizado",
    "O.s",
    "Danfe",
    "Motorista",
    "Ação",
  ]);
  //#endregion

  //#region === Computeds ===
  const $_driver_scales_listed = computed(() => driverScaleStore.getters.getListed());
  const $_driver_scales_is_listing = computed(() => driverScaleStore.getters.getIsListing());
  //#endregion

  //#region === Methods ===
  /**
   * Remover item
   * @param {number} id
   */
  function onRemoveItemClick(id) {
    emit("onRemoveItemClick", id);
  }

  /**
   * Calcula a diferença entre o horário previsto e o horário realizado.
   * @param {string} predictedTime - 'HH:mm'.
   * @param {string|null} firstTravelTime -'HH:mm' || null
   * @returns {string}
   */
  function getTimeDifference (predictedTime, firstTravelTime) {
    const predicted = moment(predictedTime, 'HH:mm')
    const realized = moment(firstTravelTime).format('HH:mm')
    
    const diff = moment.duration(moment(realized, 'HH:mm').diff(predicted))
    const hours = Math.floor(diff.asHours())
    const minutes = diff.minutes()
    
    const signal = (hours >= 0 || minutes >= 0) ? '+' : ''
    return `${signal}${String(hours).padStart(2, '0')}:${String(Math.abs(minutes)).padStart(2, '0')}`
  }

  /**
   * Retorna a cor para indicar a diferença entre os horários 
   * (vermelho para atraso, verde para pontualidade ou adiantado).
   * @param {string} predictedTime - 'HH:mm'.
   * @param {string|null} firstTravelTime -'HH:mm' || null
   * @returns {string}
   */
  function getDifferenceColor (predictedTime, firstTravelTime) {
    const predicted = moment(predictedTime, 'HH:mm')
    const realized = moment(firstTravelTime).format('HH:mm')
    
    const diff = moment(realized, 'HH:mm').diff(predicted)
    return diff > 0 ? '#ff0000' : '#008000'
  }
  //#endregion
  </script>
  
  <style scoped>
  * {
    font-family: Fredoka;
  }
  </style>
  