<template>
  <div>
    <modal size="md"
      :show.sync="isOpen"
      :backgroundColor="'#F2F4F9'"
      :showClose="false"
      bodyClasses="p-0"
    >
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/print-primary.png"
            alt="history"
            width="24"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-3">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          type="link"
          width="auto"
          size="sm"
          :hasOutline="true"
          @click.prevent="close"
        />
      </template>
      <div class="container px-4 py-2">
        <div class="row">
          <div class="col-md-12 mt-1 mb-2 text-left">
            <label>Selecione o mês:</label>
            <PuzlSelect
              :clearable="false"
              placeholder="Mês"
              v-model="selected_month"
              :items="months"
            />
          </div>
        </div>
      </div>
      <hr class="my-3">
      <div class="container px-4 mt-4 mb-3">
        <div class="row">
          <!-- Botão para Acessar contrato (Sempre visível) -->
          <div class="col-12 text-right px-3" style="gap: 10px; display: flex; justify-content: flex-end">
            <AppButton
              label="Cancelar"
              type="danger"
              :isBlock="false"
              :hasOutline="true"
              @click.prevent="close"
            />
            <AppButton
              label="Continuar"
              type="primary"
              :isBlock="false"
              :hasOutline="true"
              @click.prevent="generate"
            />
          </div>
          <!-- Caso haja boletos -->
        </div>
      </div>
    </modal>
  </div>
</template>
<script setup>
import {AppButton} from "@/components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {
  ref,
  defineEmits,
} from "vue";

const title = "Imprimir relatório DRE";
const isOpen = ref(false);
const months = [
  {
    id: 1,
    name: "Janeiro",
  },
  {
    id: 2,
    name: "Fevereiro",
  },
  {
    id: 3,
    name: "Março",
  },
  {
    id: 4,
    name: "Abril",
  },
  {
    id: 5,
    name: "Maio"
  },
  {
    id: 6,
    name: "Junho"
  },
  {
    id: 7,
    name: "Julho"
  },
  {
    id: 8,
    name: "Agosto",
  },
  {
    id: 9,
    name: "Setembro",
  },
  {
    id: 10,
    name: "Outubro",
  },
  {
    id: 11,
    name: "Novembro",
  },
  {
    id: 12,
    name: "Dezembro",
  }
];
const selected_month = ref(months[0].id);

async function generate(){
  const month = months[selected_month.value-1];
  close();
  emit('onSelected', month);
}
function close() {
  isOpen.value = false;
}

function openModal(){
  isOpen.value = true;
}

/**
 * @typedef {Object} GenerateReportModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});

/** Eventos emitidos */
const emit = defineEmits([
  "onSelected",
]);
</script>
<style scoped>

</style>
