import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} BankAccountListFilterExtType
 * @property {boolean|null} status - false-Inativo, true-Ativo, null-Todos
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & BankAccountListFilterExtType)} BankAccountListFilterType
 */
export const BankAccountListFilterType = {};

/**
 * Inicializar BankAccountListFilterType
 *
 * @returns {BankAccountListFilterType}
 */
export const initBankAccountListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}
