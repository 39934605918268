const keys = Object.freeze({
  NONE: "none",
  SOLID: "solid",
  DASHED: "dashed",
  DOTTED: "dotted",
  INSET: "inset",
  OUTSET: "outset",
});

export default {
  keys,
};
