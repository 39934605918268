/**
 * Type para parcela/Boleto do SAP
 *
 * @typedef {Object} InvoiceGetSapBankBilletInstallmentsType
 * @property {number} invoice_id
 * @property {number} external_id
 * @property {string} our_number
 * @property {string} expire_at
 * @property {number} amount
 * @property {boolean} is_pending
 * @property {string} base64
 */
export const InvoiceGetSapBankBilletInstallmentsType = {};

/**
 * Inicializar InvoiceGetSapBankBilletInstallmentsType
 * 
 * @returns {InvoiceGetSapBankBilletInstallmentsType}
 */
export const initInvoiceGetSapBankBilletInstallmentsType = () => {
  return {
    invoice_id: 0,
    external_id: 0,
    our_number: '',
    expire_at: '',
    amount: 0,
    is_pending: false,
    base64: '',
  }
};