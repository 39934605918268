<template>
  <div>
    <AppFixedPageTitle
      title="Agenda de Serviços"
      icon="/img/icons/icons8/ios/services-white.svg"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :rangeParams="{ is_range: false }"
          @onClearClick="resetDefaultSearchBarFilter(true)"
          @onSearchClick="handleFilterDate(searchBarFilter.range.start)"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/progress-indicator_grey.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="searchBarFilter.statusId"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                class="select-xl col-md-12 px-0 new-default-black-font"
                @input="othersFilters"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <label
              class="form-control-label fs-12 new-default-black-font font-weight-400"
            >
              Categoria de Equipamento
            </label>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="searchBarFilter.equipment_category_id"
                :items="equipmentCategoriesHydrate"
                :disableBoxShadow="true"
                placeholder="Selecione"
                class="select-xl col-md-12 px-0 new-default-black-font"
                @input="othersFilters"
                @change="categoryChange"
              />
            </div>
            <label
              class="form-control-label fs-12 new-default-black-font font-weight-400"
            >
              Tipo de Equipamento
            </label>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="searchBarFilter.equipment_type_id"
                :items="categoriesTypes"
                :disableBoxShadow="true"
                placeholder="Selecione"
                class="select-xl col-md-12 px-0 new-default-black-font"
                @input="othersFilters"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <base-button
            block
            type="light"
            class="new-puzl-default"
            style="border-radius: 50px !important"
            @click.prevent="$router.go(-1)"
          >
            <img width="19" src="/img/icons/circled-left.png" class="mr-1" />
            Voltar
          </base-button>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <div class="col-md-12">
      <AppTabSelect
        :isMultiSelect="false"
        :items="tabSelectItems"
        @onTabSelectItemClick="onTabSelectItemClick"
        :isShowViewTypes="false"
      >
      </AppTabSelect>
      <card>
        <HeaderAgenda
          :columns="headersHydrate"
          :stepCalendarDays="stepCalendarDays"
          @navigate-steps="handleNavigateSteps"
        >
        </HeaderAgenda>
        <BodyAgenda
          :items="transformedItems"
          :idList="idList"
          :loading="loading"
          @updated-service="updatedService"
        ></BodyAgenda>
      </card>
    </div>
    <LoadingPagination :show="loadingStatus" />
  </div>
</template>

<script>
import {
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
} from "@/components/AppGlobal";
import { mapGetters } from "vuex";
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";
import HeaderAgenda from "./Shared/_HeaderAgenda/_HeaderAgenda";
import BodyAgenda from "./Shared/_BodyAgenda/_BodyAgenda";
import LoadingPagination from "@/components/LoadingPagination";
import { areEqualObjects } from "../../../../../helpers";
import { ScheduleServiceSearchTypeByDateEnum } from "./Shared/Utils/ScheduleServiceSearchTypeByDateEnum";

export default {
  name: "ScheduleServiceForAgenda",
  components: {
    PuzlSelect,
    HeaderAgenda,
    BodyAgenda,
    AppSearchBar,
    AppTabSelect,
    AppSearchBarFilterSection,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeader,
    LoadingPagination,
  },
  data() {
    return {
      searchBarFilter: initSearchBarFilterType(),
      filter: {
        calendar_date: moment().format("YYYY-MM-DD"),
        company_plant_id: null,
        search: [],
        statusId: null,
        equipment_category_id: null,
        equipment_type_id: null,
        step_calendar_days: null,
        start: 0,
        limitByPage: 25,
        type: ScheduleServiceSearchTypeByDateEnum.EXPECTED_START
      },
      loading: false,
      loadingStatus: false,
      stepCalendarDays: 5,
      statusList: {},
      statusSelected: "Todos",
      status: this.initStates(),
      categoriesTypes: [],
      list: {},
      idList: [],
    };
  },
  watch: {
    $_company_plants(newValue, oldValue) {
      if (newValue) {
        this.resetDefaultSearchBarFilter(true);
      }
    },
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items: "scheduleServiceAgenda/fetch",
      $_equipment_categories: "equipmentCategory/fetch",
    }),
    transformedItems() {
      if (!this.filter.start) {
        this.list = {};
      }
      let list = this.list;
      this.status = this.initStates();
      if (Object.keys(this.$_items).length === 0) {
        return list;
      }
      const headersList = this.headersHydrate.map((item) => item.stringDate);
      this.status[
        this.status.findIndex((current) => current && current.id === null)
      ].quantity = Object.keys(this.$_items).length;
      this.$_items.forEach((item) => {
        let stateIndex = this.status.findIndex(
          (current) => current && current.id === item.schedule_service_status
        );
        if (stateIndex !== -1) {
          this.status[stateIndex].quantity =
            this.status[stateIndex].quantity + 1;
        }

        if (item.equipment_code === null)
          item.equipment_code = "Sem equipamentos";
        if (!list[`${item.equipment_code}_${item.service_description}`]) {
          list[`${item.equipment_code}_${item.service_description}`] = {};
          for (const value of headersList) {
            list[`${item.equipment_code}_${item.service_description}`][value] =
              [];
          }
        }
        let newDate = new Date(
          +this.filter.type === ScheduleServiceSearchTypeByDateEnum.EXPECTED_START ? item.expected_start_date : item.real_start_date
        );
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        let formatDateString = this.formatDateString(newDate);
        if (
          list[`${item.equipment_code}_${item.service_description}`] &&
          list[`${item.equipment_code}_${item.service_description}`][
            formatDateString
          ]
        ) {
          this.idList.push(item.schedule_service_orders_uuid);
          let current = {
            schedule_service_id: item.schedule_service_id,
            schedule_service_orders_id: item.schedule_service_orders_id,
            schedule_service_orders_code: item.schedule_service_orders_code,
            schedule_service_orders_uuid: item.schedule_service_orders_uuid,
            equipment_id: item.equipment_id,
            total_services: item.total_services,
            expected_volume: item.expected_volume,
            total_volume: item.total_volume,
            total_cycle: item.total_cycle,
            schedule_service_status: item.schedule_service_status,
            contract_proposal_code: item.contract_proposal_code,
            construction_name: item.construction_name,
            expected_start_date: item.expected_start_date,
            expected_end_date: item.expected_end_date,
            real_start_date: item.real_start_date,
            real_end_date: item.real_end_date,
            customer_name: item.customer_name,
            uuid: item.schedule_service_uuid,
            schedules_uuid: item.schedules_uuid,
          };
          let exist = false;
          for (
            let i = 0;
            i <
            list[`${item.equipment_code}_${item.service_description}`][
              formatDateString
            ].length;
            i++
          ) {
            if (
              areEqualObjects(
                current,
                list[`${item.equipment_code}_${item.service_description}`][
                  formatDateString
                ][i]
              )
            ) {
              exist = true;
            }
          }
          if (!exist) {
            list[`${item.equipment_code}_${item.service_description}`][
              formatDateString
            ].push(current);
          }
        }
      });
      this.list = list;
      return this.list;
    },
    headersHydrate() {
      let currentDate = new Date(this.filter.calendar_date + " 00:00:00");
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      let headers = [];

      [...Array(this.stepCalendarDays).keys()].forEach((indx) => {
        let newDate = new Date(currentDate.getTime());
        newDate.setDate(currentDate.getDate() + indx);

        headers.push({
          date: newDate,
          stringDate: this.formatDateString(newDate),
        });
      });
      return headers;
    },
    equipmentCategoriesHydrate() {
      return this.$_equipment_categories.map((equipment_category) => {
        return {
          id: equipment_category.id,
          name: equipment_category.category_name,
        };
      });
    },
    tabSelectItems() {
      return this.status.map((state) => {
        return {
          id: state.id,
          name: state.name,
          suffix: state.quantity,
          selected:
            this.filter.statusId === state.id ||
            (this.filter.statusId === undefined && state.id === null)
              ? true
              : false,
        };
      });
    },
  },
  methods: {
    updateCompanyPlantId() {
      const promise = new Promise((resolve, reject) => {
        this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
        this.setDefaultCompanyPlant();
        resolve([]);
      });
      return promise;
    },
    initStates() {
      return [
        { id: null, name: "Todos", quantity: 0 },
        { id: 0, name: "Liberado", quantity: 0 },
        { id: 1, name: "Concluido", quantity: 0 },
        { id: 3, name: "Cancelado", quantity: 0 },
        { id: 4, name: "Em andamento", quantity: 0 },
      ];
    },
    /**
     * @returns {void}
     */
    categoryChange() {
      this.categoriesTypes = [];
      let equipmentCategory = this.$_equipment_categories.find(
        (category) => category.id === this.searchBarFilter.equipment_category_id
      );
      if (!equipmentCategory) {
        return;
      }
      equipmentCategory.types.forEach((c) => {
        this.categoriesTypes.push({
          id: c.id,
          name: c.description,
        });
      });
    },
    /**
     * @param {Date} date
     * @returns {void}
     */
    handleNavigateSteps(date) {
      this.handleFilterDate(date);
    },
    /**
     * @param {Date} dateString
     * @returns {string}
     */
    formatDateString(dateString) {
      const date = new Date(dateString);
      const daysOfWeek = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
      const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];

      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${dayOfWeek} - ${day} ${month} ${year}`;
    },
    /**
     * @param {Date} dateString
     * @returns {void}
     */
    async handleFilterDate(date) {
      await this.updateCompanyPlantId();
      this.filter.search = this.searchBarFilter.custom_search_values;
      this.filter.calendar_date = moment(date).format("YYYY-MM-DD");
      this.filter.step_calendar_days = this.stepCalendarDays;
      this.filter.start = 0;
      this.filter.type = this.searchBarFilter.range.selected
      this.init();
    },
    /**
     * @param {object} status
     * @param {number|null} status.id
     * @param {string} status.name
     * @param {boolean} status.selected
     * @param {string} status.suffix
     * @returns {void}
     */
    onTabSelectItemClick(status) {
      this.handleFilterStatus(status.id);
    },
    /**
     * @param {number|null} statusId
     * @param {boolean} executeInit
     * @returns {void}
     */
    handleFilterStatus(statusId, executeInit = true) {
      this.filter.statusId = statusId;
      if (statusId === null) {
        delete this.filter.statusId;
      }
      if (executeInit) {
        this.init();
      }
    },
    /**
     * @returns {void}
     */
    othersFilters() {
      delete this.filter.statusId;
      delete this.filter.equipment_category_id;
      delete this.filter.equipment_type_id;
      delete this.filter.type;
      if (this.searchBarFilter.equipment_category_id) {
        this.filter.equipment_category_id =
          this.searchBarFilter.equipment_category_id;
      }
      if (this.searchBarFilter.equipment_type_id) {
        this.filter.equipment_type_id = this.searchBarFilter.equipment_type_id;
      }
      if (this.searchBarFilter.statusId) {
        this.filter.statusId = this.searchBarFilter.statusId;
      }
      if (this.searchBarFilter.range && this.searchBarFilter.range.selected) {
        this.filter.type = this.searchBarFilter.range.selected;
      }
    },
    /**
     * @returns {number|null}
     */
    getDefaultCompanyPlant() {
      const company_plant_id = localStorage.getItem("company_plant_id");
      return company_plant_id ? parseInt(company_plant_id) : null;
    },
    /**
     * @returns {void}
     */
    setDefaultCompanyPlant() {
      localStorage.setItem("company_plant_id", this.filter.company_plant_id);
    },
    /**
     * @returns {void}
     */
    init(loading = true) {
      this.loading = loading;
      this.loadingStatus = true;
      if (!this.searchBarFilter.company_plant_selected) {
        delete this.filter.company_plant_id;
      }
      if (!this.searchBarFilter.custom_search_values.length) {
        delete this.filter.search;
      }
      return this.$store
        .dispatch("scheduleServiceAgenda/fetch", this.filter)
        .then((response) => {
          this.loading = false;
          this.loadingStatus = false;
        })
        .catch(() => {
          this.loading = false;
          this.loadingStatus = false;
        });
    },
    /**
     * @returns {void}
     */
    getEquipmentTypes() {
      this.$store
        .dispatch("hardenedStateTestEquipment/fetchEquipmentTypeItems")
        .then((response) => {})
        .catch(() => {});
    },
    /**
     * @returns {void}
     */
    getEquipmentCategories() {
      this.$store.dispatch("equipmentCategory/fetchItems").then(() => {});
    },
    /**
     * @returns {void}
     */
    updatedService(params) {
      this.init();
    },
    /**
     * @returns {void}
     */
    updateStepCalendarDays() {
      const width = window.innerWidth;
      this.stepCalendarDays = 5;
      if (width >= 768 && width < 992) {
        this.stepCalendarDays = 2;
      } else if (width >= 576 && width < 768) {
        this.stepCalendarDays = 2;
      } else if (width < 576) {
        this.stepCalendarDays = 1;
      }
    },
    /**
     * @returns {void}
     */
    resetFilter() {
      this.filter.calendar_date = moment().format("YYYY-MM-DD");
      delete this.filter.search;
      delete this.filter.statusId;
      delete this.filter.equipment_category_id;
      delete this.filter.equipment_type_id;
    },
    /**
     * @param {boolean} execRequest
     * @returns {void}
     */
    resetDefaultSearchBarFilter(execRequest = false) {
      this.resetFilter();
      this.searchBarFilter = this.defaultSearchBarFilter();
      this.searchBarFilter.range.start = this.filter.calendar_date;
      this.searchBarFilter.range.end = this.filter.calendar_date;
      if (execRequest) {
        this.init();
      }
    },
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        statusId: null,
        equipment_category_id: null,
        equipment_type_id: null,
        company_plant: {
          items: this.$_company_plants.map((companyPlant) => {
            return {
              id: companyPlant.id,
              name: companyPlant.name,
              selected_name: companyPlant.name,
            };
          }),
          selected: this.filter.company_plant_id,
          start: this.filter.calendar_date,
          end: this.filter.calendar_date,
        },
        range: {
          items: [
            { id: 1, name: "Inicio previsto", selected_name: "Inicio prev." },
            { id: 2, name: "Fim previsto", selected_name: "Fim prev." },
          ],
          selected: this.filter.type,
          start: this.filter.calendar_date,
          end: this.filter.calendar_date,
        },
        custom_search_values: [],
      };
    },
    handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.loadingStatus
      ) {
        this.filter.start = this.filter.start + this.filter.limitByPage;
        window.removeEventListener("scroll", this.handleScroll);
        this.init(false).finally(() => {
          window.scrollTo({
            top:
              document.documentElement.scrollHeight - window.innerHeight - 125,
            behavior: "smooth",
          });
          window.addEventListener("scroll", this.handleScroll);
        });
      }
    },
  },
  beforeMount() {
    this.updateStepCalendarDays();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("visibilitychange", () => {
      if (!document.hidden && !this.loading) {
        this.init();
      }
    });
  },
  mounted() {
    this.filter.company_plant_id = this.getDefaultCompanyPlant();
    this.filter.step_calendar_days = this.stepCalendarDays;
    this.resetDefaultSearchBarFilter();
    this.init();
    this.getEquipmentTypes();
    this.getEquipmentCategories();
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden && !this.loading) {
        this.init();
      }
    });
    window.addEventListener("resize", () => {
      this.updateStepCalendarDays();
      this.filter.step_calendar_days = this.stepCalendarDays;
    });
  },
};
</script>
