<template>
  <div>
    <modal
      :show.sync="isOpen"
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
      size="lg"
    >
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/sap_vector.png"
            alt="sap-logo"
            width="49"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-2">
            {{ title }}
          </h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          type="link"
          size="sm"
          width="auto"
          @click.prevent="closeModal"
        />
      </template>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Card do contrato -->
            <div class="card py-2">
              <div class="container">
                <div class="row">
                  <!-- Título do card -->
                  <div class="col-12">
                    <div class="info-section">
                      <div class="info-section-title">
                        <AppIcon icon="briefcase" width="16px" />
                        <h4 class="new-default-black-font m-0 ml-3">
                          Contrato
                        </h4>
                      </div>
                      <hr class="info-section-divider" />
                    </div>
                  </div>
                  <!-- Conteúdo do card -->
                  <div class="col-12 px-5">
                    <h4 class="font-weight-normal new-default-black-font">
                      {{ formData.contract_proposal_code }}
                    </h4>
                    <h4 class="font-weight-normal new-default-black-font">
                      {{ formData.entity_name }}
                    </h4>
                    <h4 class="font-weight-normal new-default-black-font">
                      {{ formData.construction_name }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <!-- Card dos boletos -->
            <div class="card py-2">
              <div class="container">
                <div class="row">
                  <!-- Título do card -->
                  <div class="col-12">
                    <div class="info-section">
                      <div class="info-section-title">
                        <img src="/img/icons/barcode.png" alt="barcode" width="18"/>
                        <h4 class="new-default-black-font m-0 ml-3">Boletos</h4>
                      </div>
                      <hr class="info-section-divider" />
                    </div>
                  </div>
                  <!-- Conteúdo do card -->
                  <div class="col-12 pl-5">
                    <h4 class="font-weight-normal new-default-black-font">
                      FATURA {{ formData.id }} | NFS-E
                      {{ formData.nfse_number }}
                    </h4>
                    <h1 v-if="formData.installments.length" class="new-default-black-font">
                      <i style="font-style: normal" class="fs-12">R$</i>
                      {{ sumInstallmentAmount }}
                    </h1>
                    <h5 class="font-weight-normal new-default-gray-font">
                      {{ formData.payment_term_name }}
                    </h5>
                    <hr class="info-section-divider" />
                    <!-- Lista de boletos -->
                    <div v-if="formData.installments.length">
                      <div class="container mt-4 pt-2 mb-4 pb-2">
                        <div v-for="(installment, index) in formData.installments" :key="index">
                          <div class="row align-items-center mb-3">
                            <div class="col-auto m-0 p-0">
                              <div style="display: flex; align-items: center">
                                <!-- Numerador de cada boleto -->
                                <h5 class="font-weight-normal new-default-black-font m-0 mr-3" style="width: 10px">
                                  {{ index + 1 }}.
                                </h5>
                                <div class="card-bill-status" :class="installment.is_pending ? 'warning' : 'success'">
                                  <img :src="
                                      installment.is_pending 
                                        ? '/img/icons/icons8/ios/hourglass_warning.png'
                                        : '/img/icons/icons8/ios/receive-dollar_success.png'
                                    "
                                    :alt="installment.is_pending ? 'hourglass-warning' : 'receive-dollar'"
                                    width="14"
                                    class="mr-2"
                                  />
                                  <h5 class="m-0">
                                    {{ installment.is_pending ? "Pendente" : "Recebido"}}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <!-- Ícone do calendário e a data de cada boleto -->
                            <div class="col-auto">
                              <div class="card-bill-date">
                                <img
                                  src="/img/icons/icons8/ios/calendar_dark.png"
                                  alt="hourglass-warning"
                                  width="16"
                                  class="mr-2"
                                />
                                <h4 class="new-default-black-font m-0 font-weight-400">
                                  {{ date.make(installment.expire_at).format() }}
                                </h4>
                              </div>
                            </div>
                            <div class="col px-0">
                              <hr class="info-section-divider" style="margin: 0"/>
                            </div>
                            <!-- Valor de cada boleto -->
                            <div class="col-auto pr-0">
                              <h4 class="new-default-black-font m-0 font-weight-400">
                                {{ toMoney(installment.amount) }}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Caso não haja boletos -->
                    <div v-else>
                      <div class="container mt-4 pt-2 mb-4 pb-2">
                        <div class="row align-items-center mb-3">
                          <div class="col-12 card-bills-not-found">
                            <div style="display: inline-flex; align-items: center">
                              <img
                                src="/img/icons/icons8/ios/error--v1_danger.png"
                                alt="error"
                                width="20"
                                class="mr-2"
                              />
                              <h2 class="new-default-black-font m-0"> Boletos não encontrados</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Call-to-action buttons -->
                    <div class="cta-section mb-2">
                      <div class="container px-2">
                        <div class="row">
                          <!-- Botão para Acessar contrato (Sempre visível) -->
                          <div class="col px-2">
                            <router-link
                              :to="{
                                name: 'commercial.contract-proposal.invoices.view',
                                params: {
                                  contract_proposal_uuid: formData.contract_proposal_uuid,
                                  construction_name: formData.construction_name,
                                  customer_name: formData.customer_construction_customer_name,
                                },
                              }"
                            >
                              <AppButton
                                label="Acessar contrato"
                                icon="external-link-squared"
                                type="dark"
                                :isBlock="true"
                                :hasOutline="true"
                              />
                            </router-link>
                          </div>
                          <!-- Caso haja boletos -->
                          <div class="col px-2" v-if="formData.installments.length">
                            <AppButton
                              :isDisabled="!hasPendingBillets"
                              label="Download de Pendentes"
                              icon="printer"
                              type="primary"
                              :isBlock="true"
                              @click.prevent="downloadSapPendingBillets"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="close-button-section mb-3">
              <div class="container px-0">
                <div class="row">
                  <div class="col-12 text-right">
                    <AppButton
                      label="Fechar"
                      type="dark"
                      width="150px"
                      :hasOutline="true"
                      @click.prevent="closeModal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref, getCurrentInstance } from "vue";
import { AppButton, AppIcon } from "../../../../../components/AppGlobal";
import { date, progress, toDecimal, toMoney } from "../../../../../helpers";
import invoiceStore from "../../../../../modules/financial/invoice/store/invoiceStore";
import {
  initInvoiceGetSapBankBilletsType,
  InvoiceGetSapBankBilletsType,
} from "../../../../../modules/financial/invoice/types";
import JSZip from "jszip";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Boletos SAP";
/** @type {InvoiceGetSapBankBilletsType} */
const formData = reactive(initInvoiceGetSapBankBilletsType());
const store = getCurrentInstance().proxy.$store;
//#endregion

//#region Computeds
/** Total das parcelas */
const sumInstallmentAmount = computed(() => {
  return toDecimal(formData.installments.reduce((sum, installment) => sum + installment.amount, 0));
});

/** Tem boletos pendentes? */
const hasPendingBillets = computed(() =>
  formData.installments.some((installment) => installment.is_pending === true)
);
//#endregion

//#region Methods
/**
 * Abrir modal com ID da fatura
 * @param {number} invoiceId - ID da fatura
 */
async function openModal(invoiceId) {
  await setFormData(invoiceId);
  isOpen.value = !!formData;
}

/**
 * Localizar registro por id e setar dados no formulário
 * @param {number} invoiceId - ID da fatura
 */
async function setFormData(invoiceId) {
  let loader = progress.showLoader();
  progress.start();
  try {
    const found = await invoiceStore.actions.findSapBankBillets(invoiceId);
    Object.assign(formData, found);
  } finally {
    loader.hide();
    progress.finish();
  }
}

function closeModal() {
  isOpen.value = false;
}

/** Download de boletos em formato PDF */
function downloadSapPendingBillets() {
  const zip = new JSZip();
  
  // Filtra os boletos que estão pendentes
  const pendingInstallments = formData.installments.filter((installment) => installment.is_pending === true);
  
  // Adiciona os PDFs ao arquivo ZIP
  pendingInstallments.forEach((installment, index) => {
    const fileName = `boleto_${index + 1}.pdf`;
    const byteCharacters = atob(installment.base64);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    zip.file(fileName, byteArray);
  });
  
  // Gera o arquivo zip e faz o download
  zip.generateAsync({ type: "blob" }).then(function (content) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    const timestamp = Date.now();
    link.download = `boletos_${formData.contract_proposal_code}_pdfs_${timestamp}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} SapBankBilletsModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
.card {
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.info-section-title {
  display: flex;
  align-items: center;
}
.info-section-divider {
  background-color: #fff;
  margin-top: 8px;
  margin-bottom: 8px;
}
.card-bill-status {
  display: flex;
  align-items: center;
  width: 109px;
}
/** Customiza o background para TAGS com as duas classes ao mesmo tempo */
.card-bill-status.warning {
  background: #fef9f2;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
}
.card-bill-status.warning h5 {
  color: #f2b532;
}
/** Customiza o background para TAGS com as duas classes ao mesmo tempo */
.card-bill-status.success {
  background: #f2f7f3;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
}
.card-bill-status.success h5 {
  color: #149e57;
}
/** card transparente para usar data */
.card-bill-date {
  display: flex;
  align-items: center;
}
.card-bills-not-found {
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  background: #fcf3f3;
  text-align: center;
}
</style>
