import AdjustmentLevelResponsibilityListPage from "../views/list/AdjustmentLevelResponsibilityListPage.vue";

export default [
  {
    path: "/technology/adjustment/level-responsibilities",
    name: "technology.adjustment.level-responsibility.index.view",
    component: AdjustmentLevelResponsibilityListPage,
    meta: {
      title: "Tecnologia",
      description: "Ajustes - Nível de Aplicação",
      icon: "fa fa-home",
      permission: "puzl_adjust.level-responsibility-permissions.index.view",
    },
  },
];
