<template>
  <modal
    :show.sync="isOpen"
    class="bank-account-edit"
    size="lg"
    v-if="!$_bank_account_is_finding"
  >
    <template #header>
      <AppIcon icon="create" color="warning" width="21" />
      <h5 class="modal-title">Editar Conta</h5>
    </template>

    <BankAccountForm
      :formData="formData"
      @onCancelClick="closeModal"
      @onSubmitClick="handleSubmitForm"
    />
  </modal>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import bankAccountStore from "../../store/bankAccountStore";
import { BankAccountType, initBankAccountType } from "../../types";
import { AppIcon } from "../../../../../components/AppGlobal";
import BankAccountForm from "../shared/BankAccountForm.vue";
import { dialogs, progress } from "../../../../../helpers";
import companyStore from "../../../company/store/companyStore";
import companyPlantIssuerStore from "../../../company-plant-issuer/store/companyPlantIssuerStore";

//#region Data
const isOpen = ref(false);
/** @type {BankAccountType} */
const formData = reactive(initBankAccountType());
//#endregion

//#region Computeds
/** Store Getters */
const $_bank_account_is_finding = computed(() =>
  bankAccountStore.getters.getIsFinding()
);
//#endregion

//#region Methods
/** Abrir modal */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
  bankAccountStore.actions.fetchBanks();
  companyStore.actions.getListOptions();
  companyPlantIssuerStore.actions.getListOptions();
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
async function setFormData(id) {
  progress.start();
  const loader = progress.showLoader();
  const bankAccount = await bankAccountStore.actions.find(id);
  Object.assign(formData, { ...bankAccount });

  progress.finish();
  loader.hide();
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  bankAccountStore.actions
    .update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} BankAccountEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";
@import './assets/bankAccountEditModal.scss';
</style>
