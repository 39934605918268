import { AppIconVariants } from "../AppIcon";
import ButtonTypeEnum from "./enums/ButtonTypeEnum";
import ButtonSizeEnum from "./enums/ButtonSizeEnum";
import ButtonRoundedEnum from "./enums/ButtonRoundedEnum";
import ButtonBorderTypeEnum from "./enums/ButtonBorderTypeEnum";
export const AppButtonVariants = Object.freeze({
  defaultVariants: Object.freeze({
    label: "",
    icon: undefined,
    isBlock: false,
    rounded: ButtonRoundedEnum.keys.DEFAULT,
    size: ButtonSizeEnum.keys.MD,
    width: '150px',
    borderType: ButtonBorderTypeEnum.keys.SOLID,
    type: ButtonTypeEnum.keys.PRIMARY,
    hasOutline: false,
    hasGrowEffect: true,
    isLoading: false,
    isDisabled: false,
  }),
  variants: Object.freeze({
    icon: AppIconVariants.variants.svg,
    rounded: ButtonRoundedEnum,
    size: ButtonSizeEnum,
    borderType: ButtonBorderTypeEnum,
    type: ButtonTypeEnum,
    typeSchema: Object.freeze({
      primary: Object.freeze({
        color: "#1A70B7",
        backgroundColor: "#1A70B7",
        borderColor: "#1A70B7",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#1A70B7",
        borderOutlineHoverColor: "#1A70B7",
      }),
      success: Object.freeze({
        color: "#149E57",
        backgroundColor: "#149E57",
        borderColor: "#149E57",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#149E57",
        borderOutlineHoverColor: "#149E57",
      }),
      danger: Object.freeze({
        color: "#DB4539",
        backgroundColor: "#DB4539",
        borderColor: "#DB4539",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#DB4539",
        borderOutlineHoverColor: "#DB4539",
      }),
      warning: Object.freeze({
        color: "#F2B532",
        backgroundColor: "#F2B532",
        borderColor: "#F2B532",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#F2B532",
        borderOutlineHoverColor: "#F2B532",
      }),
      muted: Object.freeze({
        color: "#606062",
        backgroundColor: "#606062",
        borderColor: "#606062",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#606062",
        borderOutlineHoverColor: "#606062",
      }),
      dark: Object.freeze({
        color: "#2B2D32",
        backgroundColor: "#2B2D32",
        borderColor: "#2B2D32",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#2B2D32",
        borderOutlineHoverColor: "#2B2D32",
      }),
      gray: Object.freeze({
        color: "#CFCFCF",
        backgroundColor: "#CFCFCF",
        borderColor: "#CFCFCF",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#CFCFCF",
        backgroundOutlineHoverColor: "#CFCFCF",
        borderOutlineHoverColor: "#CFCFCF",
      }),
      link: Object.freeze({
        color: "#FFFFFF",
        backgroundColor: "#FFFFFF",
        borderColor: "#FFFFFF",
        backgroundOutlineColor: "#FFFFFF",
        borderOutlineColor: "#FFFFFF",
        backgroundOutlineHoverColor: "#FFFFFF",
        borderOutlineHoverColor: "#FFFFFF",
      }),
    }),
  }),
});
