<template>
  <div>
    <div class="container-fluid">
      <div class="col-lg-12 text-left ml-n2 mb-n3">
        <a
          v-if="$_stocks.length && !loadingSkeleton"
          href="#"
          class="ml-1"
          @click.prevent="downloadStockExcel()"
        >
          <img src="/img/icons/excel.png" width="28px" class="mt-n2 mb-1" />
        </a>
      </div>
      <MultiFilter
        :showSlotDates="false"
        :showSlotBookmark="false" 
        :showSlotStatus="false" 
        @fetch="init" 
        @handleFilterDate="handleFilterDate"
        @clearFilter="clearFilter"
      >
        <template slot="data">
          <div class="row pr-3 mt-1 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label">
                Categoria do MCC
              </label>
              <div class="mb-2 mt-n1">
                <base-input input-classes="form-control">
                 <div class="form-control-label">
                    <PuzlSelect 
                      v-model="filter.cmc_category_id"
                      :items="$_cmcCategories"
                      @input="init({})"
                      @change="getCmcProductByCmcCategory()"
                      :loading="loadingCmcCategory"
                      :disabled="loadingCmcCategory" />
                  </div>
                </base-input>
              </div>
            </div>
            <div class="col-12 text-left">
              <label class="form-control-label">
                Produto do MCC
              </label>
              <div class="mb-2 mt-n1">
                <base-input input-classes="form-control">
                  <div class="form-control-label">
                    <PuzlSelect 
                      v-model="filter.cmc_product_id"
                      :items="$_cmcProducts"
                      label="product_name"
                      @input="init({})"
                      :loading="loadingCmcProduct"
                      :disabled="disabledCmcProduct" />
                  </div>
                </base-input>
              </div>
            </div>
            <div class="col-12 text-left">
              <label class="form-control-label">
                Fornecedor
              </label>
              <div class="mb-4 mt-n1">
                <base-input input-classes="form-control">
                 <textarea 
                  type="text" 
                  rows="2" 
                  class="form-control form-control-sm" 
                  v-model="filter.supplier" />
                </base-input>
              </div>
            </div>
          </div>
        </template>
      </MultiFilter>
      <!-- Tabela -->
      <div class="row card-row mt-0 m-0">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center">Ações</th>
                        <th class="text-center">Estoque final</th>
                        <th class="text-center">Central</th>
                        <th class="text-center">Mcc</th>
                        <th class="text-center">Categoria Mcc</th>
                        <th class="text-center">Preço médio</th>
                        <th class="text-center">Estoque final (Kg)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingSkeleton">
                        <td colspan="12">
                          <SkeletonPuzl type="button" />
                        </td>
                      </tr>
                      <tr v-for="(stock, index) in $_stocks" :key="index">
                        <td>
                          <div class="row d-flex justify-content-center">
                            <div v-if="stock.last_stock_location" class="col-2 p-0">
                              <a href="#" @click.prevent="handleDelete(stock.id)">
                                <img src="/img/icons/delete.png" width="21px" height="21px">
                              </a>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <span class="px-2">
                            {{ stock.date_stock | parseDate('DD/MM/YYYY') }}
                          </span>
                          <el-popover
                            class="ml-0"
                            placement="bottom"
                            trigger="click"
                          >
                            <div class="mb-n2">
                              <span>
                                <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                                  Criação
                                </span>
                                <hr class="hr-popover-user">
                                <span v-if="stock.created_by_user_name "style="font-weight: 400;font-size: 11px;">
                                  {{ stock.created_by_user_name }}
                                  <br>
                                </span>
                                <span style="font-weight: 400;font-size: 11px;">
                                  {{ stock.created_at | parseDate('DD MMM YYYY  HH:mm') }}
                                </span>
                              </span>
                            </div>
                            <base-button
                              slot="reference"
                              class="p-0 m-0 shadow-none"
                              outiline
                              size="sm"
                              type="secundary"
                            >
                              <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                            </base-button>
                          </el-popover>
                        </td>
                        <td class="text-center">
                          <span>
                            {{ stock.company_plant_name }}
                          </span>
                        </td>
                        <td class="text-left">
                          <span>
                            {{ stock.product_name }} 
                            <span class="mx-1">|</span> 
                            {{ stock.supplier_name }} 
                            ({{stock.origin_name }})
                          </span>
                        </td>
                        <td class="text-center">
                          <span>
                            {{ stock.cmc_category_name }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span>
                            {{ stock.average_price | currency() }} /Kg
                          </span>
                        </td>
                        <td class="text-center">
                          <span>
                            {{ Math.round(stock.final_stock_kg).toLocaleString('pt-BR') }} Kg
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <PuzlEmptyData v-show="$_stocks && !$_stocks.length && !loadingBySeller" class="mt-n1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from '@/components/InputDatePicker';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import { date } from "../../../../../helpers";

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    PuzlSelect,
    SkeletonPuzl,
  },
  name: "List",
  mixins: [cursorPaginate],
  data() {
    return {
      filter: {
        range: null,
        company_plants: [],
        cmc_category_id: null,
        cmc_product_id: null,
        supplier: '',
      },
      selectedCompanyPlants: [],
      selectAll: false,
      defaultRange: {
        start: date.make().format(date.FORMAT.ISO_8601), // Data de hoje
        end: date.make().format(date.FORMAT.ISO_8601)
      },
      range: {
        start: null,
        end: null,
      },
      loadingSkeleton: false,
      loadingCompanyPlants: true,
      loadingCmcCategory: true,
      loadingCmcProduct: false,
      disabledCmcProduct: true,
      dateStock: '',
      finalStockKg: '',
    }
  },
  computed: {
    ...mapGetters({
      $_stocks: "stock/fetch",
      $_plants: "plant/activeItems",
      $_cmcCategories: "category/fetch",
      $_cmcProducts: "cmcProduct/showByCategory",
    }),
  },
  mounted() {
    this.$store.dispatch('plant/fetchItems').then(() => {
      this.loadingCompanyPlants = false;
    });
    this.$store.dispatch('category/getCmcCategories').then(() => {
      this.loadingCmcCategory = false;
    });
    this.fetchItemsPaginate();
  },
  methods: {
    /**
     * @param {number} previousStockId
     */
    updatedPreviousStock(previousStockId) {
      // Busca o registro anterior da mesma localização e seta false, para que ele não seja mais o último.
      const foundPreviousStock = this.$_stocks.find((item) => item.id === previousStockId);
      foundPreviousStock.last_stock_location = false;
    },
    fetchItemsPaginate() {
      this.range = this.defaultRange;
      this.init({});
    },
    init(filter = null) {
      this.$Progress.start();
      this.filter.range = this.range;
      this.filter.company_plants = this.selectedCompanyPlants;
      // Caso o usuário retire o item da categoria do filtro.
      if (!this.filter.cmc_category_id) {
        this.filter.cmc_product_id = null;
      }
      this.startCursor(filter);
      this.$store
        .dispatch("stock/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(async response => {
          this.resolveCursor(response)
          this.$Progress.finish()
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    textClass(value) {
      if (value === 0) {
        return 'text-default';
      }
      return value > 0 ? 'text-success' : 'text-danger';
    },
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.init({});
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('stock/destroy', id)
            .then((response) => {
              // Se existir outro stock na mesma localização, então agora ele é considerado o último.
              if (response.data) {
                let foundStock = this.$_stocks.find((item) => item.id === response.data.id);
                foundStock.last_stock_location = true;
              }
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
            .catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    handleFilterDate(filter, default_filter = 1) {
      this.filter_by_default = default_filter;
      this.range = filter;
      this.init({});
    },
    getCmcProductByCmcCategory() {
      this.loadingCmcProduct = true;
      this.disabledCmcProduct = true;
      this.$store.dispatch('cmcProduct/fetchByCategory', this.filter.cmc_category_id).then(() => {
        this.loadingCmcProduct = false;
        this.disabledCmcProduct = false;
      });
    },
    clearFilter() {
      this.filter = {
        range: null,
        company_plants: [],
        cmc_category_id: null,
        cmc_product_id: null,
        supplier: '',
      };
    },
    downloadStockExcel() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("stock/getStockReportExcel", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de medições de estoque.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
  }
}
</script>

<style scoped>
.horizontal-line {
  border-bottom: 1px solid #1a70b7;
}

.hr-popover-user {
  margin: 7px 0;
  width: 118%;
  margin-left: -11px;
}
</style>
