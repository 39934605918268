<template>
  <div class="row card-wrapper">
    <div
      class="col-lg-4"
      v-for="(bank_account, index) in $_bank_account_listed.items"
      :key="index"
    >
      <div class="card">
        <div class="card-header d-flex aligh-items-center">
          <AppSwitch
            v-model="bank_account.status"
            :asyncClick="() => updateStatus(bank_account)"
            :style="{ marginRight: '12px' }"
          />
          <el-popover
            trigger="click"
            placement="bottom"
            class="mr-2 h-100"
            v-if="
              bank_account?.created_by_user_name ||
              bank_account?.updated_by_user_name
            "
          >
            <div class="table-text d-flex flex-column">
              <span class="popover-title"> Última Atualização </span>
              <hr class="popover-title-border full" />
              <span
                v-if="bank_account?.updated_by_user_name"
                class="popover-text"
              >
                {{ bank_account?.updated_by_user_name }} <br />
                {{ bank_account.updated_at | parseDate("DD MMM YYYY - HH:mm") }}
              </span>
              <span v-else class="popover-text">
                {{ bank_account?.updated_by_user_name }}<br />
                {{ bank_account.created_at | parseDate("DD MMM YYYY - HH:mm") }}
              </span>
            </div>
            <base-button
              outline
              slot="reference"
              size="sm"
              type="secundary"
              class="p-0 m-0 shadow-none text-indigo"
            >
              <AppIcon icon="male-user" width="25" />
            </base-button>
          </el-popover>
          <AppIcon
            icon="barcode"
            width="24"
            v-if="bank_account.has_bank_account_billet_params_integration"
            class="mr-2"
          />
          <AppIcon
            icon="pix"
            width="24"
            v-if="bank_account.pix_status === 1"
            color="success"
            class="mr-2"
          />

          <base-dropdown menuOnRight>
            <div
              slot="title-container"
              class="dropdown-toggle rounded m-0 ml-auto"
            >
              <AppIcon icon="settings" color="primary" width="30" />
            </div>
            <a
              class="dropdown-item"
              @click.prevent="onEditItemClick(bank_account.id)"
            >
              <AppIcon icon="create" color="warning" width="20" class="mr-2" />
              Editar
            </a>
            <hr class="my-1" />
            <a
              class="dropdown-item"
              @click.prevent="onUpdateItemSettings(bank_account.id)"
            >
              <AppIcon icon="automation" width="20" class="mr-2" />
              Configurações
            </a>
            <hr class="my-1" />
            <a
              class="dropdown-item"
              @click.prevent="onUpdateItemBillet(bank_account.id)"
            >
              <AppIcon icon="barcode" width="20" class="mr-2" />
              BOLETO
            </a>
            <hr class="my-1" />
            <a
              class="dropdown-item"
              @click.prevent="onUpdateItemPix(bank_account.id)"
            >
              <AppIcon icon="pix" color="success" width="20" class="mr-2" />
              PIX
            </a>
            <hr class="my-1" />
            <a
              class="dropdown-item"
              @click.prevent="onRemoveItemClick(bank_account.id)"
            >
              <AppIcon
                icon="trash-can"
                color="danger"
                width="20"
                class="mr-2"
              />
              EXCLUIR
            </a>
          </base-dropdown>
        </div>
        <div class="card-body">
          <img
            style="width: 65px; height: 65px"
            :src="'/img/icons/banks/' + bank_account.bank_code + '.png'"
          />
          <div class="d-flex flex-column">
            <h5>{{ bank_account.name }}</h5>

            <h6>
              Agência: {{ bank_account.number_without_digit_agency
              }}<span v-if="bank_account.digit_agency"
                >-{{ bank_account.digit_agency }}</span
              >
            </h6>
            <h6>
              Conta: {{ bank_account.number_without_digit_account
              }}<span v-if="bank_account.digit_account"
                >-{{ bank_account.digit_account }}</span
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits } from "vue";
import bankAccountStore from "../../store/bankAccountStore";
import { BankAccountListType } from "../../types";
import { AppIcon, AppSwitch } from "../../../../../components/AppGlobal";
import { dialogs } from "../../../../../helpers";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
  "onUpdateItemSettings",
  "onUpdateItemBillet",
  "onUpdateItemPix",
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_bank_account_listed = computed(() =>
  bankAccountStore.getters.getListed()
);
const $_bank_account_is_listing = computed(() =>
  bankAccountStore.getters.getIsListing()
);

//#region Methods
/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Abrir modal configurações
 * @param {number} id
 */
function onUpdateItemSettings(id) {
  emit("onUpdateItemSettings", id);
}

/**
 * Abrir modal boleto
 * @param {number} id
 */
function onUpdateItemBillet(id) {
  emit("onUpdateItemBillet", id);
}

/**
 * Abrir modal pix
 * @param {number} id
 */
function onUpdateItemPix(id) {
  emit("onUpdateItemPix", id);
}

/**
 * Atualizar Status por ID
 * @param {BankAccountListType} bank_account
 */
async function updateStatus(bank_account) {
  await bankAccountStore.actions.updateStatus(
    +!bank_account.status,
    bank_account.id
  );
}
//#endregion
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.dropdown-toggle::after {
  background-image: url("/img/icons/more-than.png");
}

.base-button:hover .invert-on-hover,
.btn:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.header-card {
  padding-bottom: 25px;
  margin-left: auto;
  margin-top: -10px;
}

/* CARDS */
.card-wrapper .card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid $muted-light;
  margin-bottom: 1rem;
  // min-height: 180px;

  .dropdown {
    margin-left: auto;
  }

  .badge {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .card-header {
    border-bottom: none !important;
    box-shadow: none !important;
    padding-bottom: 0;
    border-radius: 16px !important;
  }

  .card-body {
    padding-top: 16px;
    display: flex;
    gap: 16px;
    padding-bottom: 20px;

    & * {
      line-height: 1;
    }

    h6 {
      color: $dark;
      font-weight: 400 !important;
      font-size: 14px;
      margin-bottom: 8px;
      span {
        color: inherit;
        font-weight: inherit;
        font-size: inherit !important;
        display: inline-block;
        margin-bottom: 0;
      }
    }

    h5 {
      color: $dark;
      font-weight: 500 !important;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}

/* ACTION BUTTON */
.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/** Popovers */
.popover-title {
  font-family: Fredoka;
  font-size: 16px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  position: relative;
}

.popover-text {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 300;
}
.popover-title-border {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 100%;
  position: relative;
  border-color: #e8e8e8 !important;

  &.full {
    border-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      top: 50%;
      transform: translate(-12px, 50%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translate(12px, -50%);
      background-color: #e8e8e8;
    }
  }
}

/** Popovers */
.popover-title {
  font-family: Fredoka;
  font-size: 16px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  position: relative;
}

.popover-text {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 300;
}
.popover-title-border {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 100%;
  position: relative;
  border-color: #e8e8e8 !important;

  &.full {
    border-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      top: 50%;
      transform: translate(-12px, 50%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translate(12px, -50%);
      background-color: #e8e8e8;
    }
  }
}
</style>
