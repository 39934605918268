const keys = Object.freeze({
  RECEIVED: 1,
  CANCELED: 3,
  APPROVED: 8,
  REVERSED: 10,
});

const getTitle = (value) => {
  switch (value) {
    case keys.RECEIVED:
      return "Recebido";
    case keys.CANCELED:
      return "Cancelado";
    case keys.APPROVED:
      return "Aprovado";
    case keys.REVERSED:
      return "Estornado";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
