import * as types from "./mutation_types";
import {destroy, update,cursorPaginate,setUrlWithNextPage} from "@/store/baseStore";
import { createAxios } from "@/plugins/axios"
import {SET_CONTRACT_PROPOSALS} from "./mutation_types";
const endPoint = "/operational/schedule/schedule-travel/+microservice";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    penultimate_travel: {},
    mcc: [],
    mix_codes: [],
    contract_proposals: [],
    adjustment: {}
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    mcc: state => state.mcc,
    adjustment: state => state.adjustment,
    getMixCodes: state => state.mix_codes,
    getContractProposals: state => state.contract_proposals,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW_MCC](state, payload) {
      state.mcc = payload;
    },
    [types.SET_MIX_CODES](state, payload) {
      state.mix_codes = payload;
    },
    [types.SET_CONTRACT_PROPOSALS](state, payload) {
      state.contract_proposals = payload;
    },
    [types.SHOW_ADJUSTMENT](state, payload) {
      state.adjustment = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SET_PENULTIMATE](state, payload) {
      state.penultimate_travel = payload;
    },
  },
  actions: {
    fetchItems({commit,state}, payload) {
      return createAxios().get(setUrlWithNextPage(endPoint+ "schedule/" + payload.uuid, payload),
        {
          params: payload,
        })
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: payload.next_page})
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fetchWithFilter({commit}, payload) {
      return createAxios()
        .post(endPoint + "gantt", payload)
        .then(({data}) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    updateCycle({commit}, payload) {
      return createAxios()
        .put(endPoint + "cycle/" + payload.travel.uuid, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getResume({commit}, payload) {
      return createAxios()
        .get(`${endPoint}resume/${payload}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    dragUpdate({commit}, payload) {
      return createAxios()
        .put(endPoint + "drag/" + payload.travel_uuid, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    update({commit}, payload) {
      return createAxios()
        .put(endPoint + payload.uuid, payload)
        .then(({data}) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    add({commit, state}, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    show({commit, dispatch}, payload) {
      if (! payload.uuid) {
        return;
      }
      return createAxios()
        .get(endPoint + payload.uuid, {params: {code: payload.code}})
        .then(({data}) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    showLight({commit, dispatch}, payload) {
      if (! payload.uuid) {
        return;
      }
      return createAxios()
        .get(endPoint + payload.uuid, {params: {code: 3}})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getMCC({commit, dispatch}, params) {
      return createAxios()
        .patch(`/operational/schedule/schedule-travel/set/mcc/${params.uuid}+microservice`, {filter: params}
        )
        .then(({data}) => {
          commit(types.SHOW_MCC, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getAdjustments({commit, dispatch}, params) {
      return createAxios()
        .get(
          `/operational/schedule/schedule-travel/adjustments/${params.mix_design_mixe_id}+microservice`,
          {params: params}
        )
        .then(({data}) => {
          commit(types.SHOW_ADJUSTMENT, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    putSetTimer({commit, dispatch}, params) {
      return createAxios()
        .put(
          `/operational/schedule/schedule-travel/${params.uuid}/timer+microservice`,
          params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    putSetManualDosage({commit, dispatch}, params) {
      return createAxios()
        .put(`/operational/schedule/schedule-travel/manual-dosage-mcc+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postStoreAutomations({commit, dispatch}, params) {
      return createAxios()
        .post(`/operational/schedule/schedule-travel/set/automations+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    putFinishTimer({commit, dispatch}, params) {
      return createAxios()
        .put(
          `/operational/schedule/schedule-travel/${params.uuid}/finish-timer+microservice`,
          params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postCompleteCharging({commit, dispatch}, params) {
      return createAxios()
        .post(
          `/operational/schedule/schedule-travel/${params.uuid}/complete+microservice`,
          params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postCompleteManualCharging({commit, dispatch}, params) {
      return createAxios()
        .post(
          `/operational/schedule/schedule-travel/${params.uuid}/complete-manual+microservice`,
          params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postSetMCC({commit, dispatch}, params) {
      return createAxios()
        .post(
          `/operational/schedule/schedule-travel/mcc/${params.schedule_travel_uuid}+microservice`,
          params.params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    approveCharging({commit, dispatch}, params) {
      return createAxios()
        .post(
          `/operational/schedule/schedule-travel/approve-charging/${params.schedule_travel_uuid}+microservice`,
          params.params
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios()
        .delete(endPoint + uuid)
        .then(({data}) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    cancel({commit, dispatch}, params) {
      return createAxios()
        .put(endPoint + params.uuid + '/cancel', params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    deleteClearMcc({commit, dispatch}, params) {
      return createAxios()
        .delete(
          `/operational/schedule/schedule-travel/mcc/${params.uuid}+microservice`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    download({commit, dispatch}, params) {
      return createAxios()
        .get(
          `${endPoint}download/${params.type}`,{
            responseType: 'blob',
            timeout: 30000,
            params: params
          })
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fillCycle({commit}, payload) {
      return createAxios()
        .post(`${endPoint}cycle/fill/${payload.id}`, payload)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    generateNumber({commit}, payload) {
      return createAxios()
        .post(`${endPoint}generate-number/${payload.id}`, payload)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getLastArrivalTimeByScheduleAndSendPlant({}, payload) {
      return createAxios()
        .get(`${endPoint}arrival-time/schedule/${payload.schedule_uuid}/send-plant/${payload.send_plant_uuid}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    searchAutomation({}, params) {
      return createAxios()
        .get(`${endPoint}automation/${params.uuid}`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getMixCode({commit}, params) {
      return createAxios()
        .get(`${endPoint}configuration/mix-codes`, {params: params})
        .then(({data}) => {
          commit(types.SET_MIX_CODES, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getContractProposals({commit}, params) {
      return createAxios()
        .get(`${endPoint}configuration/contract-proposals`, {params: params})
        .then(({data}) => {
          commit(types.SET_CONTRACT_PROPOSALS, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    updateVersion({}, params) {
      return createAxios()
        .post(`${endPoint}formulation/update-version/${params.id}`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    updateService({}, params) {
      return createAxios()
        .post(`${endPoint}${params.schedule_travel_id}/update-service/`, {schedule_service_id: params.schedule_service_id})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    setToUnrealized({}, payload) {
      return createAxios()
        .get(`${endPoint}set-to-unrealized/${payload}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postSendNfe(_, schedule_travel_id) {
      return createAxios()
        .post(`${endPoint}send-nfe/${schedule_travel_id}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    putUpdateIndividualCut(_, params) {
      return createAxios()
        .put(`${endPoint}update-individual-cut/${params.mcc_id}`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getXmlByTravel({}, schedule_travel_id) {
      return createAxios()
        .get(`${endPoint}xml/${schedule_travel_id}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getInfo({}, schedule_travel_id) {
      return createAxios()
        .get(`${endPoint}info/${schedule_travel_id}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postSetComment(_, params) {
      return createAxios()
        .post(`${endPoint}comments/${params.id}`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postSetObservation(_, params) {
      return createAxios()
        .post(`${endPoint}observation/${params.id}`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getBinderConsumption(_, params) {
      return createAxios()
        .get(`${endPoint}binder/consumption/${params.schedule_travel_uuid}`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getPenultimateTravelByEquipment({commit}, equipment_uuid) {
      return createAxios()
        .get(`${endPoint}penultimate-travel/equipment/${equipment_uuid}`, )
        .then(({data}) => {
          commit(types.SET_PENULTIMATE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    validMoldingCodeBeforeCompleteCharging(_, uuid) {
      return createAxios()
        .get(`${endPoint}valid/require-molding-code/${uuid}`, )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
  },
};
