import store from "../../../../shared/libraries/store";
import {
  initAdjustmentFinalSlumpListPagerType,
  AdjustmentFinalSlumpListFilterType,
  AdjustmentFinalSlumpListPagerType,
  AdjustmentFinalSlumpType,
  AdjustmentFinalSlumpUpdateDosageType,
  AdjustmentFinalSlumpListType,
} from "../types";
import {
  createSource,
  findSource,
  listSources,
  removeSource,
  updateSource,
} from "../../../../shared/store";
import { adjustmentFinalSlumpFormatEmptyAgentService } from "../services";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";

/** URLS */
const urls = Object.freeze({
  BASE: "/technology/adjustments-final-slump",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "ADJUSTMENT_FINAL_SLUMP_ITEM",
  LISTED: "ADJUSTMENT_FINAL_SLUMP_LISTED",
  IS_LISTING: "ADJUSTMENT_FINAL_SLUMP_IS_LISTING",
  IS_FINDING: "ADJUSTMENT_FINAL_SLUMP_IS_FINDING",
  IS_SAVING: "ADJUSTMENT_FINAL_SLUMP_IS_SAVING",
  IS_REMOVING: "ADJUSTMENT_FINAL_SLUMP_IS_REMOVING",
  PAGE_STATES: "ADJUSTMENT_FINAL_SLUMP_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?AdjustmentFinalSlumpType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {AdjustmentFinalSlumpListPagerType} */
  getListed: () =>
    store.getState(keys.LISTED) ??
    initAdjustmentFinalSlumpListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: AdjustmentFinalSlumpListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {AdjustmentFinalSlumpType} item
   * @returns {AdjustmentFinalSlumpType}
   */
  setItem: (item) => {
    if (item.agent === null) {
      item.agent =
        adjustmentFinalSlumpFormatEmptyAgentService.execute();
    }
    return store.commit(keys.ITEM, item);
  },

  /**
   * @param {AdjustmentFinalSlumpListPagerType} listed
   * @returns {AdjustmentFinalSlumpListPagerType}
   */
  setListed: (listed) => {
    listed.items.forEach((item) => {
      if (item.agent === null) {
        item.agent =
          adjustmentFinalSlumpFormatEmptyAgentService.execute();
      }
    });
    store.commit(keys.LISTED, listed);
  },

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {AdjustmentFinalSlumpType} created
   * @returns {AdjustmentFinalSlumpType}
   */
  setNewItemInList: (created) => {
    created.agent =
      adjustmentFinalSlumpFormatEmptyAgentService.execute();

    return store.commitNewItemToList(keys, created);
  },
  /**
   * @param {AdjustmentFinalSlumpListPagerType} listed
   * @returns {AdjustmentFinalSlumpListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {AdjustmentFinalSlumpType} updated
   * @returns {AdjustmentFinalSlumpType}
   */
  setUpdatedItem: (updated) => {
    if(updated.agent === null) {
      updated.agent = adjustmentFinalSlumpFormatEmptyAgentService.execute();
    }
    return store.commitUpdatedItem(keys, updated);
  },

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: AdjustmentFinalSlumpListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {AdjustmentFinalSlumpType} data
   * @returns {Promise<AdjustmentFinalSlumpType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?AdjustmentFinalSlumpType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {AdjustmentFinalSlumpListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?AdjustmentFinalSlumpListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) =>
    await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {AdjustmentFinalSlumpType} data
   * @param {number} id
   * @returns {Promise<AdjustmentFinalSlumpType>}
   */
  update: async (data, id) =>
    await updateSource(data, id, mutations, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar valores de dosagem por ID
   *
   * @param {AdjustmentFinalSlumpUpdateDosageType} dosages
   * @param {number} id
   * @returns {Promise<?AdjustmentFinalSlumpType>}
   */
  updateDosages: async (dosages, id) => {
    try {
      mutations.setIsSaving(true);

      const res = await httpClient.put(
        urls.BASE + "/update-dosages/" + id,
        dosages
      );
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK:
          if (res.data.data.agent === null) {
            res.data.data.agent =
              adjustmentFinalSlumpFormatEmptyAgentService.execute();
          }

          return mutations.setUpdatedItem(res.data.data);

        default:
          handleUnexpectedStatusCode(res);
      }
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Retorna lista das centrais vinculadas
   * ao nível de aplicação por ID
   *
   * @param {AdjustmentFinalSlumpListType} adjustment
   */
  getCompanyPlantsById: async (adjustment) => {
    try {
      mutations.setIsFinding(true);

      const res = await httpClient.get(
        urls.BASE + "/company-plants-by-id/" + adjustment.id
      );

      switch (res.status) {
        case HttpStatusEnum.HTTP_OK:
          adjustment.company_plants = res.data.data;
          return mutations.setUpdatedItem(adjustment);
        default:
          handleUnexpectedStatusCode(res);
      }
    } finally {
      mutations.setIsFinding(false);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};
