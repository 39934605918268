<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
        <h5 class="mb-0" slot="title">Dados de emissão</h5>
      </div>
      <div v-if="billet" class="row mt-2">
        <div class="col-md-12">
          <h5 class="mb-0">
                  <span class=""
                  >Cliente : <span class="font-weight-normal">{{ billet.entity_document }}</span>
                  </span>
          </h5>
          <h5 class="mb-0">
                  <span class=""
                  >Beneficiário : <span class="font-weight-normal">{{ billet.issuer_document }}</span>
                  </span>
          </h5>
          <h5 class="mb-0">
                  <span class=""
                  >Competência : <span class="font-weight-normal">{{
                      bill_receive.bill_receive.competence | parseDate('DD/MM/YYYY')
                    }}</span>
                  </span>
          </h5>
          <h5 v-if="bill_receive.bill_receive.bank_account" class="mb-0">
            <span class=""
            >Conta bancária : <span class="font-weight-normal">{{
                bill_receive.bill_receive.bank_account.name}}
                | {{ bill_receive.bill_receive.bank_account.number_without_digit_account }} -
            {{ bill_receive.bill_receive.bank_account.digit_account }}
            </span>
            </span>
          </h5>
        </div>
        <template v-if="bill_receive && bill_receive.installments">
          <div class="col-2"/>
          <div class="col-10 mt-2">
            <h5 class="mb-0 border-3 border-left border-primary">
                  <span class="ml-1 font-weight-normal"
                  >TOTAL = <span class="font-weight-bold">{{
                      bill_receive.bill_receive.total_value | currency()
                    }}</span>
                  </span>
            </h5>
            <!--            <h5 class="mt-1 mb-0 border-3 border-left border-danger">-->
            <!--                  <span class="ml-1 text-danger font-weight-normal"-->
            <!--                  >RETENÇÃO ISS = <span class="font-weight-bold">{{ bill_receive.bill_receive.iss_retain | currency() }}</span>-->
            <!--                  </span>-->
            <!--            </h5>-->
            <h5 class="mt-1 mb-0 border-3 border-left border-success">
                  <span class="ml-1 font-weight-normal"
                  >VALOR FINAL = <span class="font-weight-bold">{{
                      bill_receive.bill_receive.final_value | currency()
                    }}</span>
                  </span>
            </h5>
          </div>
          <div class="col-md-12 mt-2">
            <div class="mb-2  card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Documento</h5>
            </div>
            <div class="form-group row m-0 p-0 mb-1 limited-row">
              <label class="col-md-6 pb-1 pt-2  col-form-label form-control-label">
                Data:
              </label>
              <div class="col-md-6">
                <base-input input-group-classes="input-group-sm">
                  <el-date-picker
                    v-model="bill_receive.bill_receive.document_date"
                    size="mini"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1 limited-row">
              <label class="col-md-6 pb-1 pt-2  col-form-label form-control-label">
                Número:
              </label>
              <div class="col-md-6">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="bill_receive.bill_receive.document_number"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>
            <div class="mb-2 mt-2 card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Parcelas</h5>
            </div>
            <div v-for="item in bill_receive.installments" class="timeline timeline-one-side">
              <div class="timeline-block"><span class="timeline-step" :class="badgeColor(item)">
                <i :class="icon(item)"></i>
              </span>
                <div class="timeline-content"><small
                  class="text-muted font-weight-bold">({{ item.index }}/{{
                    bill_receive.bill_receive.total_index
                  }})</small><h4
                  class=" mb-0" style="font-family: Gilroy,sans-serif !important">{{
                    item.total_value | currency()
                  }}</h4>
                  <p style="margin-top: -0.5rem !important;"><small style="font-family: Gilroy,sans-serif !important">Vencimento:
                    {{ item.due_date | parseDate('DD/MM/YYYY') }}</small></p>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="col-2 mt-4">-->
          <!--            <img-->
          <!--              style="width: 45px; border: 1px solid blue"-->
          <!--              src="https://w7.pngwing.com/pngs/984/151/png-transparent-computer-icons-barcode-scanners-scanner-qr-code-barcode-icon-text-logo-monochrome.png"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="col-10 mt-3 text-left">-->
          <!--            <h4 class="mb-0">-->
          <!--                  <span class="font-weight-normal"-->
          <!--                  >Parcela {{ item.index }} | <b>{{ item.value | currency() }}</b>-->
          <!--                  </span>-->
          <!--              <br>-->
          <!--              <small class="font-weight-normal">-->
          <!--                Vencimento: {{ item.due_date | parseDate('DD/MM/YYYY') }}-->
          <!--              </small>-->
          <!--            </h4>-->
          <!--          </div>-->
        </template>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button
          @click.prevent="generate()"
          type="primary"
          native-type="submit"
          :loading="loading"
          :disabled="dueDateIsLessThanToday"
        >
          Emitir boletos pendentes
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { dialogs } from "../../../../../../helpers";

export default {
  name: "ModalGenerateBillet",
  data() {
    return {
      title: 'Emitir boleto',
      show: false,
      bill_receive: null,
      billet: null,
      loading: false,
      dueDateIsLessThanToday: false,
    }
  },
  methods: {
    close() {
      this.show = false
    },
    icon(item) {
      if (item.billets.length === 0) {
        return 'fa-solid fa-arrow-rotate-left'
      }
      const index = item.billets.length - 1
      const status = item.billets[index].status
      switch (status) {
        case 0:
          return 'fa-solid fa-arrow-rotate-right';
        case 3:
          return 'fa-solid fa-check-double';
        case 1:
          return 'fa-solid fa-check';
        default:
          return 'fa-solid fa-arrow-rotate-left';
      }
    },
    badgeColor(item) {
      if (item.billets.length === 0) {
        const received = 1;
        return (item.status === received)
          ? 'badge-success'
          : 'badge-warning';
      }
      const index = item.billets.length - 1
      const status = item.billets[index].status
      switch (status) {
        case 0:
          return 'badge-secondary';
        case 3:
          return 'badge-success';
        case 1:
          return 'badge-primary';
        default:
          return 'badge-warning';
      }
    },
    async generate() {
      // Confirmar operação
      const dialogMessage = `ANTES DE CONFIRMAR, VERIFIQUE SE A DATA DE VENCIMENTO ESTÁ CORRETA,
        ESSE BANCO NÃO PERMITE A ALTERAÇÃO DA DATA DE VENCIMENTO
        NO MESMO DIA QUE O BOLETO É CRIADO.`;
      const isConfirmed = await dialogs.confirmAction(dialogMessage);
      if (!isConfirmed) {
        return;
      }

      this.loading = true
      let params = {
        id: this.bill_receive.bill_receive.id,
        type: this.bill_receive.bill_receive.type,
        document_date: this.bill_receive.bill_receive.document_date,
        document_number: this.bill_receive.bill_receive.document_number,
      }
      this.$store.dispatch("billReceive/generateBillet", params).then(response => {
        this.loading = false
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.show = false
        if (this.$listeners && this.$listeners.fetch) {
          this.$emit('fetch')
        }
      }).catch(error => {
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        });
        this.loading = false
      });
    },
    openModal(id, billet, bill_receive) {
      this.billet = billet
      this.bill_receive = bill_receive

      // Carregar Data e número da NFS-e se existir um único invoice relacionado
      this.bill_receive.bill_receive.document_date = null;
      this.bill_receive.bill_receive.document_number = null;
      if ((bill_receive.nfses.length) && (bill_receive.nfses[0]) && this.arrayValuesAreEqual(bill_receive.invoices)) {
        this.bill_receive.bill_receive.document_date = bill_receive.nfses[0].authorization_date;
        this.bill_receive.bill_receive.document_number = bill_receive.nfses[0].nfse_number;
      }

      // Não permitir geração de boleto se vencimento inferior a data atual
      const today = moment(new Date(), "YYYY/MM/DD").format("YYYYMMDD");
      this.dueDateIsLessThanToday = this.bill_receive.installments.filter(
        function (value) {
          if (value.status == 1) {
            return
          }
          const due_date = moment(value.due_date, "YYYY/MM/DD").format("YYYYMMDD");
          if (due_date < today)
            return true;
        }
      ).length > 0;

      // Mostrar dialogo informando que parcela está com vencimento inferior ao dia atual
      if (this.dueDateIsLessThanToday) {
        this.$swal.fire({
          icon: 'error',
          title: 'Emissão de Boleto bloqueada!',
          text: 'Parcela com vencimento inferior ao dia atual!',
        });
        return;
      }

      // Exibir modal
      this.show = true
    },
    arrayValuesAreEqual(arr) {
      return arr.reduce(
        (prev, curr) => (prev === curr) ? prev : (!curr)
      ) === arr[0];
    }
  },
};
</script>
