<template>
  <div>
    <div class="row card-wraper">
      <div class="col-lg-4" v-for="item in $_adjustment_final_slumps_listed.items" :key="item.id">
        <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <section class="top-section d-flex align-items-center mb-1 mt-n1">
                <span
                    v-if="item.is_adjusting"
                    class="status-button-adjusting"
                  >
                      <img src="/img/icons/icons8/ios/adjust_grey.png" alt="adjust_grey" width="12px">
                      Ajustando
                </span>
                <span
                    v-else-if="!item.has_adjustment && !item.is_adjusting"
                    class="status-button-no-adjustment"
                  >
                      <img src="/img/icons/icons8/ios/adjust_grey.png" alt="adjust_grey" width="12px">
                      Sem Ajuste
                </span>
                <span
                    v-else
                    class="status-button-has-adjustment"
                  >
                      <img src="/img/icons/icons8/ios/adjust_primary.png" alt="adjust_primary" width="12px">
                      Com Ajuste
                </span>
                <div class="row m-0 ml-2">
                  <div class="mx-1">
                    <el-popover
                      class="ml-0"
                      placement="bottom"
                      trigger="click"
                    >
                      <div class="mb-n2" v-if="item.company_plants !== null">
                        <span>
                          <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                            CENTRAL
                          </span>
                          <hr class="hr-popover-user">
                          <div v-for="(company_plant, indexCompanyPlant) in item.company_plants">
                            <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ company_plant }} </span>
                          </div>
                        </span>
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/chemical-plant-v1.png"
                          alt="chemical-plant-v1"
                          width="22px"
                          @click="onCompanyPlantsByIdClick(item);"
                        >
                      </base-button>
                    </el-popover>
                  </div>
                </div>
              </section>
              <div class="ml-auto">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                    <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                  </base-button>
                  <span class="dropdown-item" @click.prevent="onEditItemClick(item.id)">
                    <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                    EDITAR
                  </span>
                  <span class="dropdown-item mb-n1" @click.prevent="onRemoveItemClick(item.id)">
                    <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                    REMOVER
                  </span>
                </base-dropdown>
              </div>
            </div>
            <section class="mid-section mt-2">
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 16px;font-weight: 500;">
                  Slump final: {{ item.nomenclature }}
                </span>
              </div>
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 16px;font-weight: 500;">
                  Slump referência: {{ item.ref_nomenclature }}
                </span>
              </div>
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 14px;font-weight: 400;">
                  <div class="checklist-item checklist-item-danger">
                    <div class="checklist-info">
                      {{ item.observation }}
                    </div>
                  </div>
                </span>
              </div>
            </section>
            <section class="bottom-section">
              <collapse class="p-0 mt-3">
                <collapse-item :name="item.id" back-ground="border-header-primary-bottom">
                  <h5 slot="title" class="mb-0">Ajustes</h5>
                  <div class="row mt-2">
                    <div class="col-md-12 justify-content-center">
                      <base-button
                        block
                        slot="title-container"
                        type="dark"
                        size="sm"
                        class="rounded"
                        outline
                        @click="resetAdjustmentValues(item);"
                      >
                        zerar todas
                      </base-button>
                    </div>
                  </div>
                  <div class="row justify-content-md-center">
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                              item.binder < 0
                                ? 'text-white btn-danger'
                                : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.binder = toNegative(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.binder);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                          <span
                            :class="
                              item.binder > 0
                                ? 'text-success align-self-end'
                                : item.binder < 0
                                ? 'text-danger align-self-end'
                                : 'text-light align-self-end'
                            "
                            style="font-weight: 500;font-size: 13px;"
                          >
                            aglomerante
                        </span>
                        <button
                          :class="
                            item.binder > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.binder = toPositive(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.binder);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm" class="mt-0">
                        <input
                          maxlength="4"
                          inputmode="numeric"
                          type="number"
                          v-model="item.binder"
                          @input="
                            item.is_adjusting = true;
                            item.binder = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.binder);
                          "
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.binder = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.binder);
                        "
                        v-model="item.binder"
                        :connect="true"
                        :options="{ step: 1, range: { min: parseInt(allLimits.binder.bottom), max: parseInt(allLimits.binder.top) } }"
                        :decimals="0"
                      >
                      </base-slider>
                    </div>
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                            item.water < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.water = toNegative(CmcAdjustmentLimitTypeEnum.keys.WATER, item.water);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <span
                        :class="
                          item.water > 0
                            ? 'text-success align-self-center'
                            : item.water < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        "
                        style="font-weight: 500;font-size: 13px;"
                      >
                        água
                      </span>
                        <button
                          :class="
                            item.water > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.water = toPositive(CmcAdjustmentLimitTypeEnum.keys.WATER, item.water);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm" class="mt-0">
                        <input
                          maxlength="4"
                          @input="
                            item.is_adjusting = true;
                            item.water = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.WATER, item.water);
                          "
                          inputmode="numeric"
                          type="number"
                          v-model="item.water"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.water = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.WATER, item.water);
                        "
                        v-model="item.water"
                        :connect="true"
                        :options="{ step: 1, range: { min: parseInt(allLimits.water.bottom), max: parseInt(allLimits.water.top) } }"
                        :decimals="0"
                      >
                      </base-slider>
                    </div>
                  </div>
                  <div class="row justify-content-md-center">
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                            item.content_mortar < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.content_mortar = toNegative(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.content_mortar);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <span
                        :class="
                          item.content_mortar > 0
                            ? 'text-success align-self-center'
                            : item.content_mortar < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        "
                        style="font-weight: 500;font-size: 13px;"
                      >
                        teor de argamassa
                      </span>
                        <button
                          :class="
                            item.content_mortar > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.content_mortar = toPositive(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.content_mortar);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm">
                        <input
                          maxlength="6"
                          @input="
                            item.is_adjusting = true;
                            item.content_mortar = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.content_mortar);
                          "
                          inputmode="numeric"
                          type="number"
                          v-model="item.content_mortar"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.content_mortar = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.content_mortar);
                        "
                        v-model="item.content_mortar"
                        :connect="true"
                        :options="{
                          step: 0.1,
                          range: { min: parseFloat(allLimits.content_mortar.bottom), max: parseFloat(allLimits.content_mortar.top) },
                        }"
                        :decimals="1"
                      >
                      </base-slider>
                    </div>
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                            item.total_air < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.total_air = toNegative(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.total_air);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <span
                          :class="
                            item.total_air > 0
                              ? 'text-success align-self-center'
                              : item.total_air < 0
                              ? 'text-danger align-self-center'
                              : 'text-light align-self-center'
                          "
                          style="font-weight: 500;font-size: 13px;"
                        >
                        ar total
                      </span>
                        <button
                          :class="
                            item.total_air > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.total_air = toPositive(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.total_air);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes=" input-group-sm">
                        <input
                          maxlength="6"
                          @input="
                            item.is_adjusting = true;
                            item.total_air = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.total_air);
                          "
                          inputmode="numeric"
                          type="number"
                          v-model="item.total_air"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.total_air = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.total_air);
                        "
                        v-model="item.total_air"
                        :connect="true"
                        :options="{
                          step: 0.1,
                          range: { min: parseFloat(allLimits.total_air.bottom), max: parseFloat(allLimits.total_air.top) },
                        }"
                        :decimals="1"
                      >
                      </base-slider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 text-center pt-3">
                      <h5
                        v-if="item.agent != null"
                        :class="
                          item.agent !== null && item.agent[0].value > 0
                            ? 'text-uppercase text-success'
                            : item.agent !== null && item.agent[0].value < 0
                            ? 'text-uppercase text-danger'
                            : 'text-uppercase text-light'
                        "
                      >
                        Aditivo A
                      </h5>
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="item.agent[0].id"
                          :items="agentGroups"
                          :disableBoxShadow="true"
                          @change="checkAgentOnChange(0, $event, item);"
                          label="group_name"
                        />
                      </base-input>
                      <div v-if="item.agent[0].id" class="auxiliary-buttons d-flex justify-content-between my-1">
                        <button
                          :class="
                            item.agent[0].value < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.agent[0].value = toNegative(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[0].value);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <button
                          :class="
                            item.agent[0].value > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.agent[0].value = toPositive(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[0].value);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" v-if="item.agent[0].id" input-group-classes="input-group-sm mt-1">
                        <input
                          maxlength="7"
                          @input="
                            item.is_adjusting = true;
                            item.agent[0].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[0].value);
                          "
                          v-model="item.agent[0].value"
                          inputmode="numeric"
                          type="number"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <base-slider
                        v-if="item.agent[0].id"
                        @input="
                          item.is_adjusting = true;
                          item.agent[0].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[0].value);
                        "
                        v-model="item.agent[0].value"
                        :connect="true"
                        :options="{ step: 0.01, range: { min: parseFloat(allLimits.agent.bottom), max: parseFloat(allLimits.agent.top) } }"
                        :decimals="2"
                      >
                      </base-slider>
                    </div>
                    <div class="col-md-6 text-center pt-3">
                      <h5
                        v-if="item.agent != null"
                        :class="
                          item.agent && item.agent[1].value > 0
                            ? 'text-uppercase text-success'
                            : item.agent && item.agent[1].value < 0
                            ? 'text-uppercase text-danger'
                            : 'text-uppercase text-light'
                        "
                      >
                        Aditivo B
                      </h5>
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="item.agent[1].id"
                          :items="agentGroups"
                          :disableBoxShadow="true"
                          @change="checkAgentOnChange(1, $event, item);"
                          label="group_name"
                        />
                      </base-input>
                      <div v-if="item.agent[1].id" class="auxiliary-buttons d-flex justify-content-between my-1">
                        <button
                          :class="
                            item.agent[1].value < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.agent[1].value = toNegative(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[1].value);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <button
                          :class="
                            item.agent[1].value > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.agent[1].value = toPositive(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[1].value);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" v-if="item.agent[1].id" input-group-classes="input-group-sm mt-1">
                        <input
                          maxlength="7"
                          @input="
                            item.is_adjusting = true;
                            item.agent[1].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[1].value);
                          "
                          v-model="item.agent[1].value"
                          inputmode="numeric"
                          type="number"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <div>
                        <base-slider
                          v-if="item.agent[1].id"
                          @input="
                            item.is_adjusting = true;
                            item.agent[1].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.agent[1].value);
                          "
                          v-model="item.agent[1].value" 
                          :connect="true"
                          :options="{ step: 0.01, range: { min: parseFloat(allLimits.agent.bottom), max: parseFloat(allLimits.agent.top) } }"
                          :decimals="2"
                        >
                        </base-slider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 pt-3">
                      <base-input input-group-classes="input-group-sm">
                        <textarea
                          v-model="item.observation"
                          type="text"
                          rows="4"
                          class="form-control form-control-sm"
                          @input="item.is_adjusting = true;"
                        >
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                  <div v-show="item.is_adjusting">
                    <div class="d-flex mt-3 mb-2 justify-content-between form-buttons-cmc-adjustment">
                      <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="onCancelDosageAdjutmentClick(item);">
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                        <span style="font-weight: 500;">
                          Cancelar
                        </span>
                      </div>
                      <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onUpdateDosageAdjustmentClick(item);">
                        <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="18px">
                        <span style="font-weight: 500;">
                          Salvar
                        </span>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits } from "vue";
import adjustmentFinalSlumpStore from "../../store/adjustmentFinalSlumpStore";
import CmcAdjustmentLimitTypeEnum from "../../../cmc-adjustment/enums/CmcAdjustmentLimitTypeEnum";
import { useAdjustmentFinalSlumpHook } from "../../hooks/useAdjustmentFinalSlumpHook";
import { AdjustmentFinalSlumpListType, AdjustmentFinalSlumpUpdateDosageType } from "../../types";
import { AgentType } from "../../../cmc-adjustment/types";
import PuzlSelect from "@/components/PuzlSelect";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
  "onCancelDosageAdjutmentClick",
  "onUpdateDosageAdjustmentClick",
  "onCompanyPlantsByIdClick"
]);
//#endregion

//#region Data
const {
  allLimits,
  agentGroups,
  validateValueFromLimit,
  resetAdjustmentValues,
  toPositive,
  toNegative,
  filteredAgentGroupsA,
  filteredAgentGroupsB
} = useAdjustmentFinalSlumpHook();
//#endregion

//#region Computeds
/** Store Getters */
const $_adjustment_final_slumps_listed = computed(() => adjustmentFinalSlumpStore.getters.getListed());
const $_adjustment_final_slumps_is_listing = computed(() => adjustmentFinalSlumpStore.getters.getIsListing());
//#endregion

//#region Methods
/**
 * Carrega informações do slump
 * @param {AdjustmentFinalSlumpListType} adjustment
 */
function onCancelDosageAdjutmentClick(adjustment) {
  emit("onCancelDosageAdjutmentClick", adjustment)
}
/**
 * Editar item
 * @param {number} id
 */
 function onEditItemClick(id) {
  emit("onEditItemClick", id);
}
/**
 * Remover item
 * @param {number} id
 */
 function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}
/**
 * Atualiza valores de dosagem
 * @param {AdjustmentFinalSlumpListType} adjustment
 */
function onUpdateDosageAdjustmentClick(adjustment) {

  const has_agent = hasAgent(adjustment.agent);

  /** @type {AdjustmentFinalSlumpUpdateDosageType} updateDosageData */
  const updateDosageData = {
    id: adjustment.id,
    binder: adjustment.binder,
    water: adjustment.water,
    content_mortar: adjustment.content_mortar,
    total_air: adjustment.total_air,
    agent: has_agent ? adjustment.agent : null,
    observation: adjustment.observation
  };
  emit("onUpdateDosageAdjustmentClick", updateDosageData)
}
/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}
/**
 * 
 * @param {number} position 
 * @param {?number} agent_id 
 * @param {AdjustmentFinalSlumpListType} adjustment 
 */
function checkAgentOnChange(position, agent_id, adjustment) {
  if(!agent_id) {
    adjustment.agent[position].id = null;
    adjustment.agent[position].value = null;
  }

  adjustment.is_adjusting = true;
}

/**
 * @param {AdjustmentFinalSlumpListType} adjustment
 */
function onCompanyPlantsByIdClick(adjustment) {
  if(!adjustment.company_plants) {
    emit("onCompanyPlantsByIdClick", adjustment);
  }
}
//#endregion

</script>

<style scoped>
.status-button-has-adjustment {
  background-color: #F2F4F9;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #1A70B7;
  font-weight: 400;
  padding: 5px 25px;
}
.status-button-no-adjustment {
  background-color: #E8E8E8;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #606062;
  font-weight: 400;
  padding: 5px 25px;
}
.status-button-adjusting {
  background-color: #FEF9F2;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #606062;
  font-weight: 400;
  padding: 5px 25px;
}
.btn {
  box-shadow: none !important;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 5px 53px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 10px 75px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
}
.hr-popover-user {
  margin: 7px 0;
  width: 118%;
  margin-left: -11px;
}
@media (max-width: 1727px) {
  .button-cancel {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }
  .button-save {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }
  .form-buttons-cmc-adjustment {
    flex-direction: column;
    gap: 10px;
  }
}
</style>