const keys = Object.freeze({
  CMC: 2, // MCC
  PRODUCT_SERVICE: 3, // Produtos e Serviços
  FREIGHT: 4, // Frete
  TAX: 5, // Tributos
  LOAN: 6, // Empréstimo
  FINANCING: 7, // Financiamento
  UNKNOWN: 8, // Desconhecido
  PAYROLL: 9, // Folha de Pagamento
  FEE: 10, // Taxa
  RECIPE_DEVOLUTION: 11, // Devolução de Receita
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.CMC:
      return "MCC";
    case keys.PRODUCT_SERVICE:
      return "Produtos e Serviços";
    case keys.FREIGHT:
      return "Frete";
    case keys.TAX:
      return "Tributos";
    case keys.LOAN:
      return "Empréstimo";
    case keys.FINANCING:
      return "Financiamento";
    case keys.UNKNOWN:
      return "Desconhecido";
    case keys.PAYROLL:
      return "Folha de Pagamento";
    case keys.FEE:
      return "Taxa";
    case keys.RECIPE_DEVOLUTION:
      return "Devolução de Receita";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
