const keys = Object.freeze({
  PUZL: 0,
  SAP: 1,
  TOTVS: 2,
  SANKHYA: 3,
});

const getTitle = (value) => {
  switch (value) {
    case keys.PUZL:
      return "PUZL MIX";
    case keys.SAP:
      return "SAP";
    case keys.TOTVS:
      return "TOTVS";
    case keys.SANKHYA:
      return "SANKHYA";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
