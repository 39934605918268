<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @clearFilters="clearFilters" @fetch="init" :filter="filter" :status="multi_filter">
        <div class="col-md-3 p-2">
          <input-date-picker
            :disableMinDate="new Date(today)"
            :default-range="defaultRange"
            ref="date-picker"
            :filterable="5"
            @handleFilterDate="handleFilterDate"
          />
        </div>
        <div class="col-md-3 p-2">
          <puzl-select
            @input="init({})"
            v-model="filter.payment_method_ids"
            multiple
            :disabled="loadingPaymentMethod || loadingSkeleton || loading"
            :items="$_payment_methods"
            placeholder="Método de pagamento"
          />
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      
      <!-- Liberação de crédito desabilitada -->
      <div class="pt-1" v-if="$_general_settings && $_general_settings.disable_pending_credit_release">
        <div class="card mt-4">
          <div class="card-body py-5">
            <div class="row text-center d-flex justify-content-center">
              <a class="button-inactive-danger col-auto h-100" style="color: rgb(220, 53, 69);">
                <img src="/img/icons/icons8/ios/cancel_danger.png" width="12px"> 
                Atenção, a liberação de crédito está desabilitada para essa base.
              </a>
            </div>
          </div>
        </div>
      </div>
      <PuzlEmptyData v-else-if="!$_schedules.length && !loadingSkeleton && !loading"></PuzlEmptyData>

      <div class="row card-wrapper pt-2" v-if="$_schedules.length && !loadingSkeleton">
        <div class="col-md-6 col-lg-4" v-for="(schedule, index) in $_schedules" :key="index">
          <card>
            <h3 class="mb-0">
              <div class="row">
                <div class="col-3">
                  {{ schedule.code }}
                </div>
                <div class="offset-1 col-8 mt-n2 text-right ">
                  <span class="px-2">
                    <el-popover trigger="hover" placement="bottom" class="p-0">
                      <span>
                        {{ schedule.seller_name }}
                      </span>
                      <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <img style="width: 28px" src="/img/icons/salesman.png" />
                      </base-button>
                    </el-popover>
                  </span>
                  <span class="px-2">
                    <a href="#" @click.prevent="handleShowAttachments(schedule.entity_id, schedule.entity_name, schedule.document)">
                      <span v-if="schedule.total_entity_attachments > 0">
                        <img style="width: 28px" src="/img/icons/paperclip-green.png" :title="`Total de anexos: ${schedule.total_entity_attachments}`" />
                      </span>
                      <span v-else>
                        <img style="width: 28px" src="/img/icons/paperclip.png" :title="`Total de anexos: ${schedule.total_entity_attachments}`" />
                      </span>
                    </a>
                  </span>
                  <span class="px-2">
                    <a href="#" title="Registros do cliente"
                      @click.prevent="handleModalEntityRegistry(schedule.entity_id, schedule.entity_uuid, schedule.entity_name, schedule.document)">
                      <img style="width: 28px" src="/img/icons/signing-a-document-blue.png" />
                    </a>
                  </span>
                  <span class="px-2">
                    <a href="#" @click.prevent="handleListContacts(schedule.construction_uuid)">
                      <img style="width: 29px" src="/img/icons/contacts.png" />
                    </a>
                  </span>
                </div>
              </div>
            </h3>
            <h3 class="mb-0">
              {{ schedule.entity_name }}
            </h3>
            <h3 class="mb-0">
              {{ schedule.document }}
            </h3>
            <h3 class="mb-0">
              {{ schedule.construction_name }}
            </h3>

            <div class="dropdown-divider mt-2 p-0 m-0 mb-2 border"></div>

            <div class="row">
              <div class="col-12">
                <h3 class="mb-0">
                  <div class="row">
                    <div class="col-6">
                      {{ schedule.company_plant_names }}
                    </div>
                    <div class="text-right col-6">
                      <span class="px-1">
                        <a href="#" @click.prevent="handleModalEntityCreditLimit(schedule)">
                          <img style="width: 28px" src="/img/icons/plus.png"/>
                        </a>
                      </span>
                      <span class="px-1">
                        <el-popover trigger="hover" placement="bottom" class="">
                          Métodos de Pagamento <br>
                          <span> {{schedule.payment_methods}} </span> <br><br>
                          Condições de Pagamento <br>
                          <span> {{schedule.payment_terms}} </span>
                          <base-button outline slot="reference" size="sm" type="secundary" class="shadow-none text-success">
                            <img style="width: 28px" src="/img/icons/card-in-use.png"/>
                          </base-button>
                        </el-popover>
                      </span>
                      <span class="px-1">
                        <a
                          role="button"
                          @click.prevent="handleModalEntityHistory(schedule)"
                          title="Saldos"
                        >
                          <img style="width: 28px" src="/img/icons/wallet.png"/>
                        </a>
                      </span>
                    </div>
                  </div>
                </h3>
                <h3 class="mb-0">
                  <div class="row">
                    <div class="col-4 mt-n2" style="margin-right: -13px;">
                      {{ schedule.concreting_date | parseDate("DD MMM YYYY") }}
                    </div>
                    <div class="offset-6 col-2 px-0" >
                      <el-popover v-show="schedule.has_overdue_bill_receive_installment" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                        Existência de recebimentos atrasados <br>
                        <base-button @click.prevent="handleModalOverdueInstallments(schedule)" outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none  text-success">
                          <i
                            style="font-size: 30px !important"
                            role="button"
                            class="fas fa-exclamation-triangle fa-2x text-danger"
                          />
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div v-if="$hasPermission('additional_permissions.financial_approvation')" class="row p-0 mt-2">
              <div class="col-12 text-center">
                <base-dropdown v-show="schedule.necessary_approvation">
                  <button
                    slot="title-container"
                    class="dropdown-toggle p-1 rounded m-0 mt-1 mb-1"
                    :class="[
                      schedule.approvation === financialApprovalStatus['financial_pending']['status'] ? 'btn btn-warning' : '',
                      schedule.approvation === financialApprovalStatus['financial_approval']['status'] ? 'btn btn-success' : '',
                      schedule.approvation === financialApprovalStatus['financial_disapproval']['status'] ? 'btn btn-danger' : '',
                    ]"
                  >
                    <span v-if="schedule.approvation === financialApprovalStatus['financial_pending']['status']">
                      <i class="fa-solid fa-thumbs-up"></i> PENDÊNCIA FINANCEIRA
                    </span>
                    <span v-if="schedule.approvation === financialApprovalStatus['financial_approval']['status']">
                      <i class="fa-solid fa-thumbs-up"></i> APROVAÇÃO FINANCEIRA
                    </span>
                    <span v-if="schedule.approvation === financialApprovalStatus['financial_disapproval']['status']">
                      <i class="fa-solid fa-thumbs-down"></i> REPROVAÇÃO FINANCEIRA
                    </span>
                  </button>
                    <span
                      class="dropdown-item"
                      :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(schedule.contract_proposal_status)"
                      @click.prevent="approve(schedule.contract_proposal_id, financialApprovalStatus['financial_pending']['status'])"
                    >
                      <i class="fa-solid fa-thumbs-up text-warning"></i> PENDENTE
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span
                      class="dropdown-item"
                      :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(schedule.contract_proposal_status)"
                      @click.prevent="approve(schedule.contract_proposal_id, financialApprovalStatus['financial_approval']['status'])"
                    >
                      <i class="fa-solid fa-thumbs-up text-success"></i> APROVAR
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span
                      class="dropdown-item"
                      :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(schedule.contract_proposal_status)"
                      @click.prevent="approve(schedule.contract_proposal_id, financialApprovalStatus['financial_disapproval']['status'])"
                    >
                      <i class="fa-solid fa-thumbs-down text-danger"></i> REPROVAR
                    </span>
                </base-dropdown>
              </div>
            </div>
          </card>
        </div>
      </div>

      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
    <ModalListContacts ref="modalListContacts" />
    <ModalEntityCreditLimit ref="modalEntityCreditLimit" @updated="init({})" />
    <ModalEntityHistory ref="modalEntityHistory" />
    <ModalOverdueInstallments ref="modalOverdueInstallments" />
    <ModalEntityAttachmentList ref="modalEntityAttachmentList" @storedAttachment="storedAttachment" @deletedLastAttachment="deletedLastAttachment" />
    <ModalEntityRegistry ref="modalEntityRegistry" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "../../../../../components/Utils/MultiFilterV2";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import ModalListContacts from "../../../Operational/Schedule/Weighing/Shared/_ModalListContacts";
import ModalEntityCreditLimit from "../../../Configuration/Entity/Shared/_ModalCreditLimit.vue";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import ModalOverdueInstallments from "./_ModalOverdueInstallments";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList.vue";
import ModalEntityRegistry from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityRegistry.vue";
import entityFinancialStore from "../../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";
import EntityFinancialHubEnum from '../../../../../modules/configuration/entity/aggregates/entity-financial/enums/EntityFinancialHubEnum';

export default {
  name: "ListSchedule",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    ModalListContacts,
    ModalEntityCreditLimit,
    ModalEntityHistory,
    PuzlSelect,
    ModalOverdueInstallments,
    ModalEntityAttachmentList,
    ModalEntityRegistry,
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {
        status: null,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        0: {
          name: "Limite de crédito",
          type: "warning",
        },
        1: {
          name: "Aprovação financeira",
          type: "warning",
        },
      },
      range: {
        start: null,
        end: null,
      },
      financialApprovalStatus: {
        'financial_pending': {
            name: "Pendência financeira",
            status: 0,
        },
        'financial_approval': {
            name: "Aproação financeira",
            status: 1,
        },
        'financial_disapproval': {
            name: "Reprovação financeira",
            status: 2,
        }
      },
      defaultRange: {
        start: this.getToday(),
        end: this.getEndDate()
      },
      today:null,
      loadingPaymentMethod:true,
    };
  },
  computed: {
    ...mapGetters({
      $_schedules: "creditRelease/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_general_settings: "generalSetting/show",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.filter.range = this.range;
      this.$store
        .dispatch("creditRelease/fetchItems", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.filter = {
        status: null,
      };
      this.$refs["date-picker"].resetDate();
      this.range = {
        start: this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss"),
        end: this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss"),
      };
      this.init();
    },
    handleListContacts(construction_uuid) {
      this.$refs.modalListContacts.openModal(construction_uuid);
    },
    handleModalEntityHistory(schedule) {
      this.$refs.modalEntityHistory.openModal(
        schedule.entity_id,
        { id: schedule.entity_id, uuid: schedule.entity_uuid, document: schedule.document },
        schedule.contract_proposal_id
      );
    },
    async handleModalEntityCreditLimit(schedule) {
      let loader = this.$loading.show();
      await entityFinancialStore.actions.getByEntity(schedule.entity_id, { sync_hub : EntityFinancialHubEnum.keys.PUZL});
      this.$refs.modalEntityCreditLimit.openModal(schedule.entity_id, schedule.document.length === 18)
      loader.hide();
    },
    approve(id, status) {
      let loader = this.$loading.show();
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("contractProposal/putApprove", { id: id, status: status })
        .then((response) => {
          this.$notify({ type: "success", message: response.message });
          this.init({});
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleModalOverdueInstallments(schedule) {
      this.$refs.modalOverdueInstallments.openModal({
        id: schedule.entity_id,
        entity_name: schedule.entity_name,
        document: schedule.document,
       });
    },
    /**
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * @description
     * Atualiza ícone de anexos após realizar o upload.
     *
     * @param {number} entityId
     */
    storedAttachment(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        let foundEntity = this.$_schedules.find((item) => item.entity_id == entityId);
        foundEntity.total_entity_attachments = response.data.total_entity_attachments;
      });
    },
    /**
     * @description
     * Após excluir um anexo, é atualizado o total de arquivos anexados.
     *
     * @param {number} entityId
     */
    deletedLastAttachment(entityId) {
      let foundEntity = this.$_schedules.find((item) => item.entity_id == entityId);
      foundEntity.total_entity_attachments --;
    },
    /**
     * @param {number} entityId
     * @param {string} entityUuid
     * @param {string} name
     * @param {string} document
     */
    handleModalEntityRegistry(entityId, entityUuid, name, document) {
      this.$refs.modalEntityRegistry.handleCreateModal(entityId, entityUuid, name, document);
    },
    getToday() {
      return new Date();
    },
    getEndDate() {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 7);
      return endDate;
    }
  },
  mounted() {
    this.today = this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss");
    this.range = this.defaultRange;
    this.init();
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethod = false;
    });
    this.$store.dispatch('generalSetting/show');
  },
};
</script>
