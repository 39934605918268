import { PagerType, initPagerType } from "../../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} DriverScaleListType
 * @property {number} id
 * @property {string} date
 * @property {string} time
 * @property {number} driver_id
 * @property {string} driver_name
 * @property {number} company_plant_id
 * @property {string} company_plant_name
 * @property {?number} created_by_user_id
 * @property {?string} created_at
 * @property {?string} updated_at
 * @property {?number} schedule_travel_id
 * @property {?string} first_travel_created_at
 * @property {?string} sequencial_number_plant_number
 * @property {?string} xml_travel_number
 */
export const DriverScaleListType = {};

/**
 * Type para retorno paginado da listagem
 * @typedef {(PagerType & { items: Array<DriverScaleListType> })} DriverScaleListPagerType
 */
export const DriverScaleListPagerType = {};

/**
 * Inicializar DriverScaleListPagerType
 * @returns {DriverScaleListPagerType}
 */
export const initDriverScaleListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};
