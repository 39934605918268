const keys = Object.freeze({
  NONE: 'none',
  SM: 'sm',
  DEFAULT: 'default',
  MD: 'md',
  LG: 'lg',  
  XL: 'xl', 
  XXL: 'xxl',
  XXXL: 'xxxl', 
  FULL: 'full',
});

/**
 * @param {string} value
 * @returns {string}
 */
const getRadius = (value) => {
  switch (value) {
    case keys.NONE: return "0px";
    case keys.SM: return "0.125rem";
    case keys.DEFAULT: return "0.25rem";
    case keys.MD: return "0.375rem";
    case keys.LG: return "0.5rem";
    case keys.XL: return "0.75rem";
    case keys.XXL: return "1rem";
    case keys.XXXL: return "1.5rem";
    case keys.FULL: return "9999px";
    default: return "0.25rem";
  }
};

export default {
  keys,
  getRadius,
};
