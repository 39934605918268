/**
 * Ficha de crédito
 *
 * @typedef {Object} EntityFinancialType
 * @property {number} total_performed
 * @property {number} free_credit
 * @property {number} iss_retain
 * @property {number} bill_receive_total_paid
 * @property {number} bill_receive_total_overdue
 * @property {number} bill_receive_total_pending
 * @property {number} anticipation_balance
 * @property {number} anticipation_balance_test
 * @property {number} recipe_devolution
 * @property {number} invoice_without_bill_receive
 * @property {number} total_performed_without_retentions
 * @property {number} net_total_balance
 * @property {number} total_balance_with_recipe_devolution
 * @property {number} opened_billing
 * @property {number} new_balance_to_test
 */
export const EntityFinancialType = {};

/**
 * Inicializar EntityFinancialType
 *
 * @returns {EntityFinancialType}
 */
export const initEntityFinancialType = () => {
  return {
    total_performed: 0,
    free_credit: 0,
    iss_retain: 0,
    bill_receive_total_paid: 0,
    bill_receive_total_overdue: 0,
    bill_receive_total_pending: 0,
    anticipation_balance: 0,
    anticipation_balance_test: 0,
    recipe_devolution: 0,
    invoice_without_bill_receive: 0,
    total_performed_without_retentions: 0,
    net_total_balance: 0,
    total_balance_with_recipe_devolution: 0,
    opened_billing: 0,
    new_balance_to_test: 0,
  };
};
