const keys = Object.freeze({
  PUZL: "puzl",
  SAP: "sap",
  SANKHYA: "sankhya",
  VARUNA: "varuna",
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.PUZL:
      return "Puzl";
    case keys.SAP:
      return "Sap";
    case keys.SANKHYA:
      return "Sankhya";
    case keys.VARUNA:
      return "Varuna";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
