/**
 * Type para buscar ficha de crédito via cliente
 *
 * @typedef {Object} EntityFinancialGetByEntityFilterType
 * @property {string} sync_hub // [puzl, sap, sankhya]
 * @property {number|null} contract_proposal_id
 */
export const EntityFinancialGetByEntityFilterType = {};

/**
 * Inicializar EntityFinancialGetByEntityFilterType
 *
 * @returns {EntityFinancialGetByEntityFilterType}
 */
export const initEntityFinancialGetByEntityFilterType = () => {
  return {
    sync_hub: "",
    contract_proposal_id: null,
  };
};
