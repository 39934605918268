import store from "../../../../../shared/libraries/store";
import { listSources, createSource, removeSource } from "../../../../../shared/store";
import { 
    initDriverScaleListPagerType,
    DriverScaleListFilterType,
    DriverScaleListPagerType, 
    DriverScaleType,
} from "../types";

/** URLS */
const urls = Object.freeze({
  BASE: "/operational/schedule/driver_scale",
});

/** KEYS */
const keys = Object.freeze({
    LISTED: "DRIVER_SCALE_LISTED",
    IS_LISTING: "DRIVER_SCALE_IS_LISTING",
    PAGE_STATES: "DRIVER_SCALE_PAGE_STATE",
    IS_SAVING: "DRIVER_SCALE_IS_SAVING",
    IS_REMOVING: "DRIVER_SCALE_IS_REMOVING",
});

const getters = {
    /** @returns {DriverScaleListPagerType} */
    getListed: function () {
        return store.getState(keys.LISTED) ?? initDriverScaleListPagerType();
    },

    /** @returns {boolean} */
    getIsListing: function () {
        return store.getState(keys.IS_LISTING) ?? false;
    },

    /** 
     * @returns {{ 
     *   searchBarFilter: SearchBarFilterType, 
     *   filter: DriverScaleListFilterType
     * }}
    */
    getPageState: function () {
        return store.getState(keys.PAGE_STATES);
    },

    /** @returns {boolean} */
    getIsSaving: function () {
        return store.getState(keys.IS_SAVING) ?? false;
    } 
};


/** MUTATIONS */
const mutations = {
    /**
     * @param {DriverScaleListPagerType} listed
     * @returns {DriverScaleListPagerType}
     */
    setListed: function (listed) {
        return store.commit(keys.LISTED, listed);
    },

    /**
     * @param {boolean} isListing
     * @returns {boolean}
     */
    setIsListing: function (isListing) {
        return store.commit(keys.IS_LISTING, isListing);
    },

    /**
     * @param {DriverScaleListPagerType} listed
     * @returns {DriverScaleListPagerType}
     */
    setListMerge: function (listed) {
        return store.commitListMerge(keys, listed);
    },

    /** @param {{ searchBarFilter: SearchBarFilterType, filter: DriverScaleListFilterType }} filters */
    setPageState: function (filters) {
        return store.commit(keys.PAGE_STATES, filters);
    },

    /** Resetar chaves de estado */
    resetStates: function () {
        return store.resetStates(Object.values(keys));
    },

    /**
     * @param {boolean} isSaving
     * @returns {boolean}
     */
    setIsSaving: function (isSaving) {
        return store.commit(keys.IS_SAVING, isSaving)
    },

    /**
     * @param {DriverScaleType} created
     * @returns {DriverScaleType}
     */
    setNewItemInList: function (created) {
        return store.commitNewItemToList(keys, created)
    },

     /**
     * @param {DriverScaleListPagerType} listed
     * @returns {DriverScaleListPagerType}
     */
    setListMerge: function (listed) {
        store.commitListMerge(keys, listed)
    },
    
    /**
     * @param {number} id
     */
    setRemovedItem: function (id) {
        store.commitRemovedItem(keys, id)
    },

    /**
     * @param {boolean} isRemoving
     * @returns {boolean}
     */
    setIsRemoving: function (isRemoving) {
        store.commit(keys.IS_REMOVING, isRemoving)
    },
};

/** ACTIONS */
const actions = {
    /**
     * Listar
     * @param {DriverScaleListFilterType} filter - Filtro de listagem
     * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
     * @returns {Promise<?DriverScaleListPagerType>} - Retorno paginado
     */
    list: async function (filter, isAccumulateItems = false) {
        return await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE);
    },

    /**
     * Incluir
     * @param {DriverScaleType} data
     * @returns {Promise<DriverScaleType>}
     */
    create: async function (data) {
        return await createSource(data, mutations, urls.BASE);
    },

    /**
     * Deletar por ID
     * @param {number} id
     * @returns {Promise<void>}
     */
    remove: async function (id) {
        await removeSource(id, mutations, urls.BASE)
    },
};

export default {
  actions,
  getters,
  mutations,
};
