<template>
  <div class="row">
    <div class="col-md-12 table-responsive" style="min-height: 280px">
      <table class="table table-sm">
        <thead>
          <tr class="fixed">
            <th class="text-center">Status</th>
            <th class="text-left">Nome</th>
            <th class="text-left">Agência</th>
            <th class="text-left">Conta</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in 10"
            v-show="$_bank_account_is_listing"
            :key="index"
          >
            <th colspan="12">
              <SkeletonPuzl type="button" />
            </th>
          </tr>
          <tr
            v-for="(bank_account, index) in $_bank_account_listed.items"
            :key="`bank_account-${index}`"
          >
            <td width="10%" class="text-center p-16">
              <AppSwitch
                v-model="bank_account.status"
                :asyncClick="() => updateStatus(bank_account)"
                class="app-switch-reset"
              />
            </td>
            <td width="35%">
              <div
                class="table-content d-flex justify-content-between align-items-center"
              >
                <span
                  v-if="isMobile && bank_account.name.length < 30"
                  class="table-text col p-0 break-word"
                >
                  {{ "1" + bank_account.name }}
                </span>
                <el-popover
                  trigger="click"
                  placement="bottom"
                  class="mr-2 h-100"
                  v-if="isMobile && bank_account.name.length >= 30"
                >
                  <div class="table-text d-flex flex-column">
                    {{ bank_account.name }}
                  </div>
                  <base-button
                    slot="reference"
                    type="secundary"
                    class="table-text limit-text col p-0"
                    id="reset-name-button"
                  >
                    {{ bank_account.name }}
                  </base-button>
                </el-popover>

                <span v-if="!isMobile" class="table-text col p-0 break-word">
                  {{ bank_account.name }}
                </span>

                <div class="table-icons col text-right pr-0">
                  <AppIcon
                    icon="barcode"
                    width="24"
                    v-if="
                      bank_account.has_bank_account_billet_params_integration
                    "
                    class="mr-2"
                  />
                  <AppIcon
                    icon="pix"
                    width="24"
                    v-if="bank_account.pix_status === 1"
                    color="success"
                    class="mr-2"
                  />
                  <el-popover
                    trigger="click"
                    placement="bottom"
                    class="mr-2 h-100"
                    v-if="
                      bank_account?.created_by_user_name &&
                      !bank_account?.updated_by_user_name
                    "
                  >
                    <div class="table-text d-flex flex-column">
                      <span class="popover-title"> Última Atualização </span>
                      <hr class="popover-title-border full" />
                      <span class="popover-text">
                        {{ bank_account?.created_by_user_name }} <br />
                        {{
                          bank_account.created_at
                            | parseDate("DD MMM YYYY - HH:mm")
                        }}
                      </span>
                    </div>
                    <base-button
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                      <AppIcon icon="male-user" width="25" />
                    </base-button>
                  </el-popover>
                  <el-popover
                    trigger="click"
                    placement="bottom"
                    class="mr-2 h-100"
                    v-if="
                      bank_account?.updated_by_user_name ||
                      (!bank_account?.created_by_user_name &&
                        bank_account?.updated_by_user_name)
                    "
                  >
                    <div class="table-text d-flex flex-column">
                      <span class="popover-title"> Última Atualização </span>
                      <hr class="popover-title-border full" />
                      <span class="popover-text">
                        {{ bank_account?.updated_by_user_name }} <br />
                        {{
                          bank_account.updated_at
                            | parseDate("DD MMM YYYY - HH:mm")
                        }}
                      </span>
                    </div>
                    <base-button
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                      <AppIcon icon="male-user" width="25" />
                    </base-button>
                  </el-popover>
                </div>
              </div>
            </td>
            <td width="15%" class="">
              <span class="table-text fs-14">
                {{ bank_account.number_without_digit_agency
                }}<span v-if="bank_account.digit_agency"
                  >-{{ bank_account.digit_agency }}
                </span>
              </span>
            </td>
            <td width="15%" class="">
              <span class="table-text fs-14">
                {{ bank_account.number_without_digit_account
                }}<span v-if="bank_account.digit_account"
                  >-{{ bank_account.digit_account }}</span
                >
              </span>
            </td>
            <td width="6%" class="text-center">
              <base-dropdown menuOnRight>
                <div
                  slot="title-container"
                  class="dropdown-toggle rounded m-0 ml-auto"
                >
                  <AppIcon icon="settings" color="primary" width="25" />
                </div>
                <a
                  class="dropdown-item"
                  @click.prevent="onEditItemClick(bank_account.id)"
                >
                  <AppIcon
                    icon="create"
                    color="warning"
                    width="20"
                    class="mr-2"
                  />
                  Editar
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="onUpdateItemSettings(bank_account.id)"
                >
                  <AppIcon icon="automation" width="20" class="mr-2" />
                  Configurações
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="onUpdateItemBillet(bank_account.id)"
                >
                  <AppIcon icon="barcode" width="20" class="mr-2" />
                  BOLETO
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="onUpdateItemPix(bank_account.id)"
                >
                  <AppIcon icon="pix" color="success" width="20" class="mr-2" />
                  PIX
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="onRemoveItemClick(bank_account.id)"
                >
                  <AppIcon
                    icon="trash-can"
                    color="danger"
                    width="20"
                    class="mr-2"
                  />
                  EXCLUIR
                </a>
              </base-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <PuzlEmptyData
        v-show="
          $_bank_account_listed &&
          !$_bank_account_listed.items.length &&
          !$_bank_account_is_listing
        "
      />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, onBeforeUnmount, onMounted, ref } from "vue";
import bankAccountStore from "../../store/bankAccountStore";
import { BankAccountListType } from "../../types";
import { AppIcon, AppSwitch } from "../../../../../components/AppGlobal";
import PuzlEmptyData from "@/components/";
import SkeletonPuzl from "@/components/";
import { dialogs } from "../../../../../helpers";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
  "onUpdateItemSettings",
  "onUpdateItemBillet",
  "onUpdateItemPix",
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_bank_account_listed = computed(() =>
  bankAccountStore.getters.getListed()
);

const $_bank_account_is_listing = computed(() =>
  bankAccountStore.getters.getIsListing()
);

const isMobile = ref(false);

//#region Methods
function checkIfMobile() {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
}
/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Abrir modal configurações
 * @param {number} id
 */
function onUpdateItemSettings(id) {
  emit("onUpdateItemSettings", id);
}

/**
 * Abrir modal boleto
 * @param {number} id
 */
function onUpdateItemBillet(id) {
  emit("onUpdateItemBillet", id);
}

/**
 * Abrir modal pix
 * @param {number} id
 */
function onUpdateItemPix(id) {
  emit("onUpdateItemPix", id);
}

/**
 * Atualizar Status por ID
 * @param {BankAccountListType} bank_account
 */
async function updateStatus(bank_account) {
  await bankAccountStore.actions.updateStatus(
    +!bank_account.status,
    bank_account.id
  );
}
//#endregion

onMounted(() => {
  checkIfMobile();
  window.addEventListener("resize", checkIfMobile);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", checkIfMobile);
});
</script>

<style lang="scss">
.app-switch-reset .badge {
  border-radius: 5px !important;
}
</style>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

/* TABLE */
/* Tirando boxshadow do card em volta da table */
.card-body .card {
  box-shadow: none !important;
  border: none;
}

.table {
  $default-table-text-color: #2b2d32;

  border-collapse: separate;
  border-spacing: 2px 2px;

  th,
  td {
    box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    color: $default-table-text-color;
    vertical-align: middle;
    border-radius: 5px !important;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media screen and (max-width: 768px) {
      min-width: max-content;

      .permission-mobile {
        min-width: 190px;
      }
    }
  }

  th {
    padding-left: 8px;
    padding-right: 8px;
    background: $muted-light;
    font-family: Fredoka !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  td {
    font-weight: 400 !important;
    font-family: Fredoka !important;
    font-size: 10px !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;

    &.p-16 {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }

  .table-text,
  #reset-name-button {
    font-size: 12px !important;
    padding-right: 1rem;
    box-shadow: none;

    &.break-word {
      display: inline-block;
      max-width: 50ch;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    }

    &.fs-14 {
      font-size: 14px !important;
    }
  }

  .active-badge {
    background-color: #f2f7f3;
    width: 100%;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 100px !important;
    min-width: 100px;
  }

  .table-icons {
    max-width: max-content;
  }

  .limit-text {
    display: inline-block;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/** Popovers */
.popover-title {
  font-family: Fredoka;
  font-size: 16px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  position: relative;
}

.popover-text {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 300;
}
.popover-title-border {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 100%;
  position: relative;
  border-color: #e8e8e8 !important;

  &.full {
    border-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      top: 50%;
      transform: translate(-12px, 50%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translate(12px, -50%);
      background-color: #e8e8e8;
    }
  }
}
</style>
