import { render, staticRenderFns } from "./_ModalOrderDeniedJustification.vue?vue&type=template&id=2630b422&scoped=true"
import script from "./_ModalOrderDeniedJustification.vue?vue&type=script&lang=js"
export * from "./_ModalOrderDeniedJustification.vue?vue&type=script&lang=js"
import style0 from "./_ModalOrderDeniedJustification.vue?vue&type=style&index=0&id=2630b422&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2630b422",
  null
  
)

export default component.exports