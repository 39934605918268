<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div v-if="$_item">
        <div class="row card-wrapper pr-4 pl-4" v-if="loading">
          <SkeletonPuzlFullWidth />
        </div>
        <div v-else class="row m-0">
          <div class="col-8 pl-2 pr-4 mb-4">
            <div class="border-left border-primary border-3 pl-2 ml-1 mb-2">
              <span
                >Programado : <b> {{ $_programmed_value | currency }} </b>
              </span>
            </div>
            <div class="border-left border-warning border-3 pl-2 ml-1 mb-2">
              <span
                >Saldo atual : <b> {{ $_history.total_balance || 0 | currency }} </b>
              </span>
            </div>
            <div class="border-left border-primary border-3 pl-2 ml-1">
              <span
                >Total : <b> {{ total_value | currency }} </b>
                <i
                  @click.prevent="$_item.credit = total_value.toFixed(2)"
                  style="font-size: 20px !important"
                  role="button"
                  class="fas fa-download pl-2 text-success"
                />
              </span>
            </div>
          </div>
          <div class="col-4 d-grid justify-content-end">
            <div class="row m-0 mt-1 justify-content-center">
              <input @change="applyAddition" v-model="with_addition" style="height:20px; width:20px;" type="checkbox" />
              <span class="ml-2">+ 10%</span>
            </div>
          </div>
        </div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="d-flex justify-content-betwen">
              <div class="col-9">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pl-2 pt-1 pb-0 col-form-label form-control-label"> Crédito </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        v-model.lazy="$_item.credit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pl-2 pb-0 col-form-label form-control-label"> Data limite </label>
                  <div class="col-md-8 proposal-credit-limit-date-picker">
                    <base-input input-group-classes="input-group-sm">
                      <validation-provider rules="required">
                        <input-date-time-picker
                          ref="datePicker"
                          :disableDate="min_date"
                          :disableMaxDate="max_date"
                          :disabled="loadingConcretingDate"
                          :mode="'date'"
                          v-model="$_item.due_date"
                        />
                      </validation-provider>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pl-2 pb-0 col-form-label form-control-label">
                    Observações
                    <span class="text-danger"></span>
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <textarea type="text" v-model="$_item.observation" rows="2" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-3 d-flex align-items-center ml-2">
                <div class="col-12">
                  <base-button
                    :disabled="!user_credit_config.credit || !$_item.id"
                    @click.prevent="store(false)"
                    size="sm"
                    type="danger"
                    outline
                  >
                    zerar crédito
                  </base-button>
                </div>
              </div>
            </div>
            <h4 class="mt-2 col-12 ml-2" v-if="!has_credit_limit">
              <small> Usuário não possui limite disponível para liberação de crédito! </small>
            </h4>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="!user_credit_config.credit || invalid || $_item.credit == '0,00'"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import { formatErrorValidation } from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { mapGetters } from "vuex";
import moment from "moment";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import entityFinancialStore from "../../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";

export default {
  components: { InputDateTimePicker, SkeletonPuzlFullWidth },
  name: "ModalCreditLimit",
  data() {
    return {
      modal: false,
      title: "Limite de crédito",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      min_date: null,
      max_date: false,
      is_juridical: false,
      user_credit_config: {
        credit: null,
        days: false,
      },
      has_credit_limit: true,
      entity_id: null,
      loading: false,
      total_value: 0,
      loadingConcretingDate:false,
      with_addition : false,
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
      $_item: "entityCredit/show",
      $_programmed_value: "entityCredit/getProgrammedValue",
    }),
    $_history() {
      return entityFinancialStore.getters.getItem();
    }
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(entity_id, is_juridical = true) {
      let loader = this.$loading.show();
      this.is_juridical = is_juridical;
      this.entity_id = entity_id;
      this.max_date = false;
      this.min_date = new Date(this.$helper.parseDate(moment(),'YYYY-MM-DD 00:00:00'));
      this.user_credit_config = {
        credit: null,
        days: false,
      };
      this.with_addition = false;
      this.loadingConcretingDate = false;

      this.$store.dispatch("entityCredit/show", entity_id).then(async (response) => {
        await this.normalize();
        this.getProgrammedValue();
        this.getLastConcretingDate();
        this.$store.dispatch("userCredit/getByUser", this.$_user.id).then((response) => {
          if (response.data) {
            this.user_credit_config.days = response.data.days;
            this.user_credit_config.credit = this.is_juridical ? response.data.juridical_value : response.data.physical_value;
            let max_date = this.getUserConfigMaxDate();
            if (this.$_item.due_date) {
              /* Se a data definida já for maior que o limite máximo, o limite máximo passará a ser a data definida para evitar conflitos */
              this.max_date = moment(this.$_item.due_date).isSameOrAfter(max_date) ? this.$_item.due_date : max_date;
            } else {
              /* Se crédito não estiver definido. */
              this.max_date = max_date;
            }
          } else {
            this.$notify({
              type: "danger",
              message: "Limite de crédito não definido!",
            });
            this.has_credit_limit = false;
          }
          this.max_date = new Date(this.max_date)
          this.min_date = new Date(this.min_date)
          this.modal = true;
          loader.hide();
        });
      });
    },
    async store(enabled = true) {
      const is_valid = await this.checkRules(enabled);
      if (!is_valid) return;
      let loader = this.$loading.show();
      this.$store
        .dispatch("entityCredit/add", this.finalFormat(enabled))
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updated");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => loader.hide());
    },

    /**
     * Formata o item para gravação
     *
     * @returns {Object}
     */
    finalFormat(enabled) {
      let item = this.$helper.cloneObject(this.$_item);
      item.credit = enabled ? this.$helper.moneyToFloat(item.credit) : 0;
      item.status = enabled;
      return item;
    },

    /**
     * Dispara verificações de validação para crédito e true para válido e false para inválido
     *
     * @returns {boolean}
     */
    checkRules(enabled) {
      switch (true) {
        case this.max_date && (new Date(this.$helper.parseDate(moment(this.$_item.due_date),'YYYY-MM-DD 00:00:00')) > this.getUserConfigMaxDate()):
          this.$notify({
            type: "danger",
            message: "A data selecionada excede o limite permitido!",
          });
          return false;
        case this.user_credit_config.credit &&
          parseFloat(this.$helper.moneyToFloat(this.$_item.credit)) > parseFloat(this.user_credit_config.credit):
          this.$notify({
            type: "danger",
            message: "O crédito informado excede o limite permitido!",
          });
          return false;
        case enabled === false && this.$_item.status === false:
          this.$notify({
            type: "danger",
            message: "O crédito atual encontra-se zerado!",
          });
          return false;
        default:
          return true;
      }
    },

    /**
     * Retorna a data máxima permitida para o usuário
     *
     * @returns {Date}
     */
    getUserConfigMaxDate() {
      return new Date(moment(this.$helper.parseDate(moment(),'YYYY-MM-DD 00:00:00')).add(this.user_credit_config.days, "days").format("YYYY-MM-DD 00:00:00"));
    },

    /**
     * Normaliza os dados para exibição
     *
     * @returns void
     */
    normalize() {
      if (this.$_item && this.$_item.id) {
        const due_date = new Date(this.$helper.parseDate(this.$_item.due_date, "YYYY-MM-DD 00:00:00"));
        if(moment().isAfter(due_date)) {
          this.$_item.due_date = null;
        } else {
          this.$_item.due_date = due_date;
        }
        return this.$_item.credit = this.$_item.status === false ? 0 : this.$_item.credit;
      }
      return this.$_item.entity_id = this.entity_id;
    },

    /**
     * Busca valor programado para cliente
     *
     * @returns void
     */
    getProgrammedValue() {
      this.loading = true;
      this.$store.dispatch("entityCredit/getProgrammedValue", this.entity_id).then((response) => {
        this.getTotalValue();
        this.loading = false;
      });
    },

    /**
     * Calcula valor total
     *
     * @returns void
     */
    getTotalValue() {
      if (this.$_history && this.$_history.total_balance) {
        const programmed_value = parseFloat(this.$_programmed_value);
        const balance = this.$_history.total_balance < 0 ?
          Math.abs(this.$_history.total_balance.toFixed(2)) :
          -Math.abs(this.$_history.total_balance.toFixed(2));
        const total_value = programmed_value + balance;
        this.total_value = total_value > 0 ? total_value : 0;
        if(this.$_item.credit && this.$_item.credit !== '0,00' && this.$_item.status) {
          const credit = this.$_item.credit.includes(",")  ? this.$helper.moneyToFloat(this.$_item.credit) : parseFloat(this.$_programmed_value);
          this.total_value += credit;
        }
      } else {
        this.total_value = this.$_programmed_value;
      }
    },

    /**
     * Busca data de concretagem de última programação
     *
     * @returns void
     */
    getLastConcretingDate() {
      if (!this.$_item.due_date) {
        this.loadingConcretingDate = true;
        this.$store.dispatch("schedule/getLastConcretingDateByEntity", this.entity_id).then((response) => {
          if(response.data) {
            this.$_item.due_date = response.data;
          }
          this.loadingConcretingDate = false;
        });
      }
    },

    applyAddition(){
      if(this.with_addition && this.total_value > 0) this.total_value += parseFloat(this.$_programmed_value) * 0.1;
      else this.getTotalValue();
    }
  },
};
</script>