import store from "../../../../shared/libraries/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
    BASE: "/configuration/company-plant-issuer/",
});

/** KEYS */
const keys = Object.freeze({
    ITEM: "BANK_ACCOUNT_ITEM",
    LISTED: "BANK_ACCOUNT_LISTED",
    IS_LISTING: "BANK_ACCOUNT_IS_LISTING",
    IS_FINDING: "BANK_ACCOUNT_IS_FINDING",
    IS_SAVING: "BANK_ACCOUNT_IS_SAVING",
    IS_REMOVING: "BANK_ACCOUNT_IS_REMOVING",
    PAGE_STATES: "BANK_ACCOUNT_PAGE_STATE",
    IS_LOADING_COMPANY_PLANT_ISSUER_OPTIONS: "BANK_ACCOUNT_IS_LOADING_COMPANY_PLANT_ISSUER_OPTIONS",
    COMPANY_PLANT_ISSUER_ITEMS: 'COMPANY_PLANT_ISSUER_ITEMS'
});

/** GETTERS */
const getters = {

    /** @returns {boolean} */
    getIsLoadingCompanyPlantIssuerOptions: () => store.getState(keys.IS_LOADING_COMPANY_PLANT_ISSUER_OPTIONS) ?? false,

    /** @returns {array}  */
    getCompanyPlantIssuerItems: () => store.getState(keys.COMPANY_PLANT_ISSUER_ITEMS) ?? [],
}

/** MUTATIONS */
const mutations = {

    /**
     * @param {boolean} isLoadingCompanyOptions
     * @returns {boolean}
     */
    setIsLoadingCompanyPlantIssuerOptions: (isLoadingCompanyOptions) => store.commit(keys.IS_LOADING_COMPANY_PLANT_ISSUER_OPTIONS, isLoadingCompanyOptions),

    /**
     * @param {array} CompanyOptionsItems
     * @returns {array}
     */
    setCompanyPlantIssuerOptions: (CompanyOptions) => store.commit(keys.COMPANY_PLANT_ISSUER_ITEMS, CompanyOptions),

    /** Resetar chaves de estado */
    resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {

    getListOptions: async () => {
        try {
            mutations.setIsLoadingCompanyPlantIssuerOptions(true);

            const res = await httpClient.get(urls.BASE + 'list-options');
            if (res.status !== HttpStatusEnum.HTTP_OK) {
                handleUnexpectedStatusCode(res);
                return;
            }

            const bankItems = res.data.data
            return mutations.setCompanyPlantIssuerOptions(bankItems)
        } finally {
            mutations.setIsLoadingCompanyPlantIssuerOptions(false)
        }
    }
}

export default {
    actions,
    getters,
    mutations,
};
