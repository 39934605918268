<template>
  <div>
    <modal size="md"
      :show.sync="isOpen"
      :backgroundColor="'#FEF9F2'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/create-new.png" alt="create-new" width="20px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>

      <AdjustmentLevelResponsibilityForm
        :isEdit="true"
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref } from "vue";
import { initAdjustmentLevelResponsibilityType, AdjustmentLevelResponsibilityType } from "../../types";
import adjustmentLevelResponsibilityStore from "../../store/adjustmentLevelResponsibilityStore";
import { dialogs, progress } from "../../../../../helpers";
import AdjustmentLevelResponsibilityForm from "../shared/AdjustmentLevelResponsibilityForm.vue";
import { adjustmentLevelResponsibilityCreateHistoryService } from "../../services";
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "EDITAR AJUSTE POR NÍVEL DE APLICAÇÃO";

/** @type {AdjustmentLevelResponsibilityType} */
const formData = reactive(initAdjustmentLevelResponsibilityType());

/** @type {AdjustmentLevelResponsibilityType} */
const beforeUpdateFormData = reactive(initAdjustmentLevelResponsibilityType());
//#endregion

//#region Computeds
/** Store Getters */
const $_adjustment_level_responsibility_is_finding = computed(() => adjustmentLevelResponsibilityStore.getters.getIsFinding());
//#endregion

//#region Methods
/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  adjustmentLevelResponsibilityStore.actions.find(id)
    .then((data) => {
      Object.assign(formData, data);
      Object.assign(beforeUpdateFormData, data);
    })
    .finally(() => progress.finish());
}

/** Atualizar registro */
async function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();

  try {
    /** @type {AdjustmentLevelResponsibilityType} adjustmentLevelResponsibility */
    
    await adjustmentLevelResponsibilityStore.actions.update(formData, formData.id)

    const adjustmentLevelResponsibility = await adjustmentLevelResponsibilityStore.actions.find(formData.id);
    const has_agent = await hasAgent(adjustmentLevelResponsibility.agent);

    // verifica se houve modificação das centrais
    if(checkIfChange(beforeUpdateFormData.company_plant_ids, adjustmentLevelResponsibility.company_plant_ids)) {
      adjustmentLevelResponsibilityCreateHistoryService.execute(
        AdjustmentHistoryActionsEnum.keys.EDIT,
        formData,
        has_agent ? agentGroups : null
      );
    }

  } finally {
      dialogs.notify();
      closeModal();
      progress.finish();
      loader.hide();
  }
}

/**
 * 
 * @param {Array<number>} oldCompanyPlants 
 * @param {Array<number>} newCompanyPlants 
 */
function checkIfChange(oldCompanyPlants, newCompanyPlants) {
  if (oldCompanyPlants.length !== newCompanyPlants.length) {
    return true;
  }
  
  for (let i = 0; i < oldCompanyPlants.length; i++) {
    if (oldCompanyPlants[i] !== newCompanyPlants[i]) {
      return true;
    }
  }
  
  return false;
}

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} AdjustmentLevelResponsibilityEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion

</script>

<style scoped>
</style>