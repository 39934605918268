import store from "../../../../shared/libraries/store";
import {
  initAdjustmentLevelResponsibilityListPagerType,
  AdjustmentLevelResponsibilityListFilterType,
  AdjustmentLevelResponsibilityListPagerType,
  AdjustmentLevelResponsibilityType,
  AdjustmentLevelResponsibilityUpdateDosageType,
  AdjustmentLevelResponsibilityListType,
} from "../types";
import {
  createSource,
  findSource,
  listSources,
  removeSource,
  updateSource,
} from "../../../../shared/store";
import { adjustmentLevelResponsibilityFormatEmptyAgentService } from "../services";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";

/** URLS */
const urls = Object.freeze({
  BASE: "/technology/adjustments-level-responsibility",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "ADJUSTMENT_LEVEL_RESPONSIBILITY_ITEM",
  LISTED: "ADJUSTMENT_LEVEL_RESPONSIBILITY_LISTED",
  IS_LISTING: "ADJUSTMENT_LEVEL_RESPONSIBILITY_IS_LISTING",
  IS_FINDING: "ADJUSTMENT_LEVEL_RESPONSIBILITY_IS_FINDING",
  IS_SAVING: "ADJUSTMENT_LEVEL_RESPONSIBILITY_IS_SAVING",
  IS_REMOVING: "ADJUSTMENT_LEVEL_RESPONSIBILITY_IS_REMOVING",
  PAGE_STATES: "ADJUSTMENT_LEVEL_RESPONSIBILITY_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?AdjustmentLevelResponsibilityType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {AdjustmentLevelResponsibilityListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initAdjustmentLevelResponsibilityListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: AdjustmentLevelResponsibilityListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {AdjustmentLevelResponsibilityType} item
   * @returns {AdjustmentLevelResponsibilityType}
   */
  setItem: (item) => {
    if(item.agent === null) {
      item.agent =
        adjustmentLevelResponsibilityFormatEmptyAgentService.execute();
    }
    return store.commit(keys.ITEM, item);
  },

  /**
   * @param {AdjustmentLevelResponsibilityListPagerType} listed
   * @returns {AdjustmentLevelResponsibilityListPagerType}
   */
  setListed: (listed) => {
    listed.items.forEach((item) => {
      if (item.agent === null) {
        item.agent =
          adjustmentLevelResponsibilityFormatEmptyAgentService.execute();
      }
    });
    store.commit(keys.LISTED, listed);
  },

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {AdjustmentLevelResponsibilityType} created
   * @returns {AdjustmentLevelResponsibilityType}
   */
  setNewItemInList: (created) => {
    created.agent =
      adjustmentLevelResponsibilityFormatEmptyAgentService.execute();

    return store.commitNewItemToList(keys, created);
  }
  ,

  /**
   * @param {AdjustmentLevelResponsibilityListPagerType} listed
   * @returns {AdjustmentLevelResponsibilityListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {AdjustmentLevelResponsibilityType} updated
   * @returns {AdjustmentLevelResponsibilityType}
   */
  setUpdatedItem: (updated) => {
    updated.agent =
      adjustmentLevelResponsibilityFormatEmptyAgentService.execute();
    return store.commitUpdatedItem(keys, updated)
  },

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: AdjustmentLevelResponsibilityListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {AdjustmentLevelResponsibilityType} data
   * @returns {Promise<AdjustmentLevelResponsibilityType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?AdjustmentLevelResponsibilityType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {AdjustmentLevelResponsibilityListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?AdjustmentLevelResponsibilityListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) =>
    await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {AdjustmentLevelResponsibilityType} data
   * @param {number} id
   * @returns {Promise<AdjustmentLevelResponsibilityType>}
   */
  update: async (data, id) =>
    await updateSource(data, id, mutations, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar valores de dosagem por ID
   *
   * @param {AdjustmentLevelResponsibilityUpdateDosageType} dosages
   * @param {number} id
   * @returns {Promise<?AdjustmentLevelResponsibilityType>}
   */
  updateDosages: async (dosages, id) => {
    try {
      mutations.setIsSaving(true);

      const res = await httpClient.put(
        urls.BASE + "/update-dosages/" + id,
        dosages
      );
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK:
          if (res.data.data.agent === null) {
            res.data.data.agent =
              adjustmentLevelResponsibilityFormatEmptyAgentService.execute();
          }

          mutations.setUpdatedItem(res.data.data);
          return res.data.data;
        default:
          handleUnexpectedStatusCode(res);
      }
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Retorna lista das centrais vinculadas
   * ao nível de aplicação por ID
   *
   * @param {AdjustmentLevelResponsibilityListType} adjustment
   */
  getCompanyPlantsById: async (adjustment) => {
    try {
      mutations.setIsFinding(true);

      const res = await httpClient.get(
        urls.BASE + "/company-plants-by-id/" + adjustment.id
      );

      switch (res.status) {
        case HttpStatusEnum.HTTP_OK:
          adjustment.company_plants = res.data.data;
          return mutations.setUpdatedItem(adjustment);
        default:
          handleUnexpectedStatusCode(res);
      }
    } finally {
      mutations.setIsFinding(false);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};
