<template>
  <div v-if="show" class="scrolling-box" style="margin-top: -2%">
    <transition name="slide-fade">
      <div v-show="show_slot" class="slide-fade-modal"
           style="overflow: hidden">
        <div style="width: 100%;background: rgb(96 114 129);height: 69px">
          <div class="row">
            <div @click.prevent="show = false" class="col-10 pl-5" style="margin-top: 24px">
              <h3 class="text-white pointer">
                <a href="#">
                  <img style="margin-right: 4px" ref="icon" height="22" :src="setIcon()" alt="receive"/>
                </a>
                {{ title }}
              </h3>
            </div>
            <div class="col-2 text-right"
                 style="
    margin-top: 24px;
"><i @click.prevent="close()"  class="pointer text-white fas fa-times mr-3" style="font-size: 20px;"></i></div>
          </div>
        </div>
        <div style="height: 100vh;bottom: 0px;overflow: hidden" class="p-2">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SlideFadeModal",
  props: {
    platform: {
      type: String,
    },
    size: {
      type: String,
    },
    title: {
      type: String,
    },
    icon: {
      type: String
    }
  },
  data() {
    return {
      show: false,
      show_slot: false,
    }
  },
  methods: {
    getSize() {
      switch (this.size) {
        default:
          return 'slide-modal-md'
          break;
      }
    },
    close() {
      this.show = false
      this.$emit('close')
    },
    toggleShowComponent() {
      this.show_slot = !this.show_slot
    },
    handleOpenModal() {
      this.show_slot = true
      this.show = true
    },
    setIcon() {
      if (this.platform === 'default') {
        return `/img/icons/${this.icon}.png`;
      }
      return `/img/icons/icons8/${this.platform}/${this.icon}.png`;
    }
  },
  mounted() {
    window.EventBus.$on('closeWindow', () => {
      this.show = false
      this.show_slot = false
    }, this)
  }
}
</script>

<style>

.slide-fade-modal {
  z-index: 10;
  position: fixed;
  top: 5px;
  width: 32vw;
  height: calc(100vh - 10px);
  max-height: 100%;
  right: 5px;
  background-color: white;
  border: 1px solid #606602;
  border-radius: 8px;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}

/*@media only screen and (min-height: 768px) {*/
/*  .slide-fade-modal {*/
/*    max-height: 100% !important;*/
/*  }*/
/*}*/

@media only screen and (max-width: 600px) {
  .slide-fade-modal{
    width: 93vw !important;
    max-height: 100% !important;
  }
}

/*@media only screen and (min-width: 800px) and (max-width: 1000px) {*/
/*  .slide-fade-modal {*/
/*    width: 45vw !important;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 600px) and (max-width: 800px) {*/
/*  .slide-fade-modal {*/
/*    width: 55vw !important;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 1000px) and (max-width: 1200px) {*/
/*  .slide-fade-modal {*/
/*    width: 38vw !important;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 1200px) and (max-width: 1400px) {*/
/*  .slide-fade-modal {*/
/*    width: 35vw !important;*/
/*  }*/
/*}*/
</style>
