<template>
  <div class="row card-wrapper">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in requests" :key="index">
      <div class="card main-card">
        <div class="p-3 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <div @click="showButtonsModal = true; handleShowModal({ ref: 'modalListItemsApproval', data: item })"
                class="align-self-center card-status mr-3 mb-1 pointer" :class="getStatusClass(item.status)">
                <img ref="icon" height="17" :src="getStatusIcon(item.status)" />
                {{ getStatusText(item.status) }}
              </div>
              <div style="margin-top: 3px;">
                <!-- ICONES -->
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div class="mr-2" @click="showButtonsModal = false; handleShowModal({ ref: 'modalListItemsApproval', data: item })">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                        </div>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <span style="font-size: 12px; font-weight: 400; color: #606062;">
                {{ item.input_company_plant_name }}
              </span>
            </div>
            <div class="d-flex">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">
                {{ item.code }}
              </span>
            </div>
            <div class="mt-1">
              <span style="font-size: 14px ;font-weight: 400; color: #606062;">{{ item.purchase_category_description }}</span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                PRAZO: {{ item.deadline | parseDate("DD MMM YYYY") }}
              </span>
            </div>
          </div>
          <!-- Menu engrenagem -->
          <div>
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div v-if="item.status != purchaseRequestEnum.CANCELED && item.status != purchaseRequestEnum.APPROVED">
                <a @click="redirectToEdit(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                  Editar
                </a>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
              </div>
              <a @click="handleShowModal({ ref: 'modalHistory', data: item })" class="dropdown-item d-flex"
                style="font-size: 12px;">
                <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                Histórico
              </a>
              <div @click="removePurchaseRequest(item)" v-if="item.status != purchaseRequestEnum.CANCELED && !item.has_active_budget">
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                  Cancelar
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval
      @addContact="listContacts"
      :showButtons="showButtonsModal"
      @fetch="fetchPurchaseRequests"
      ref="modalListItemsApproval"
      />
    <ModalAttachment @addContact="listContacts" ref="modalAttachment" />
    <ModalPurchaseRequestHistory ref="modalHistory" />
  </div>
</template>
<script>

import ModalListItemsApproval from "./Shared/_ModalListItemsApproval";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment";
import { PurchaseRequestEnum } from "@/enum/PurchaseRequestEnum";
import { mapGetters } from "vuex";
import ModalPurchaseRequestHistory from "./Shared/_ModalPurchaseRequestHistory.vue";

export default {
  name: "CardsViewConcretePrice",
  props: {
    requests: Array,
  },
  components: {
    ModalListItemsApproval,
    ModalAttachment,
    ModalPurchaseRequestHistory,
  },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  data() {
    return {
      filter: {
        order_by: [
          {
            column: 'purchase_request_attachments.id',
          }
        ],
        purchase_request_id: null,
      },
      purchaseRequestEnum: PurchaseRequestEnum,
      showButtonsModal: true,
    };
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    async handleShowAttachments(id) {
      this.filter.purchase_request_id = id;
      let loader = this.$loading.show();
      const attachments = await this.getAttachments();
      this.$refs.modalAttachment.openAttachment(attachments.data.items);
      loader.hide();
    },
    async getAttachments() {
      return await this.$store.dispatch('purchaseRequest/fetchRequestAttachments', this.filter);
    },
    redirectToEdit(item) {
      if (!item.can_approve) {
        this.$notify({type: 'warning', message: 'Voce nao possui permissão para editar requisição!'});
        return
      }
      this.$router.push('/purchase/request/edit/' + item.id)
    },
    getStatusText(status) {
      switch (status) {
        case this.purchaseRequestEnum.PENDING: return "Pendente";
        case this.purchaseRequestEnum.APPROVED: return "Aprovado";
        case this.purchaseRequestEnum.DENIED: return "Negado";
        default: return "Cancelado";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case this.purchaseRequestEnum.PENDING: return "pending-status";
        case this.purchaseRequestEnum.APPROVED: return "approved-status";
        case this.purchaseRequestEnum.DENIED: return "denied-status";
        default: return "denied-status";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case this.purchaseRequestEnum.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
        case this.purchaseRequestEnum.APPROVED: return "/img/icons/icons8/ios/thumb-up_primary.png";
        case this.purchaseRequestEnum.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
        default: return "/img/icons/icons8/ios/cancel_danger.png";
      }
    },
    removePurchaseRequest(purchaseRequest) {
      if (purchaseRequest.has_active_budget) {
        this.$notify({ type: 'warning', message: 'Já foi iniciada a cotação dessa RC!' });
        return
      }
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Tem certeza que deseja CANCELAR essa requisição?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            purchaseRequest.status = this.purchaseRequestEnum.CANCELED;
            purchaseRequest.updated_by_user_id = this.$_user.id;
            this.$store.dispatch('purchaseRequest/update', purchaseRequest)
              .then(() => {
                this.$notify({ type: 'success', message: 'Requisição cancelada!' });
                this.fetchPurchaseRequests();
              });
          } else {
            this.modal.create = false;
          }
        });
    },
    fetchPurchaseRequests() {
      this.$emit('fetch');
    }
  },
};
</script>

<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.3rem 0.8rem;
  margin-top: 0.1rem;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}
</style>
