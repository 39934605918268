const keys = Object.freeze({
  PRIMARY: "primary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  MUTED: "muted",
  DARK: "dark",
  WHITE: "white",
  GRAY: "gray",
  LINK: "link",
});

export default {
  keys,
};
