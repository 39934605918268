/**
 * Type para item da listagem de contas bancárias
 *
 * @typedef {Object} BankAccountType
 * @property {number} id
 * @property {string} name
 * @property {0 | 1} status - 1-ATIVO, 0-INATIVO
 * @property {number} bank_id
 * @property {string} number_without_digit_agency
 * @property {?string} digit_agency
 * @property {string} number_without_digit_account
 * @property {string} digit_account
 * @property {?string} account_type
 * @property {?string} observation
 * @property {?string} external_code
 * @property {?string} company_id
 * @property {?string} company_plant_issuer_id
 */
export const BankAccountType = {};


/**
 * Inicializar BankAccountType
 *
 * @returns {BankAccountType}
 */
export const initBankAccountType = () => {
  return {
    id: null,
    name: null,
    bank_id: null,
    number_without_digit_agency: "",
    digit_agency: null,
    number_without_digit_account: "",
    digit_account: "",
    account_type: null,
    observation: null,
    external_code: null,
    bank_name: "",
    code: "",
    company_id: "",
    company_plant_issuer_id: ""
  };
};