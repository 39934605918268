import store from "../../../../../../shared/libraries/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";
import { EntityFinancialGetByEntityFilterType } from "../types/EntityFinancialGetByEntityFilterType";
import { EntityFinancialType, initEntityFinancialType } from "../types/EntityFinancialType";

/** URLS */
const urls = Object.freeze({
  BASE: "/configuration/entity-financial",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "ENTITY_FINANCIAL_ITEM",
});

const getters = {
  /** @returns {EntityFinancialType} */
  getItem: function () {
    return store.getState(keys.ITEM) ?? initEntityFinancialType();
  },
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {EntityFinancialType} item
   * @returns {EntityFinancialType}
   */
  setItem: function (item) {
    return store.commit(keys.ITEM, item);
  },
};

/** ACTIONS */
const actions = {
  /**
   * @param {number} entityId - Cnpj emissor
   * @param {EntityFinancialGetByEntityFilterType} filter
   *
   * @returns {Promise<?EntityFinancialType>}
   */
  getByEntity: async function (entityId, filter) {
    const res = await httpClient.get(`${urls.BASE}/by-entity/${entityId}`, { params: filter });
    switch (res.status) {
      case HttpStatusEnum.HTTP_OK:
        mutations.setItem(res.data.data);
        return res.data.data;
      default:
        handleUnexpectedStatusCode(res);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};
