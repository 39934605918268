<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <img src="/img/icons/icons8/ios/filter.png" width="22" />
              <h4 class="new-default-black-font m-0 mx-3">Filtro</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Data
                <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <base-input input-group-classes="input-group-sm">
                <InputDatePicker
                  v-model="filter.date"
                  :defaultRange="defaultRange"
                  :isRange="false"
                  :filterable="3"
                  @input="setFilterDate"
                  size="sm"
                  ref="datePicker"
                />
              </base-input>
            </div>
          </div>
          <div class="row mt-3 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Central
                <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <base-input input-group-classes="input-group-sm">
                <PuzlSelect
                  v-model="filter.company_plant_id"
                  :items="$_company_plants_listed"
                  @input="setFilterPlant"
                />
              </base-input>
            </div>
          </div>
          <div class="row mt-2 mb-3 mt-4">
            <div class="col-12" style="display: flex; align-items: center">
              <img
                src="/img/icons/icons8/ios/info-squared_warning.png"
                width="22"
              />
              <h4 class="new-default-black-font m-0 mx-3">Lançamento</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Horário previsto
                <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <base-input input-group-classes="input-group-sm">
                <InputTimePicker
                  minuteInterval="1"
                  v-model="formData.time"
                />
              </base-input>
            </div>
          </div>
          <div class="row mt-3 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Motorista
                <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <base-input input-group-classes="input-group-sm">
                <PuzlSelect
                  v-model="formData.driver_id"
                  :items="$_drivers_listed"
                />
              </base-input>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <base-button
                @click="onCancelClick"
                type="danger"
                size="sm"
                style="width: 150px"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/cancel-white.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Cancelar </i>
                </div>
              </base-button>
              <base-button
                type="success"
                size="sm"
                style="width: 150px"
                @click="onSubmitClick"
                :disabled="invalid || !(filter.date && filter.company_plant_id && formData.time && formData.driver_id)"
                :loading="$_driver_scale_is_saving"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/save.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Salvar </i>
                </div>
              </base-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script setup>
//#region === Imports ===
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  getCurrentInstance,
} from "vue";
import { DriverScaleType } from "../types";
import { ValidationObserver } from "vee-validate";
import PuzlSelect from "../../../../../components/PuzlSelect";
import InputDatePicker from "../../../../../components/InputDatePicker";
import InputTimePicker from "../../../../../components/InputTimePickerV2";
import moment from "moment";
//#endregion

//#region === Data ===
const { proxy } = getCurrentInstance();
const defaultRange = ref(getNextBusinessDay());
const filter = ref({ date: getNextBusinessDay(), company_plant_id: null });
const datePicker= ref(null);
//#endregion

//#region === Props ===
/**
 * @typedef {Object} Props
 * @property {DriverScaleType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region === Emits ===
const emit = defineEmits(["onCancelClick", "onSubmitClick", "onfilterClick"]);
//#endregion

//#region === Computeds ===
/** Store Getters */
const $_driver_scale_is_saving = false;

const $_drivers_listed = computed(
  () => proxy.$store.getters["user/getDrivers"]
);

const $_company_plants_listed = computed(
  () => proxy.$store.getters["plant/activeShortItems"]
);
//#endregion

//#region === Methods ===
function getNextBusinessDay() {
  let nextDate = moment().add(1, "day");

  // Ignora finais de semana
  while (nextDate.isoWeekday() > 5) {
    nextDate.add(1, "day");
  }

  return nextDate.format("YYYY-MM-DD");
}

function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}

function setFilterDate() {
  emit("onfilterClick", filter.value);
}

function setFilterPlant() {
  emit("onfilterClick", filter.value);
}
//#endregion
</script>

<style scoped></style>
