import { computed, getCurrentInstance } from "vue";
import CmcAdjustmentLimitTypeEnum from "../../cmc-adjustment/enums/CmcAdjustmentLimitTypeEnum";
import { adjustmentFinalSlumpFormatEmptyAgentService } from "../services";
import { AdjustmentFinalSlumpListType } from "../types";

export function useAdjustmentFinalSlumpHook() {
  //#region Data
  const store = getCurrentInstance().proxy.$store;
  //#endregion

  //#region Computeds
  /**
   * Limites de Slump
   * @todo 11 - id representando o limite de dosagens para tela Final Slump
   * será feita uma refatoração no cadastro de limites para todas as telas
   * do Puzl Adjust e criação de um Enum para aplicar em todos os Hooks
   * desta tela (Puzl Adjusts).
   */
  store.dispatch("adjustmentLimitSetting/getByType", 11);
  const allLimits = computed(
    () => store.getters["adjustmentLimitSetting/show"]
  );

  /**
   * Grupos de Aditivos
   * @todo 4 - id representando o tipo de aditivo para tela de Final Slump
   * após refatoração de todas as telas de Puzl Adjust será feito um Enum
   * contendo todas as possibilidades de aditivos para melhor clareza.
   */
  store.dispatch("cmcGroup/fetchGroupByCategory", 4);
  const agentGroups = computed(
    () => store.getters["cmcGroup/showGroupByCategory"]
  );

  /**
   * Centrais
   */
  store.dispatch("plant/getPlantsSimplified");
  const companyPlants = computed(() => store.getters["plant/activeItems"]);

  /**
   * Slumps
   */
  store.dispatch("mixSlumpFlow/fetchItems");
  const slumpFlows = computed(() => store.getters["mixSlumpFlow/activeItems"]);

  //#endregion

  //#region Methods
  /**
   * Validação dos valores de ajuste
   * @param {number} limit_type
   * @param {number} value
   */
  function validateValueFromLimit(limit_type, value) {
    if (limit_type === CmcAdjustmentLimitTypeEnum.keys.BINDER) {
      if (value < parseInt(allLimits.value.binder.bottom)) {
        return parseInt(allLimits.value.binder.bottom);
      } else if (value > parseInt(allLimits.value.binder.top)) {
        return parseInt(allLimits.value.binder.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.WATER) {
      if (value < parseInt(allLimits.value.water.bottom)) {
        return parseInt(allLimits.value.water.bottom);
      } else if (value > parseInt(allLimits.value.water.top)) {
        return parseInt(allLimits.value.water.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR) {
      if (value < parseFloat(allLimits.value.content_mortar.bottom)) {
        return parseFloat(allLimits.value.content_mortar.bottom);
      } else if (value > parseFloat(allLimits.value.content_mortar.top)) {
        return parseFloat(allLimits.value.content_mortar.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR) {
      if (value < parseFloat(allLimits.value.total_air.bottom)) {
        return parseFloat(allLimits.value.total_air.bottom);
      } else if (value > parseFloat(allLimits.value.total_air.top)) {
        return parseFloat(allLimits.value.total_air.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.AGENT) {
      if (value < parseFloat(allLimits.value.agent.bottom)) {
        return parseFloat(allLimits.value.agent.bottom);
      } else if (value > parseFloat(allLimits.value.agent.top)) {
        return parseFloat(allLimits.value.agent.top);
      } else {
        return value;
      }
    }
  }

  /**
   *
   * @param {AdjustmentFinalSlumpListType} dosages
   */
  function resetAdjustmentValues(dosages) {
    dosages.binder = 0;
    dosages.water = 0;
    dosages.content_mortar = 0;
    dosages.total_air = 0;
    dosages.agent = adjustmentFinalSlumpFormatEmptyAgentService.execute();
    dosages.observation = null;
    dosages.is_adjusting = true;
  }

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toNegative(limit_type, value) {
    return validateValueFromLimit(limit_type, -Math.abs(value));
  }

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toPositive(limit_type, value) {
    return validateValueFromLimit(limit_type, Math.abs(value));
  }
  //#endregion

  return {
    allLimits,
    agentGroups,
    validateValueFromLimit,
    resetAdjustmentValues,
    toPositive,
    toNegative,
    companyPlants,
    slumpFlows
  };
}
