const keys = Object.freeze({
  DEBIT: 0,
  CREDIT: 1,
  REVERSAL: 2,
  REVERSAL_SUCESS: 3
});

/**
 * 
 * @param {number} value 
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.DEBIT:
      return "Débito";
    case keys.CREDIT:
      return "Crédito";
    case keys.REVERSAL:
      return "Estorno";
    case keys.REVERSAL_SUCESS:
      return "Estorno";
    default:
      return "";
  }
};

/**
 * 
 * @param {number} value 
 * @returns string
 */
const getColor = (value) => {
  switch (value) {
    case keys.DEBIT:
      return "danger";
    case keys.CREDIT:
      return "success";
    case keys.REVERSAL:
      return "danger";
    case keys.REVERSAL_SUCESS:
      return "success";
    default:
      return "";
  }
}

export default {
  keys,
  getTitle,
  getColor
};
