import AdjustmentFinalSlumpListPage from "../views/list/AdjustmentFinalSlumpListPage.vue";

export default [
  {
    path: "/technology/adjustment/final-slumps",
    name: "technology.adjustment.final-slump.index.view",
    component: AdjustmentFinalSlumpListPage,
    meta: {
      title: "Tecnologia",
      description: "Ajustes - Slump final",
      icon: "fa fa-home",
      permission: "puzl_adjust.final-slump-permissions.index.view",
    },
  },
];
