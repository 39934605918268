<template>
  <validation-observer v-slot="{ invalid, handleSubmit }">
    <form
      class="needs-validation"
      @submit.prevent="handleSubmit(onSubmitClick)"
      autocomplete="off"
    >
      <!-- IDENTIFICAÇÃO -->
      <div class="modal-body-title col-12 mt-2">
        <AppIcon icon="info-squared" color="warning" width="22" />
        <h5>INFORMAÇÃO</h5>
        <div class="hr"></div>
      </div>

      <!-- NOME -->
      <div class="app-form-group">
        <label for="name" class="col-md-5"> NOME DA CONTA </label>
        <div class="wrapper col-md-7 flex-wrap">
          <validation-provider :rules="'required'" v-slot="{ errors }">
            <input
              id="name"
              class="input"
              type="text"
              v-model="formData.name"
              :class="errors[0] ? 'is-invalid' : 'is-valid'"
            />
          </validation-provider>
        </div>
      </div>

      <!-- Código externo -->
      <div class="app-form-group">
        <label for="external-code" class="col-md-5"> CÓDIGO EXTERNO </label>
        <div class="wrapper col-md-7 flex-wrap">
          <input
            id="external-code"
            class="input"
            type="text"
            v-model="formData.external_code"
          />
        </div>
      </div>

      <!-- EMPRESA -->
      <div class="app-form-group">
        <label for="bank" class="col-md-5"> Empresa </label>
        <div class="wrapper col-md-7 flex-wrap">
          <puzl-select
            v-model="formData.company_id"
            :items="$_companies"
            :loading="$_loading_companies"
            :disabled="$_loading_companies"
          />
        </div>
      </div>

      <!-- FILIAL -->
      <div class="app-form-group">
        <label for="bank" class="col-md-5"> Filial </label>
        <div class="wrapper col-md-7 flex-wrap">
          <puzl-select
            v-model="formData.company_plant_issuer_id"
            :items="$_company_issuer_plant"
            :labelMask="true"
            label="$business_name$"
            :loading="$_loading_company_issuer_plant"
            :disabled="$_loading_company_issuer_plant"
          />
        </div>
      </div>

      <!-- DADOS DO BANCO -->
      <div class="modal-body-title col-12 mt-2">
        <AppIcon icon="merchant-account" width="22" />
        <h5>DADOS DO BANCO</h5>
        <div class="hr"></div>
      </div>

      <!-- BANCO -->
      <div class="app-form-group">
        <label for="bank" class="col-md-5"> Banco </label>
        <div class="wrapper col-md-7 flex-wrap">
          <validation-provider rules="required" v-slot="{ errors }">
            <puzl-select
              v-model="formData.bank_id"
              :items="$_banks"
              :labelMask="true"
              label="$code$ - $name$"
              :loading="$_loading_banks"
              :disabled="$_loading_banks"
              :class="errors[0] ? 'is-invalid' : 'is-valid'"
            />
          </validation-provider>
        </div>
      </div>

      <!-- AGENCIA -->
      <div class="app-form-group">
        <label for="agency" class="col-md-5"> Agência </label>
        <div class="wrapper col-md-7 flex-wrap">
          <validation-provider rules="required" v-slot="{ errors }">
            <input
              id="agency"
              class="input"
              type="text"
              v-model="formData.number_without_digit_agency"
              :class="errors[0] ? 'is-invalid' : 'is-valid'"
            />
          </validation-provider>
        </div>
      </div>

      <!-- DIGITO AGENCIA -->
      <div class="app-form-group">
        <label for="external-code" class="col-md-5"> DÍGITO </label>
        <div class="wrapper col-md-7 flex-wrap">
          <input
            id="external-code"
            class="input"
            type="text"
            v-model="formData.digit_agency"
          />
        </div>
      </div>

      <!-- DADOS DA CONTA -->
      <div class="modal-body-title col-12 mt-2">
        <AppIcon icon="keypad" width="22" color="primary" />
        <h5>DADOS DA CONTA</h5>
        <div class="hr"></div>
      </div>

      <!-- CONTA -->
      <div class="app-form-group">
        <label for="account" class="col-md-5"> NÚMERO SEM DÍGITO </label>
        <div class="wrapper col-md-7 flex-wrap">
          <validation-provider rules="required" v-slot="{ errors }">
            <input
              id="account"
              class="input"
              type="text"
              v-model="formData.number_without_digit_account"
              :class="errors[0] ? 'is-invalid' : 'is-valid'"
            />
          </validation-provider>
        </div>
      </div>

      <!-- DIGITO AGENCIA -->
      <div class="app-form-group">
        <label for="account-digit" class="col-md-5"> DÍGITO </label>
        <div class="wrapper col-md-7 flex-wrap">
          <validation-provider rules="required" v-slot="{ errors }">
            <input
              id="account-digit"
              class="input"
              type="text"
              v-model="formData.digit_account"
              :class="errors[0] ? 'is-invalid' : 'is-valid'"
            />
          </validation-provider>
        </div>
      </div>

      <!-- TIPO DE CONTA -->
      <div class="app-form-group">
        <label for="account-type" class="col-md-5"> TIPO DE CONTA </label>
        <div class="wrapper col-md-7 flex-wrap">
          <input
            id="account-type"
            class="input"
            type="text"
            v-model="formData.account_type"
          />
        </div>
      </div>

      <!-- OBSERVAÇÕES -->
      <div class="app-form-group">
        <label for="account-type" class="col-md-5"> OBSERVAÇÕES </label>
        <div class="wrapper col-md-7 flex-wrap">
          <input
            id="account-type"
            class="input"
            type="text"
            v-model="formData.observation"
          />
        </div>
      </div>

      <div class="modal-footer">
        <base-button
          type="secondary"
          @click="onCancelClick"
          class="button danger"
        >
          <AppIcon icon="cancel" color="danger" width="18" />
          Cancelar
        </base-button>
        <base-button
          class="button success"
          type="success"
          native-type="submit"
          v-bind:disabled="invalid"
          :loading="$_bank_account_is_saving"
        >
          <AppIcon icon="save" color="success" width="18" />
          Salvar
        </base-button>
      </div>
    </form>
  </validation-observer>
</template>

<script setup>
import { computed } from "vue";
import { AppIcon } from "../../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";

import { BankAccountType } from "../../types";
import bankAccountStore from "../../store/bankAccountStore";
import companyStore from "../../../company/store/companyStore";
import companyPlantIssuerStore from "../../../company-plant-issuer/store/companyPlantIssuerStore";

//#region Props
/**
 * @typedef {Object} Props
 * @property {BankAccountType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
const emit = defineEmits(["onCancelClick", "onSubmitClick"]);
//#endregion

//#region Computeds
/** Store Getters */
const $_banks = computed(() => bankAccountStore.getters.getBankItems());
const $_loading_banks = computed(() =>
  bankAccountStore.getters.getIsLoadingBanks()
);

const $_companies = computed(() => companyStore.getters.getCompanyItems());
const $_loading_companies = computed(() =>
  companyStore.getters.getIsLoadingCompanyOptions()
);

const $_company_issuer_plant = computed(() =>
  companyPlantIssuerStore.getters.getCompanyPlantIssuerItems()
);
const $_loading_company_issuer_plant = computed(() =>
  companyPlantIssuerStore.getters.getIsLoadingCompanyPlantIssuerOptions()
);
const $_bank_account_is_saving = computed(() => bankAccountStore.getters.getIsSaving());
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style lang="scss" scoped></style>
