import { PagerType, initPagerType } from "../../../../shared/types";
import { AgentType } from "../../cmc-adjustment/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} AdjustmentLevelResponsibilityListType
 * @property {number} id
 * @property {boolean} has_adjustment
 * @property {number} level_feature
 * @property {?number} binder
 * @property {?number} water
 * @property {?number} content_mortar
 * @property {?number} total_air
 * @property {?Array<AgentType>} agent
 * @property {?string} observation
 * @property {?Array<string>} company_plants
 * @property {string} created_by_user_name
 * @property {string} created_at
 */
export const AdjustmentLevelResponsibilityListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<AdjustmentLevelResponsibilityListType> } )} AdjustmentLevelResponsibilityListPagerType
 */
export const AdjustmentLevelResponsibilityListPagerType = {};

/**
 * Inicializar AdjustmentLevelResponsibilityListPagerType
 * 
 * @returns {AdjustmentLevelResponsibilityListPagerType}
 */
export const initAdjustmentLevelResponsibilityListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};