/*jshint esversion: 9 */
const globalFilter = {
  watch: {
    '$filterable'() {
      if (this.hasOwnProperty('init')) {
        this.init({})
      } else if(this.hasOwnProperty('fetch')) {
        this.fetch({})
      }
    }
  },
}
export default globalFilter
