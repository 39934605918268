<template>
  <div v-if="$_companyPlantIssuer">
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062">
            <img height="30px" src="/img/icons/icons8/ios/settings-3--v1_gray.png" class="mr-3"/>
            Configuração de CNPJ
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <router-link :to="{ path: '/configuration/plant/document/issuer-document' }">
                <base-button block size="md" type="light" class="pb-2 pt-2 text-uppercase">
                  <img width="19" src="/img/icons/circled-left.png" class="mr-1"/>
                  Voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4" />
    </base-header>

    <div class="container-fluid">
      <card>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <!-- CARD - IDENTIFICAÇÃO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                        Identificação
                      </h5>
                    </div>
                    <div class="col-2">
                      <base-dropdown class="float-right">
                        <button
                          style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;"
                          slot="title-container"
                          class="btn dropdown-toggle text-uppercase colorize-btn-img"
                          type="button"
                          :class="$_companyPlantIssuer.status ? 'btn-outline-success' : 'btn-outline-danger'"
                        >
                          <img class="mr-1" v-if="$_companyPlantIssuer.status" src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                          <img class="mr-1" v-else src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                          {{ $_companyPlantIssuer.status ? 'ATIVO' : 'INATIVO' }}
                        </button>
                        <a
                          @click.prevent="$_companyPlantIssuer.status = !$_companyPlantIssuer.status"
                          class="dropdown-item text-uppercase new-default-black-font"
                          style="font-size: 12px;"
                        >
                          {{ $_companyPlantIssuer.status ? 'INATIVO' : 'ATIVO' }}
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Empresa
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            aria-describedby="button-addon4"
                            disabled
                            v-model="$_companyPlantIssuer.company_plant_issuer_group_name"
                          />
                          <div class="input-group-append" id="button-addon4">
                            <button
                              class="btn btn-outline-primary btn-sm form-control-sm"
                              type="button"
                              @click="editIssuerGroup"
                            >
                              Editar
                            </button>
                          </div>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CNPJ
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required|min:18|document">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            aria-describedby="button-addon4"
                            v-model="$_companyPlantIssuer.ein"
                            v-mask="['##.###.###/####-##']"
                          />

                          <div class="input-group-append" id="button-addon4">
                            <button
                              :disabled="!$_companyPlantIssuer.ein || ($_companyPlantIssuer.ein && $_companyPlantIssuer.ein.length < 18)"
                              class="btn btn-outline-primary btn-sm form-control-sm"
                              type="button"
                              @click="getRecipeNet"
                            >
                              Pesquisar
                            </button>
                          </div>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Nome da Filial
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            @input="$_companyPlantIssuer.subsidiary_name = $_companyPlantIssuer.subsidiary_name.toUpperCase()"
                            v-model="$_companyPlantIssuer.subsidiary_name"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label" :disabled="true">
                        Nome Empresarial
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                        <textarea
                          class="form-control form-control-sm"
                          style="white-space: normal; word-wrap: break-word;"
                          disabled
                          v-model="companyPlantIssuerGroupName"
                        ></textarea>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        E-mail
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.email"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Recebimento de E-mail
                        <el-popover
                          style="margin-top: 30px !important;"
                          trigger="click"
                          placement="top"
                          class="p-0 px-1"
                        >
                          Ao selecionar essa opção, o e-mail <br>
                          será copiado em todos os disparos<br>
                          aos clientes de faturas e NFS-E.
                          <span slot="reference"><img src="/img/icons/info.png" width="20px" height="20px"/></span>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.send_auto_mail"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Telefone
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.phone"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        WHATSAPP API PHONE
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.whatsapp_api_phone"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Regime Tributário
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input autocomplete="off">
                          <puzl-select
                            v-model="$_companyPlantIssuer.tax_regime"
                            :items="taxRegimes"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Incrição Municipal
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.municipal_registration"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Incrição Estadual
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.state_registration"
                            @input="$event.target.value = $_companyPlantIssuer.state_registration = $event.target.value.replace(/\./g, '')"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código Totvs
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.totvs_code"
                          />
                        </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código SAP
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.sap_code"
                            :disabled="loadingExternalCode"
                            @blur="setSapExternalCode"
                          />
                        </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código Externo
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.external_code"
                          />
                        </base-input>
                    </div>
                  </div>
                </div>

                <!-- CARD - ENDEREÇO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-3"/>
                        Endereço
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CEP
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.postal_code"
                          v-mask="['#####-###']"
                          v-on:blur="getAddressIssuerByCode"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        UF
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-3" autocomplete="off">
                        <puzl-select
                          v-model="$_companyPlantIssuer.state"
                          :items="states"
                          customKey="letter"
                          :labelMask="true"
                          label="$letter$"
                          @change="getCities()"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Município
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input autocomplete="off">
                          <puzl-select
                            v-model="$_companyPlantIssuer.city"
                            :items="cities"
                            customKey="title"
                            :labelMask="true"
                            label="$title$"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Endereço
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.address"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Número
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.number"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Bairro
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.district"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Complemento
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.complement"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <!-- CARD - CERTIFICADO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/grand-master-key_success.png" class="mr-3"/>
                        Certificado Digital
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Certificado
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                      <input
                        type="file"
                        class="form-control-uniform"
                        multiple=""
                        v-on:change="handleFileUpload()"
                        id="file"
                        ref="file"
                        accept=".pfx, .p12"
                        data-fouc
                      />
                      <small id="emailHelp2" class="form-text text-muted">
                        Arquivos em formato:<span class="badge mr-1 ml-1" style="background-color: #d3d3d3">PFX</span>
                        <span class="badge" style="background-color: #d3d3d3">P12</span>
                      </small>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Senha
                        <span v-show="certificate_file" class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider :rules="certificate_file && 'required'">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            aria-describedby="button-addon4"
                            v-model="certificate_password"
                            autocomplete="off"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <!-- CARD - SEFAZ -->
                <SkeletonPuzlFullWidth v-if="loadingEntryLaunchConfiguration" />
                <div v-else class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/login-rounded.png" class="mr-3" />
                        Sefaz
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Tipo de api
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <PuzlSelect @input="entryLaunchConfigurationButtonSave = true" v-model="$_entryLaunchConfiguration.type" :items="entryLaunchConfigurationTypes" />
                    </div>
                  </div>
                  <div v-if="entryLaunchConfigurationKeys.EFIT === $_entryLaunchConfiguration.type" class="row mt-2 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Url
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <input
                        @change="entryLaunchConfigurationButtonSave = true"
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_entryLaunchConfiguration.url"
                        maxlength="255"
                      />
                    </div>
                  </div>

                  <!-- Salvar --> 
                  <div v-if="entryLaunchConfigurationButtonSave" class="row mt-2 d-flex justify-content-end m-0"> 
                    <div @click.prevent="saveEntryLaunchConfiguration" class="d-flex align-items-center  button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
                      <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
                      <span style="font-weight: 500"> Salvar </span>
                    </div>
                  </div>
                </div>

                <!-- CARD - EMISSAO DE BOLETOS -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/barcode_warning.png" class="mr-3"/>
                        Emissão de Boletos
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Token de Emissão
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.bank_billet_api_token"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>

                <!-- CARD - NFS-E -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/nfe_blue.png" class="mr-3"/>
                        NFS-E
                      </h5>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                      <button
                        style="margin: 0;width: 140px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                        class="btn btn-outline-primary dropdown-toggle text-uppercase colorize-btn-img"
                        @click.prevent="openNfsePage()"
                      >
                        <img class="mr-1" src="/img/icons/icons8/ios/settings--v1_primary.png" width="16px">
                        Configurar
                      </button>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        ENOTAS
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="nfseProviderEnotas"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        MIGRATE
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="nfseProviderMigrate"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                </div>

                <!-- CARD - NF-E (DANFE)-->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/nfe.png" class="mr-3"/>
                        NF-E (DANFE)
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código de Benefício Fiscal
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        maxlength="8"
                        v-model="$_companyPlantIssuer.tax_benefit_code"
                      />
                    </div>
                  </div>
                </div>

                <!-- CARD - Configuração de E-mail -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="25px" src="/img/icons/icons8/ios/new-post--v1_primary.png" class="mr-2" />
                        Configuração de E-mail
                      </h5>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                      <button
                        v-if="$_companyPlantIssuer.custom_email_trigger"
                        style="margin: 0;width: 140px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                        class="btn btn-outline-primary dropdown-toggle text-uppercase colorize-btn-img"
                        @click.prevent="openEmailParam()"
                      >
                        <img class="mr-1" src="/img/icons/icons8/ios/settings--v1_primary.png" width="16px">
                        Configurar
                      </button>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Padrão Puzl
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          :value="!$_companyPlantIssuer.custom_email_trigger"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                          @input="toggleCustomEmailTrigger()"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Personalizado
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          :value="$_companyPlantIssuer.custom_email_trigger"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                          @input="toggleCustomEmailTrigger()"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="row align-items-center">
              <div class="offset-md-4 col-md-8 mt-n1">
                <div class="row d-flex justify-content-end show-md">
                  <div class="col-md-3 px-1">
                    <base-button
                      block
                      type="danger"
                      @click.prevent="close()"
                      size="md"
                      style="border-radius: 4px !important;"
                    >
                      <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="19px">
                      CANCELAR
                    </base-button>
                  </div>
                  <div class="col-md-3 px-1">
                    <base-button
                      native-type="submit"
                      v-bind:disabled="invalid"
                      :loading="isLoading"
                      block
                      type="success"
                      size="md"
                      style="border-radius: 4px !important;"
                    >
                      <img class="mr-2" src="/img/icons/save.png" width="19px">
                      SALVAR
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
    <ModalEmailParam ref="modalEmailParam"/>
    <ModalIssuerGroup ref="modalIssuerGroup" @submitted="modalIssuerGroupSubmitted" />
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";
import {formatErrorValidation} from "@/plugins";
import ModalEmailParam from "./_ModalEmailParam";
import ModalIssuerGroup from "./_ModalIssuerGroup.vue";
// Sap
import sapIntegrationStore from "../../../../../../modules/sap/sap-integration/store";
import { hasSap } from "../../../../../../modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "../../../../../../modules/sap/sap-integration/types";
import { SapTypeEnum } from "../../../../../../modules/sap/shared/enums/SapTypeEnum";
import EntryLaunchItegrationTypeEnum from "../../../../../../modules/configuration/entry-launch/aggregates/entry-launch-configuration/enums/EntryLaunchConfigurationTypeEnum";
import { getTitles } from "../../../../../../helpers";
import SkeletonPuzlFullWidth from "../../../../../../components/SkeletonPuzlFullWidth";
import entryLaunchConfigurationStore from "../../../../../../modules/configuration/entry-launch/aggregates/entry-launch-configuration/store/entryLaunchConfigurationStore"
import { reactive } from 'vue';

export default {
  name: "EditIssuerDocument",
  components: {
    PuzlSelect,
    ModalEmailParam,
    ModalIssuerGroup,
    SkeletonPuzlFullWidth,
  },
  computed: {
    ...mapGetters({
      $_companyPlantIssuer: "companyPlantIssuer/show",
    }),
    nfseProviderEnotas: {
      get() {
        return this.$_companyPlantIssuer.nfse_provider === 0;
      },
      set(newValue) {
        this.$_companyPlantIssuer.nfse_provider = newValue ? 0 : 1;
      }
    },
    nfseProviderMigrate: {
      get() {
        return this.$_companyPlantIssuer.nfse_provider === 1;
      },
      set(newValue) {
        this.$_companyPlantIssuer.nfse_provider = newValue ? 1 : 0;
      }
    },
    companyPlantIssuerGroupName() {
      const { company_plant_issuer_group_name, subsidiary_name } = this.$_companyPlantIssuer;
      if (company_plant_issuer_group_name && subsidiary_name) {
        return `${company_plant_issuer_group_name} | ${subsidiary_name}`;
      }
      if (company_plant_issuer_group_name) {
        return company_plant_issuer_group_name;
      }
      if (subsidiary_name) {
        return subsidiary_name;
      }
      return '';
    },
    $_entryLaunchConfiguration() { 
      return reactive(entryLaunchConfigurationStore.getters.getItem());
    },
  },
  data(){
    return {
      certificate_password: null,
      certificate_file: null,
      taxRegimes: [
        {id: 'Lucro Real', name: 'Lucro Real'},
        {id: 'Lucro Presumido', name: 'Lucro Presumido'},
        {id: 'Simples Nacional', name: 'Simples Nacional'},
        {id: 'Mei', name: 'Mei'},
      ],
      states: [],
      cities: [],
      isLoading: false,
      hasSap: hasSap(),
      SapTypeEnum: SapTypeEnum,
      loadingExternalCode: false,
      entryLaunchConfigurationTypes : getTitles(EntryLaunchItegrationTypeEnum),
      entryLaunchConfigurationKeys: EntryLaunchItegrationTypeEnum.keys,
      loadingEntryLaunchConfiguration: true,
      entryLaunchConfigurationButtonSave:false,
      entryLaunchConfigurationStore,
    }
  },
  mounted() {
    this.init();
    this.getEntryLaunchConfiguration(this.$route.params.id);
  },
  methods: {
    init() {
      this.getStates();

      // Permitir retornar sem perder dados do estado
      if (this.$route.query['changing'] === 'true') {
        return;
      }
      // Obter dados para edição
      this.getCompanyPlantIssuer();
    },
    getCompanyPlantIssuer() {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("companyPlantIssuer/show", this.$route.params.id)
        .then(({error_type, message}) => {
          this.$notify({type: error_type, message});
          this.getSapExternalCode();
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    getStates() {
      this.$Progress.start();
      this.$axios
        .get("/state")
        .then(response => {
          this.states = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getCities() {
      this.$Progress.start();
      this.$axios
        .post("/city", {uf: this.$_companyPlantIssuer.state})
        .then(response => {
          this.cities = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/ein", this.$_companyPlantIssuer)
        .then(response => {
          this.$_companyPlantIssuer.ein = response.data.data.cnpj || "";
          this.$_companyPlantIssuer.alias_name = response.data.data.fantasia || "";
          this.$_companyPlantIssuer.business_name = response.data.data.nome || "";
          this.$_companyPlantIssuer.group_name = response.data.data.nome || "";
          this.$_companyPlantIssuer.email = response.data.data.email || "";
          this.$_companyPlantIssuer.phone = response.data.data.telefone || "";
          this.$_companyPlantIssuer.number = response.data.data.numero || '';
          this.$_companyPlantIssuer.registration_situation = response.data.data.situacao || "";
          this.$_companyPlantIssuer.postal_code = response.data.data.cep.replace(/[^\d]+/g, "") || "";
          this.getAddressIssuerByCode();
          this.$notify({type: "success", message: "Dados da empresa carregado com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$notify({type: "warning", message: "Não foi encontrado empresas com este documento."});
          this.$Progress.finish();
        });
    },
    getAddressIssuerByCode() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/address", {postal_code: this.$_companyPlantIssuer.postal_code})
        .then(response => {
          this.$_companyPlantIssuer.state = response.data.data.uf;
          this.getCities(this.$_companyPlantIssuer.state);
          this.$_companyPlantIssuer.address = response.data.data.logradouro;
          this.$_companyPlantIssuer.state = response.data.data.uf;
          this.$_companyPlantIssuer.district = response.data.data.bairro;
          this.$_companyPlantIssuer.city = response.data.data.localidade;
          this.$_companyPlantIssuer.ibge_code = response.data.data.ibge;
          const needToSearchForIbgecode = !this.$_companyPlantIssuer.ibge_code || (this.$_companyPlantIssuer.ibge_code === 'undefined')
            && this.$_companyPlantIssuer.city && this.$_companyPlantIssuer.state;
          if (needToSearchForIbgecode) {
            this.checkIbgeCode();
          }
          this.$notify({type: "success", message: "Dados carregados com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    /**
     * Verificar se ibge_code foi preenchido.
     * Caso não esteja preenchido, tenta localizar ibgecode a partir de this.$_companyPlantIssuer.state e this.$_companyPlantIssuer.city
     */
    async checkIbgeCode() {
      if (this.$_companyPlantIssuer.state && this.$_companyPlantIssuer.city) {
        let loader = this.$loading.show();
        let params = {state: this.$_companyPlantIssuer.state, name: this.$_companyPlantIssuer.city}
        await this.$store.dispatch("city/showByStateAndCity", params)
          .then(response => {
            this.$_companyPlantIssuer.ibge_code = response.data.iso;
            this.$notify({type: response.error_type, message: response.message});
          }).catch((error) => {
            let errors = error.status
              ? error.data.message
              : formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: errors});
          }).finally(() => {
            loader.hide();
          });
      }
    },
    handleFileUpload() {
      this.certificate_file = this.$refs.file.files[0];
    },
    close(){
      this.$router.push({path: '/configuration/plant/document/issuer-document'});
    },
    async save() {
      // Garantir que ibge_code seja salvo
      if (!this.$_companyPlantIssuer.ibge_code) {
        await this.checkIbgeCode();
        if (!this.$_companyPlantIssuer.ibge_code) {
          this.$notify({type: "danger", message: 'Não foi possível obter o código IBGE da Cidade.'});
          return;
        }
      }
      // Desativar configuração de email personalizada p/ evitar erros
      const customEmailFilled = this.$_companyPlantIssuer.email_param_host &&
        this.$_companyPlantIssuer.email_param_port &&
        this.$_companyPlantIssuer.email_param_user_name &&
        this.$_companyPlantIssuer.email_param_trigger_email &&
        this.$_companyPlantIssuer.email_param_display_name;
      if (!customEmailFilled) {
        this.$_companyPlantIssuer.custom_email_trigger = false;
      }

      // Tratar campos
      this.$_companyPlantIssuer.send_auto_mail = Number(this.$_companyPlantIssuer.send_auto_mail);
      this.$_companyPlantIssuer.business_name = this.companyPlantIssuerGroupName;
      this.$_companyPlantIssuer.alias_name = this.companyPlantIssuerGroupName;
      this.$_companyPlantIssuer.certificate_password = this.certificate_password;

      this.isLoading = true;
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$store
        .dispatch('companyPlantIssuer/update', {
          companyPlantIssuer: this.$_companyPlantIssuer,
          certificate_file: this.certificate_file
        })
        .then(({ error_type, message }) => {
          this.$notify({type: error_type, message});
          this.close();
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    openNfsePage() {
      this.$router.push({path: '/configuration/plant/document/issuer-document/nfse?edit=true'});
    },
    toggleCustomEmailTrigger(){
      this.$_companyPlantIssuer.custom_email_trigger = !this.$_companyPlantIssuer.custom_email_trigger;
    },
    openEmailParam(){
      this.$refs.modalEmailParam.handleOpenModal();
    },
    editIssuerGroup() {
      this.$refs.modalIssuerGroup.handleEdit(this.$_companyPlantIssuer.company_plant_issuer_group_id);
    },
    modalIssuerGroupSubmitted(issuerGroupData) {
      this.$_companyPlantIssuer.company_plant_issuer_group_name = issuerGroupData.name;
    },
    async getSapExternalCode() {
      if(this.hasSap) {
        this.loadingExternalCode = true;
        const response = await sapIntegrationStore.getExternalId(this.$route.params.id, SapTypeEnum.keys.COMPANY_PLANT_ISSUER)
        this.$_companyPlantIssuer.sap_code = response;
        this.loadingExternalCode = false;
      }
    },
    async setSapExternalCode() {
      if(this.hasSap) {
        const loader = this.$loading.show();
        let data = initSapIntegrationSyncType();
        data.typeable_id = this.$route.params.id;
        data.type = SapTypeEnum.keys.COMPANY_PLANT_ISSUER;
        data.external_id = this.$_companyPlantIssuer.sap_code;
        await sapIntegrationStore.sync(data);
        loader.hide();
      }
    },
    async getEntryLaunchConfiguration(companyPlantIssuerId) {
      this.loadingEntryLaunchConfiguration = true;
      await this.entryLaunchConfigurationStore.actions.find(companyPlantIssuerId);
      this.loadingEntryLaunchConfiguration = false;
    },
    async saveEntryLaunchConfiguration() {
      const loader = this.$loading.show();
      this.entryLaunchConfigurationButtonSave = false;
      if(!this.$_entryLaunchConfiguration.company_plant_issuer_id) {
        this.$_entryLaunchConfiguration.company_plant_issuer_id = this.$route.params.id;
      }
      await this.entryLaunchConfigurationStore.actions.create(this.$_entryLaunchConfiguration);
      loader.hide();
    }
  }
};
</script>

<style scoped>
.installments-box {
  max-height: 230px;
  overflow: auto;
}
.new-default-gray {
  background: rgba(43, 45, 50, 0.05);
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-top-left-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.rezise-input-group {
  font-size: 11px !important;
}

.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}

.btn.dropdown-toggle::after {
  content: none;
}
</style>
